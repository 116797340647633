import React from "react";
import EmptyTab from "components/pages/Dashboard/Tabs/EmptyTab";
import {VideoTabs} from './testData'
import VideoResource from "components/pages/Resources/VideoResource";

export default function Video(props) {
    const TextEmpty = '33 You don’t have any active courses yet. To start learning select course from page “All courses” and start learn it';
    return (
        <>
            {
                props.statusEmpty ?

                    <div className={`in-progress`}>

                        <div className="list-items">

                            {
                                VideoTabs.map((item) => (
                                        <VideoResource key={item.id} item={item}/>
                                    )
                                )
                            }

                        </div>
                    </div>
                    :
                    <EmptyTab emptyText={TextEmpty} />
            }

        </>
    )
}