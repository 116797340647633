import React from 'react';
import {useDispatch} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import Progress from "components/pages/Course/Quiz/Progress/Progress";
import Questions from "components/pages/Course/Quiz/Questions/Questions";
import NavButtons from "components/pages/Course/Quiz/NavButtons/NavButtons";
import {saveQuiz} from "redux/actions/quiz";

const IncompletedQuiz = ({quiz, quizState, setQuizState, lessonId}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const totalQuestions = quiz.questions?.length || 0;

    const getAnswers = () => {
        const answerData = [];
        const quizData = {...quizState};
        delete quizData.step;
        delete quizData.quizIdentifier;

        for (let key in quizData) {
            if (!quizData[key]) continue;

            let answers = [{
                "answer": String(quizData[key]).trim()
            }];

            if (typeof quizData[key] === 'object') {
                answers = quizData[key].map(answer => {
                    return {answer}
                });
            }

            answerData.push({"question_id": key, answers});
        }

        return {quiz_id: quiz.id, answers: JSON.stringify(answerData)}
    };

    const handleSaveQuiz = () => dispatch(saveQuiz(lessonId, getAnswers(), quizState.quizIdentifier));

    const isStepRequired = () => {
        if (quiz.questions) {
            const isRequired = quiz.questions[quizState.step].required;
            const questionId = quiz.questions[quizState.step].id;
            const currentValue = quizState[questionId];
            const isValueSet = typeof currentValue === 'number' || (typeof currentValue === 'string' && currentValue.trim().length) || (typeof currentValue === 'object' && currentValue.length);

            return isRequired && !isValueSet;
        }
    };

    const nextStep = () => {
        if (quizState.step < totalQuestions - 1) {
            setQuizState({...quizState, step: ++quizState.step});
        }
    };

    const prevtStep = () => {
        if (quizState.step > 0) {
            setQuizState({...quizState, step: --quizState.step});
        }
    };

    return (
        <section className='quiz__card'>
            <h2 className='quiz__title'>
                {quiz.name}
                <Link className={`quiz__exit`} to={location.pathname}>
                    Exit
                </Link>
            </h2>
            <div className='quiz__content'>
                <Progress progress={{current: quizState.step + 1, total: totalQuestions}}/>
                <Questions questions={quiz.questions} setQuizState={setQuizState}
                           quizState={quizState}/>
                <NavButtons prev={prevtStep}
                            next={nextStep}
                            saveQuiz={handleSaveQuiz}
                            steps={{
                                current: quizState.step,
                                total: totalQuestions,
                                required: isStepRequired()
                            }}
                />
            </div>
        </section>
    );
};

export default IncompletedQuiz;