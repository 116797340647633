import React from 'react';
import ProgressBar from "components/common/ProgressBar/ProgressBar";
import './Progress.scss';

const Progress = ({progress = {}}) => {
    const {current = 1, total = 1} = progress;
    const completed = Math.ceil(current / total * 100);

    return (
        <div className='progress'>
            <span className='progress__text'>Section {current}/{total}</span>
            <ProgressBar complete={completed} />
            <span className='progress__text'>{completed}%</span>
        </div>
    );
};

export default Progress;