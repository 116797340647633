import info from '../constants/userInfo';

const initialState = {};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case info.GET_USER_INFO_SUCCESS:
            return payload;
        default:
            return state;
    }
}