import action from '../constants/userProfile';

export const deleteUserProfile = () => ({
    type: action.DELETE_USER_PROFILE
});
export const deleteUserProfileError = (errorData) => ({
    type: action.DELETE_USER_PROFILE_ERROR,
    payload: errorData,
});

export const updateUserProfile = (updateData) => ({
    type: action.UPDATE_USER_PROFILE,
    payload: updateData
});
export const updateUserProfileSuccess = (successData) => ({
    type: action.UPDATE_USER_PROFILE_SUCCESS,
    payload: successData
});
export const updateUserProfileError = (errorData) => ({
    type: action.UPDATE_USER_PROFILE_ERROR,
    payload: errorData
});