import React, {useState, useEffect} from 'react';
import './Quiz.scss';
import {useQuizData} from "../../../../hooks/useQuizData";
import {useSelector} from "react-redux";
import LoadingSpinner from "components/common/LoadingSpinner/LoadingSpinner";
import IncompletedQuiz from "components/pages/Course/Quiz/IncompletedQuiz/Incompleted";
import CompletedQuiz from "components/pages/Course/Quiz/CompletedQuiz/CompletedQuiz";

const Quiz = ({quizId, lessonId}) => {
    const quiz = useQuizData(quizId);
    const [quizState, setQuizState] = useState({step: 0, quizIdentifier: lessonId});
    const {closedQuiz} = useSelector(state => state.singleCourse);

    useEffect(() => {
        if (quiz.questions) {
            setQuizState({
                ...quizState,
                ...quiz.questions.reduce((val, next) => {
                    val[next.id] = next.type === 'multi_select' ? [] : '';
                    return val;
                }, {})
            })
        }
    }, [quiz.questions]);

    return (
        <div className='quiz'>
            {
                Object.keys(quiz).length > 0 ?
                    closedQuiz === quizState.quizIdentifier ?
                        <CompletedQuiz/>
                        :
                        <IncompletedQuiz quiz={quiz}
                                         quizState={quizState}
                                         setQuizState={setQuizState}
                                         lessonId={lessonId}
                        />
                    :
                    <LoadingSpinner/>
            }
        </div>
    );
};

export default Quiz;