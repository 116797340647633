import React from 'react';
import {NavLink} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {ReactComponent as TimeClockIcon} from "assets/vectors/topics/clock.svg";
import LessonIcon from "components/pages/Course/MyCourse/LessonIcon/LessonIcon";

const Lesson = ({lesson: {id, num, name, duration, type, isLocked, completed}, courseId, toggleMobileLessons}) => {
    const isTabletOrMobile = useMediaQuery({maxWidth: 980});

    const handleLinkClick = (e) => {
        if (isLocked) return e.preventDefault();

        if (isTabletOrMobile) {
            toggleMobileLessons();
        }
    };

    return (
        <NavLink to={`/dashboard/my-courses/${courseId}/lesson/${id}`}
                 onClick={handleLinkClick}
                 className={`item ${isLocked ? 'lock' : ''}`}>
            {
                ({isActive}) => (
                    <>
                        <div className='icon'>
                            <LessonIcon completed={completed}
                                        active={isActive}
                                        type={isLocked || type}/>
                        </div>
                        <div className="item-info">
                            <div className="info-title">{num}. {name}</div>
                            <div className="about">
                                <div className="info-short-description">
                                    {type}
                                </div>
                                <div className="time">
                                    <div className="time-icon"><TimeClockIcon/></div>
                                    <div className="get-time">{duration} min</div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </NavLink>
    );
};

export default Lesson;