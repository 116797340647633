import action from '../constants/certificates';

export const getAllCertificates = () => ({
    type: action.GET_CERTIFICATES,
});

export const getCertificatesError = (errorData) => ({
    type: action.GET_CERTIFICATES_ERROR,
    payload: errorData,
});

export const getCertificatesSuccess = (responseData) => ({
    type: action.GET_CERTIFICATES_SUCCESS,
    payload: responseData
});