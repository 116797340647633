import React from "react";
import {useSelector} from "react-redux";
import {ReactComponent as MyPointsIcon} from "assets/vectors/dashboard/my-points.svg";
import {ReactComponent as PointsLearningIcon} from "assets/vectors/dashboard/points-learning.svg";
import {ReactComponent as PointsIcon} from "assets/vectors/dashboard/point.svg";
import PointsTypes from "components/pages/PointsTypes/PointsTypes";

export default function MyPoints() {
    const {doctor_points_earned = 0, pharma_points_earned = 0} = useSelector(state => state.userInfo);

    return (
        <div className="my-points">
            <div className="section-head">
                <h3 className={`section-title`}><span className="icon"><MyPointsIcon/></span>My points</h3>
            </div>
            <PointsTypes doctorPoints={doctor_points_earned} pharmaPoints={pharma_points_earned}/>
            {/*<div className="active-learning">*/}
                {/*TODO temporary removed acccording to redesign #task MEC-130*/}
                {/*<div className="icon">*/}
                {/*    <PointsLearningIcon/>*/}
                {/*</div>*/}
                {/*<div className="info">*/}
                {/*    <span className={`count-points`}>1XP</span> for every <span className={`time`}> 45 minutes</span> of*/}
                {/*    active learning session*/}
                {/*</div>*/}
            {/*</div>*/}
            {/*TODO temporary hidden until functionality is not ready*/}
            {/*<div className="schedule">*/}
            {/*    <div className="caption">You’ve spent today for learning</div>*/}

            {/*    <div className="chart-wrap">*/}
            {/*        <div className="success" style={{width: `30%`,}}>*/}
            {/*            <div className="icon"><MyPointsIcon/></div>*/}
            {/*        </div>*/}

            {/*        <div className="point-one" style={{width: `50%`,}}>*/}

            {/*            <div className="icon">*/}
            {/*                <PointsIcon/>*/}
            {/*                <div className="point-text"> Get 1 point</div>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*        <div className="point-two">*/}
            {/*            <div className="icon">*/}
            {/*                <PointsIcon/>*/}
            {/*                <div className="point-text"> Get 2 points</div>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*        <div className="learning-time">90 min</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

