import React from "react";

export default function Navigation(props) {
    return (
        <ul className={`tabs__nav`}>
            {props.tabs.map((item) => (
                <li key={item.id} className={`item${(props.activeTabId === item.id) ? ' active' : ''}`}>
                    <button className={`item__button`}
                            onClick={() => props.onNavClick(item.id)}>
                        {item.title}
                    </button>
                </li>
            ))}
        </ul>
    )
}