import React, {useState, useEffect} from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import Header from "components/common/Header/Header";
import Navigation from "components/common/Navigation/Navigation";
import AccountNavigation from "components/common/AccountNavigation/AccountNavigation";
import Dashboard from "components/pages/Dashboard/Dashboard";
import Courses from "components/pages/Courses/Courses";
import Course from "components/pages/Course/Course";
import MyCertifications from "components/pages/MyCertifications/MyCertifications";
import Resources from "components/pages/Resources/Resources";
import MedicalSocieties from "components/pages/MedicalSocieties/MedicalSocieties";
import ContactUs from "components/pages/ContactUs/ContactUs";
import AccountSettings from "components/pages/AccountSettings/AccountSettings";
import NotificationSettings from "components/pages/NotificationSettings/NotificationSettings";
import Privacy from "components/pages/Privacy/Privacy";
import NotFound from "components/pages/NotFound/NotFound";
import {useMediaQuery} from "react-responsive";
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import viewportHeightFix from "../../../helpers/viewportHeightFix";

const Authorized = () => {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1200px)'});
    const location = useLocation();
    const [openBlurContent, setOpenBlurContent] = useState(false);
    const [menu, setMenu] = useState('navigation');
    const accountMenuShow = ['/account-settings', '/notification-settings', '/privacy'];

    useEffect(() => {
        if (/\?open=(presentation|quiz)/.test(location.search)) {
            setMenu(null);
        } else if (accountMenuShow.includes(location.pathname)) {
            setMenu('account');
        } else {
            setMenu('navigation');
        }
        closeMenu();
    }, [location]);

    const toggleMenu = () => {
        viewportHeightFix();
        const pageOffset = window.scrollY;
        document.documentElement.style.setProperty('--pageOffset', `${pageOffset}px`);

        setOpenBlurContent(!openBlurContent);
        openBlurContent ? enableBodyScroll(document) : disableBodyScroll(document);
    };

    const closeMenu = () => {
        if (openBlurContent) {
            setOpenBlurContent(false);
            enableBodyScroll(document);
        }
    };

    return (
        <>
            <Header setOpenBlurContent={setOpenBlurContent}
                    openBlurContent={openBlurContent}
                    toggleMenu={toggleMenu}
                    isUserAuthorized={true}
            />
            <div className={`site-container${menu === 'account' ? ' page-account-content' : ''}`}>
                {
                    isTabletOrMobile &&
                    <div className={`blur-content${openBlurContent ? ' open' : ''}`}/>
                }
                <Navigation show={openBlurContent}/>
                {menu === 'account' && <AccountNavigation/>}
                <div className={`${menu ? 'page-content' : ''}`}>
                    <Routes>
                        <Route path='dashboard/*' element={<Dashboard/>}/>
                        <Route path='dashboard/my-courses/:myCourseId/*' element={<Course/>}/>
                        <Route path='courses' element={<Courses/>}/>
                        <Route path='courses/:courseId' element={<Course/>}/>
                        <Route path='my-certifications' element={<MyCertifications/>}/>
                        <Route path='resources' element={<Resources/>}/>
                        <Route path='medical-societies' element={<MedicalSocieties/>}/>
                        <Route path='contact-us' element={<ContactUs/>}/>
                        <Route path='account-settings' element={<AccountSettings/>}/>
                        <Route path='notification-settings' element={<NotificationSettings/>}/>
                        <Route path='privacy' element={<Privacy/>}/>
                        <Route path="*" element={<NotFound/>}/>
                        <Route path="/" element={<Navigate replace to="/dashboard"/>}/>
                        <Route path="login" element={<Navigate replace to="/dashboard"/>}/>
                        <Route path="login/form" element={<Navigate replace to="/dashboard"/>}/>
                    </Routes>
                </div>
            </div>
        </>
    );
}

export default Authorized;