import React from 'react';
import {Document, Page} from "react-pdf/dist/esm/entry.webpack";

const Certificate = ({modalData}) => {

    console.log(modalData);

    return (

        <div className="request-certificate">

            <Document className={'certificate-document'}  file={modalData} >
                <Page pageNumber={1}/>
            </Document>

        </div>

    )
}

export default Certificate;