import {put, fork, takeLatest, call, select} from "redux-saga/effects";
import action from 'redux/constants/courses';
import {getData} from "redux/helpers/requestHandlers";
import {GET_COURSES} from "config/api";
import {getCoursesError, getCoursesSuccess} from "redux/actions/courses";
import ApiError from "../../services/ErrorHandler/ApiError";
import responseHandler from "redux/helpers/responseHandler";

function* watchGetCoursesData() {
    yield takeLatest(action.GET_COURSES, getCoursesRequest)
}

function* getCoursesRequest() {
    const token = yield select(state => state.login.authToken);
    const {data} = yield call(getData(GET_COURSES, token));

    yield responseHandler(data, function* () {
        yield put(getCoursesSuccess(data.data));
    }, function* () {
        const err = new ApiError(data, `Can't get courses`);

        yield put(getCoursesError(err.message));
    })
}

export default function* () {
    yield fork(watchGetCoursesData);
}
