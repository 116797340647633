export default {
    OPEN_MODAL: "OPEN_MODAL",
    CLOSE_MODAL: "CLOSE_MODAL",
    CERTIFICATIONS_MODAL: "CERTIFICATIONS_MODAL",
    GET_CERTIFICATION_MODAL: "GET_CERTIFICATION_MODAL",
    DELETE_ACCOUNT: "DELETE_ACCOUNT",
    AUTHORIZATIONS: "AUTHORIZATIONS",
    CHANGE_AVATAR: "CHANGE_AVATAR",
    DOC_CHECK_MODAL: "DOC_CHECK_MODAL",
    SUCCESS_COURSE: "SUCCESS_COURSE",
}