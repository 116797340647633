import React from 'react';
import {useDispatch} from "react-redux";
import {ReactComponent as DocCheckIcon} from "assets/vectors/login/doc-check.svg";
import {ReactComponent as ArrowIcon} from "assets/vectors/login/arrow.svg";
import {openModal} from "redux/actions/modal";

const DocCheck = ({iframe}) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(openModal('DOC_CHECK_MODAL', {
            title: 'DocCheck login',
            iframe
        }));
    }
    return (
        <div className="item doc-check" onClick={handleClick}>
            <div className="logo-icon">
                <DocCheckIcon/>
            </div>
            <div className="arrow-icon">
                <ArrowIcon/>
            </div>
        </div>
    );
};

export default DocCheck;