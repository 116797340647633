import React from 'react';
import {ReactComponent as PptActive} from "assets/vectors/topics/active/ppt.svg";
import {ReactComponent as PptDefault} from "assets/vectors/topics/default/ppt.svg";
import {ReactComponent as PptCompleted} from "assets/vectors/topics/completed/ppt.svg";
import {ReactComponent as CompletedIcon} from "assets/vectors/check.svg";

const PptIcon = ({completed, active}) => {
    if (completed) {
        return <><PptCompleted/><CompletedIcon className='complete-icon'/></>;
    }

    return active ? <PptActive/> : <PptDefault/>;
};

export default PptIcon;