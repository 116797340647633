import {useField} from "formik";
import React from "react";

const TextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);



    // console.log(field)
    // console.log(meta)
    return (
        <>
            <input className="text-input" {...field} {...props} />
            <label className={`form-label`} htmlFor={props.id || props.name}>{label}</label>
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </>
    );
};


export default TextInput;