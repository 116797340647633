import action from "redux/constants/completeLesson";

export const completeLesson = (lessonId) => ({
    type: action.COMPLETE_LESSON,
    payload: lessonId
});

export const completeLessonError = (errorData) => ({
    type: action.COMPLETE_LESSON_ERROR,
    payload: errorData,
});

export const completeLessonSuccess = (responseData) => ({
    type: action.COMPLETE_LESSON_SUCCESS,
    payload: responseData
});

export const showModalCertificate = () => ({
    type: action.SHOW_MODAL_SUCCESS_CERTIFICATE,
});
export const hideModalCertificate= () => ({
    type: action.HIDE_MODAL_SUCCESS_CERTIFICATE,
});