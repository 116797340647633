import {put, fork, takeLatest, call, select} from "redux-saga/effects";
import action from 'redux/constants/contactForm';
import {postData} from "redux/helpers/requestHandlers";
import {CONTACT_FORM_URL} from "config/api";
import ApiError from "../../services/ErrorHandler/ApiError";
import {showPopup} from "redux/actions/popup";
import responseHandler from "redux/helpers/responseHandler";
import {submitFormError, submitFormSuccess} from "redux/actions/contactForm";

function* watchSubmitForm() {
    yield takeLatest(action.SUBMIT_FORM, submitForm);
}

function* submitForm({payload}) {
    const {authToken} = yield select(state => state.login);
    const {data} = yield call(postData(CONTACT_FORM_URL, authToken, payload));

    yield responseHandler(data, function* () {
        yield put(showPopup({title: 'Success', text: 'Your message has been sent.'}, true));
        yield put(submitFormSuccess());
    }, function* () {
        const err = new ApiError(data);

        yield put(submitFormError(err.message));
        yield put(showPopup({title: 'Error', text: err.getErrorMessages()}));
    })
}

export default function* () {
    yield fork(watchSubmitForm);
}
