import React from "react";

import TestImage from "assets/vectors/courses/test.png";
import {Link} from "react-router-dom";
import {ReactComponent as ReadIcon} from "assets/vectors/resource/read.svg";
import {ReactComponent as PlayIcon} from "assets/vectors/resource/play.svg";
import {ReactComponent as VideoIcon} from "assets/vectors/resource/video.svg";
import {ReactComponent as ClockIcon} from "assets/vectors/resource/clock.svg";
import Button from "components/common/Button/Button";

export default function VideoResource({item}){
    //
    //console.log(item);

    return(
        <div className="item">
            <div className="image-prev video-image-prev">
                <img src={TestImage} alt=""/>
                <PlayIcon className={`icon-play`} />

            </div>

            <div className="item-info">

                <div className="head">
                    <h3 className="title">{item.title}</h3>
                    <div className="view">
                        <Link to={`/resources/${item.id}/video-view`}>
                            <Button
                                type="button"
                                className="button button--border"
                            >
                                View
                            </Button>
                        </Link>
                    </div>
                </div>

                <div className="about-resource">
                    {item.description}
                </div>

                <div className="video-info">

                    <div className="view">

                        <ReadIcon className={`icon`} />
                        Viewed by 1 234 users
                    </div>

                    <div className="tag">
                        <VideoIcon className={`icon`} />
                        Video
                    </div>

                    <div className="time">
                        <ClockIcon className={`icon`} />
                        10 min
                    </div>
                </div>
            </div>
        </div>
    )
}
