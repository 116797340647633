import {put, takeLatest, select, call, fork} from "redux-saga/effects";
import account from 'redux/constants/accountSettings';
import {USER_PROFILE} from "config/api";
import {closeModal} from "redux/actions/modal";
import {postData} from "redux/helpers/requestHandlers";
import {deleteUserProfileError, updateUserProfileSuccess} from "redux/actions/userProfile";
import {getUserInfo} from "redux/actions/userInfo";
import ApiError from "../../services/ErrorHandler/ApiError";
import {showPopup} from "redux/actions/popup";
import responseHandler from "redux/helpers/responseHandler";
import {setAccountAvatarDone} from "redux/actions/accountSettings";

function* watchSetAccountAvatar() {
    yield takeLatest(account.CHANGE_ACCOUNT_AVATAR, handleSetAccountAvatar)
}

function* handleSetAccountAvatar({payload}) {
    let requestData = {
        avatar: payload.accountData.avatar.imageUrl
    };

    // TODO need to create some functionality for update user avatar
    const token = yield select(state => state.login.authToken);
    const {data} = yield call(postData(USER_PROFILE, token, requestData));

    yield responseHandler(data, function* () {
        yield put(setAccountAvatarDone());
        yield put(updateUserProfileSuccess());
        yield put(getUserInfo());
        yield put(showPopup({title: 'Account settings', text: 'Avatar saved successfully'}, true));
    }, function* () {
        const err = new ApiError(data, `Can't update avatar.`);

        yield put(deleteUserProfileError(err.getErrorMessages()));
        yield put(setAccountAvatarDone());
        yield put(showPopup({title: err.name, text: err.message}));
    })

    yield put(closeModal());
}

export default function* () {
    yield fork(watchSetAccountAvatar);
}