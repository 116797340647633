import React, {useEffect} from "react";
import {CSSTransition} from "react-transition-group";
import {useDispatch, useSelector} from "react-redux";
import {hidePopup} from "redux/actions/popup";
import './Popup.scss';
import {ReactComponent as CloseIcon} from "assets/vectors/close.svg";
import {ReactComponent as WarnIcon} from "assets/vectors/warning.svg";
import {ReactComponent as SuccessIcon} from "assets/vectors/saveSuccess.svg";

const Popup = () => {
    const nodeRef = React.useRef(null);
    const dispatch = useDispatch();
    const defaultTimeOut = 4500;

    const {show, content, closeTimer, isSuccess} = useSelector(state => state.popup);

    const closePopup = () => dispatch(hidePopup());

    useEffect(() => {
        if (show) {
            let defaultShowTimerId = null;

            if (typeof closeTimer === 'number') {
                defaultShowTimerId = setTimeout(closePopup, closeTimer);

            } else {
                defaultShowTimerId = setTimeout(closePopup, defaultTimeOut);
            }

            return () => clearTimeout(defaultShowTimerId);
        }
    }, [show, closePopup, closeTimer]);

    return (
        <>

            <CSSTransition in={show}
                           timeout={500}
                           unmountOnExit
                           nodeRef={nodeRef}
                           classNames={'popup-'}
            >
                <div className={`popup${isSuccess ? ' success' : ''}`} ref={nodeRef}>
                    <div className="popup__content">
                        {
                            isSuccess ?
                                <SuccessIcon className='popup__success'/>
                                :
                                <WarnIcon className='popup__warning'/>
                        }
                        <CloseIcon onClick={closePopup} className="popup__close"/>
                        <h4 className='popup__heading'>My health academy</h4>
                        <h3 className={`popup__title`}>{content?.title}</h3>
                        <p className={`popup__text`}>{content?.text}</p>
                    </div>
                </div>
            </CSSTransition>
        </>
    )
};

export default Popup;