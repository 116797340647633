import React from 'react';
import {useMediaQuery} from "react-responsive";
import {ReactComponent as BackArrow} from "assets/vectors/back-arrow.svg";
import Lesson from "components/pages/Course/MyCourse/Topics/Lesson/Lesson";

const LessonsList = ({lessons, toggleMobileLessons, courseId}) => {
    const isTabletOrMobile = useMediaQuery({maxWidth: 980});

    return (
        <div className="post-list-data">
            {
                isTabletOrMobile && (
                    <button className='go-back' onClick={toggleMobileLessons}>
                        <BackArrow className={`icon`}/>
                        Details
                    </button>
                )
            }
            {
                lessons.map((lesson, index) => (
                    <Lesson lesson={{...lesson, num: ++index}}
                            courseId={courseId}
                            toggleMobileLessons={toggleMobileLessons}
                            key={lesson.id}
                    />
                ))
            }
        </div>
    );
};

export default LessonsList;