import React from "react";
import Upcoming from "components/pages/Resources/AdditionalInfo/Upcoming/Upcoming";
import './Additionalinfo.scss'
import Recommended from "components/pages/Resources/AdditionalInfo/Recommended/Recommended";
export default function AdditionalInfo() {
    return (
        <div className="additional-info">

            <Recommended/>

            <Upcoming/>

        </div>
    )
}
