import React from "react";


import AuthorImg from 'assets/images/courses/author.png'

export default function Author({name, avatar}) {
    return (
        <div className="author-course">

           <div className="ava"><img src={avatar} alt="avatar"/></div>

            <div className="author">
                <div className="author-header">Author of course</div>
                <div className="name">{name}</div>
            </div>

        </div>
    )
}
