import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getQuiz} from "redux/actions/quiz";

export const useQuizData = (quizId) => {
    const dispatch = useDispatch();
    const {quiz} = useSelector(state => state.singleCourse);

    useEffect(() => {
        if (quizId) {
            dispatch(getQuiz(quizId));
        }

    }, [quizId, dispatch]);

    return quiz;
}