import React from 'react';
import './PointsTypes.scss';
import {ReactComponent as DocPoints} from "assets/vectors/dashboard/doctor-points.svg";
import {ReactComponent as PharmaPoints} from "assets/vectors/dashboard/pharma-icon.svg";

const PointsTypes = ({doctorPoints = 0, pharmaPoints = 0}) => {
    return (
        <div className="points-type">
            <div className="points-type__card">
                <DocPoints />
                <span className='points-type__num'>{doctorPoints}</span>
                <p>Doctor points</p>
            </div>
            <div className="points-type__card points-type__card--pharma">
                <PharmaPoints />
                <span className='points-type__num points-type__num--pharma'>{pharmaPoints}</span>
                <p>Pharmaceutical points</p>
            </div>
        </div>
    );
};

export default PointsTypes;