import {put} from "redux-saga/effects";
import {logOut} from "redux/actions/login";
import ApiError from "../../services/ErrorHandler/ApiError";
import {clearLocalStorage} from "../../services/login";

export default function* responseHandler(response = {}, successHandler, errorHandler) {
    if (response.status === 'Success') return yield successHandler();

    if (response.status === 'Error') {
        if (response.error_code === 'request_unauthorized') {
            console.error('Authentication error');
            yield clearLocalStorage();
            return yield put(logOut());
        }

        if (typeof errorHandler === 'string') {
            const err = new ApiError(response, errorHandler);
            console.error(err.message);
        }

        return typeof errorHandler === 'function' ? yield errorHandler() : null;
    }
}
