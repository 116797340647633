import {useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {getLoginServices} from "redux/actions/login";

export default function useLoginServices() {
    const dispatch = useDispatch();
    const {loginServices} = useSelector(state => state.login);

    useEffect(() => {
        if (Object.keys(loginServices).length === 0) {
            dispatch(getLoginServices());
        }
    }, []);

    return loginServices;
}