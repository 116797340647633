export const getItemById = (id, items) => items.find(item => item.id === id);

export const minToHours = (min) => {
    let hours = 0;
    let minutes = 0;

    if (typeof min === 'number') {
        const minPerHour = 60;
        hours = Math.floor(min / minPerHour);
        minutes = min % minPerHour;

    }

    return {hours, minutes};
};