import React from "react";
import "./MyCertifications.scss";
import 'react-perfect-scrollbar/dist/css/styles.css';
import ListCertifications from "components/pages/MyCertifications/ListCertificates";
import HeaderListCertificates from "components/pages/MyCertifications/HeaderListCertificates";

export default function Certifications({allCertificates}) {

    return (
        <>
            <HeaderListCertificates/>

            <ListCertifications allCertificates={allCertificates}/>
        </>
    )

}