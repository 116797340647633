import {getItemById} from './common';

const getFirstUncompletedLesson = (topics = []) => {
    let defaultActiveLesson = {lesson: topics[0]?.lessons[0]?.id};

    for (let i = 0; i < topics.length; i++) {
        let activeLesson = topics[i].lessons.find(lesson => !lesson.isLocked && !lesson.completed);

        if (activeLesson) {
            defaultActiveLesson['lesson'] = activeLesson.id;
            break;
        }
    }

    return defaultActiveLesson;
};

export const getProgressCourseLink = (topics = []) => {
    const activeTopic = getFirstUncompletedLesson(topics);

    return `${Object.keys(activeTopic)[0]}/${Object.values(activeTopic)[0]}`;
};

export const getMyCourseDetails = ({id, topics = [], type} = {}) => {
    if (type === 'lesson') {
        const lessons = topics.map(topic => topic.lessons).flat();

        return getItemById(id, lessons);
    }

    return getItemById(id, topics);
};

export const getCoursesByStatus = (courses = [], status) => {
    return courses.filter(course => course.status === status);
};

