import React from 'react';
import {ReactComponent as PdfActive} from "assets/vectors/topics/active/pdf.svg";
import {ReactComponent as PdfDefault} from "assets/vectors/topics/default/pdf.svg";
import {ReactComponent as PdfCompleted} from "assets/vectors/topics/completed/pdf.svg";
import {ReactComponent as CompletedIcon} from "assets/vectors/check.svg";

const PdfIcon = ({completed, active}) => {
    if (completed) {
        return <><PdfCompleted/><CompletedIcon className='complete-icon'/></>;
    }

    return active ? <PdfActive/> : <PdfDefault/>;
};

export default PdfIcon;