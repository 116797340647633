import React from 'react';
import * as Yup from 'yup';
import './FromStyle.scss';

import {Formik, Field, Form, ErrorMessage, useField} from 'formik';
import Button from "components/common/Button/Button";
import {useDispatch} from "react-redux";

import TextInput from "components/common/FormEllements/TextInput";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormSelect from "components/common/FormEllements/FormSelect";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

const GetCertificateForm = ({title, subtitle, description,modalData,setRequestDigital}) => {
    const dispatch = useDispatch();

    return (
        <div className="certificate-form">

            <h2 className="get-certificate-title">{title}</h2>

            <h3 className="get-certificate-subtitle">{subtitle}<br/>"{modalData.program}"</h3>

            <p className="get-certificate-description">{description}</p>

            {/*<p className="get-certificate-form">{modalData.id}</p>*/}

            <Formik
                initialValues={{
                    first_name: 'Test name',
                    second_name: 'Test Second name',
                    select_qualification: '567',
                    specialisation: '409'
                }}
                validationSchema={Yup.object({
                    first_name: Yup.string().required("Required"),
                    second_name: Yup.string().required("Required"),
                })}

                onSubmit={
                    (values, {setErrors, setSubmitting,resetForm, setStatus}) => {
                        setSubmitting(false);
                        console.log(values)

                        resetForm();
                        setRequestDigital(false)
                        // dispatch(sendCertificateForm(values, setErrors)); //todo нужно будет создать этот action
                    }
                }
            >
                {({
                      isClearable,
                      handleReset,
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      touched,
                      isSubmitting,
                      dirty,
                      setFieldValue,
                      handleBlur,
                      status,
                      ...props
                  }) => {

                    console.log(errors);

                    return (
                        <Form className={`modal-form`}>

                            <div className={`row${errors.first_name && touched.first_name ? ' input-error' : ''}`}>

                                <TextInput
                                    label="Your name"
                                    name="first_name"
                                    type="text"
                                    placeholder="Your name"

                                />

                            </div>

                            <div className={`row${errors.second_name && touched.second_name ? ' input-error' : ''}`}>

                                <TextInput
                                    label="Second name"
                                    name="second_name"
                                    type="text"
                                    placeholder="Second name"
                                />

                            </div>

                            <div className={`row`}>

                                <FormControl className={`form-selected-input`} error = {errors.select_qualification && touched.select_qualification}  >
                                    <InputLabel className="form-select-label">Qualification</InputLabel>
                                    <Field name="select_qualification" component={FormSelect}>
                                        <MenuItem value={'1'}>Doctor of Medicine</MenuItem>
                                        <MenuItem value={'2'}>Doctor of Medicine 2</MenuItem>
                                        <MenuItem value={'3'}>Doctor of Medicine 3</MenuItem>
                                        <MenuItem value={'567'}>Doctor of Medicine 4</MenuItem>
                                        <MenuItem value={'5'}>Doctor of Medicine 5</MenuItem>
                                    </Field>
                                    <FormHelperText>{(errors.select_qualification && touched.select_qualification) && errors.select_qualification}</FormHelperText>

                                </FormControl>


                            </div>

                            <div className={`row`}>

                                <FormControl className={`form-selected-input`} error = {errors.specialisation && touched.specialisation}  >
                                    <InputLabel className="form-select-label">Specialisation</InputLabel>
                                    <Field name="specialisation" component={FormSelect}>
                                        <MenuItem value={'1'}>M.Sc - Anatomy</MenuItem>
                                        <MenuItem value={'2'}>M.Sc - Anatomy 2</MenuItem>
                                        <MenuItem value={'3'}>M.Sc - Anatomy 3</MenuItem>
                                        <MenuItem value={'409'}>M.Sc - Anatomy 4</MenuItem>
                                        <MenuItem value={'5'}>M.Sc - Anatomy 5</MenuItem>
                                    </Field>
                                    <FormHelperText>{(errors.specialisation && touched.specialisation) && errors.specialisation}</FormHelperText>

                                </FormControl>


                            </div>

                            <div className="submit-wrap">
                                <Button
                                    type="submit"
                                    className="button button--green"
                                >
                                    Get my certificate
                                </Button>
                            </div>

                        </Form>

                    )
                }}
            </Formik>
        </div>
    )
}

export default GetCertificateForm;