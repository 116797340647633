import React from 'react';
import './Rating.scss';

const Rating = ({scale = 0, value, handleChange}) => {

    return (
        <ul className='rating' >
            {
                Array.from(Array(scale)).map((item, index) => {
                    const rate = ++index;
                    const isActive = rate === value;

                    return (
                        <li key={index}
                            className={`rating__circle ${isActive ? 'active' : ''}`}
                            onClick={() => handleChange(rate)}
                        >
                            {rate}
                        </li>
                    )
                })
            }
        </ul>
    );
};

export default Rating;