import React from 'react';
import './SuccessCourse.scss';

import {Document, Page} from "react-pdf/dist/esm/entry.webpack";

import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import modalConstants from "redux/constants/modal";


const SuccessCourse = ({modalData}) => {

    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch({type: modalConstants.CLOSE_MODAL});
    };

    console.log(modalData);

    return (
        <div className="my-certifications-modal">

            <div className="request-certificate">

                <Document className={'certificate-document'}  file={modalData} >
                    <Page pageNumber={1}/>
                </Document>

                <div className="request-wrap">
                    <Link onClick={closeModal} className="button--green" to='/my-certifications'>
                        View my certificates
                    </Link>
                </div>

            </div>

        </div>
    )
}

export default SuccessCourse;