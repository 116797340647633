import action from '../constants/register';

export const registerRequired = (data) => ({
    type: action.REGISTER_REQUIRED,
    payload: data
});

export const registerRequest = (requestData) => ({
    type: action.REGISTER_REQUEST,
    payload: requestData,
});

export const registerError = (errorData) => ({
    type: action.REGISTER_ERROR,
    payload: errorData,
});

export const registerSuccess = () => ({
    type: action.REGISTER_SUCCESS,
});