import React, {useState} from 'react';
import './CertificationsModal.scss';

import Certificate from "components/Modal/CertificationsModal/Certificate";
import CertificateForm from "components/Modal/CertificationsModal/CertificateForm";

const CertificationsModal = ({title,description,modalData}) => {

    const [requestDigital, setRequestDigital] = useState(true);

    return (
        <div className="my-certifications-modal">

            <Certificate modalData={modalData} setRequestDigital={setRequestDigital}/>

            {/*{*/}
            {/*    requestDigital ?*/}

            {/*        <Certificate setRequestDigital={setRequestDigital}/>*/}
            {/*        :*/}
            {/*        <CertificateForm title={title} description={description} modalData={modalData}/>*/}
            {/*}*/}

        </div>
    )
}

export default CertificationsModal;