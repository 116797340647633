import React from "react";
import Author from "components/pages/Course/Sidebar/Author/Author";
import Materials from "components/pages/Course/Sidebar/Materials/Materials";
import AdditionalInformation
    from "components/pages/Course/Sidebar/AdditionalInformation/AdditionalInformation";
import AboutCourse from "components/pages/Course/Sidebar/AboutCourse/AboutCourse";

export default function Sidebar({course}) {
    return (
        <div className="sidebar">
            <div className="sidebar-container">
                <AboutCourse duration={course.course_duration}
                             points={{doctorPoints: course.total_doctor_points, pharmaPoints: course.total_pharma_points}}
                />
                <Author avatar={course.author_avatar}
                        name={course.author_fullname}
                />
                <Materials pdfFile={course.files}  duration={course.presentation_type_lessons_duration}/>
            </div>
            <AdditionalInformation/>
        </div>
    )
}
