import React from 'react';
import "./Authorization.scss";
import AuthorizationProgress from "./AuthorizationProgress";
import AuthorizationFail from "./AuthorizationFail";
import {useSelector} from "react-redux";

const Authorization = ({closeModal}) => {
    const {loginError} = useSelector(state => state.login);

    return (
        <div className="authorization-modal">
            {
                loginError ?
                    <AuthorizationFail closeModal={closeModal}/>
                    :
                    <AuthorizationProgress/>
            }
        </div>
    )
}

export default Authorization;