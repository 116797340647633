import React from 'react';
import Radio from "components/common/Inputs/Radio";
import uniqid from 'uniqid';

const RadioGroup = ({options = [], value, ...otherProps}) => {
    return (
        options.map(option => (
                <Radio key={uniqid()}
                       value={value}
                       checked={value === option.option}
                       name={option.option}
                       label={option.option}
                       {...otherProps}
                />
            )
        )
    );
};

export default RadioGroup;