import React from 'react';

import {ReactComponent as SuccessIcon} from "assets/vectors/login/success-load.svg";

import LoadingSpinner from "components/common/LoadingSpinner/LoadingSpinner";


const AuthorizationProgress = () => {
    return (
        <div className="authorization-modal-success">
            <h2 className="authorization-modal__title">
                Authorization
            </h2>
            <p className="authorization-modal__description">
                Please wait while we check your authorization information
            </p>
            <LoadingSpinner/>
            <SuccessIcon/>
        </div>
    )
}

export default AuthorizationProgress;