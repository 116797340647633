import React, {useEffect} from 'react';
import {Formik, Field, Form, ErrorMessage} from 'formik';
import {useSelector, useDispatch} from "react-redux";
import * as Yup from 'yup';
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import '../forms.scss';
import FormSelect from "components/common/FormEllements/FormSelect";
import Button from "components/common/Button/Button";
import {selectStyles, labelStyles} from "components/forms/selectStyles";
import {registerRequest} from "redux/actions/register";
import {clearState} from "redux/actions/clearState";
import register from "redux/constants/register";

const RegisterForm = () => {
    const dispatch = useDispatch();
    const {registerData, registerRequestProgress, registerError} = useSelector(state => state.registration);
    const {staticData} = useSelector(state => state.staticData);

    useEffect(() => {
        let timerId = null;

        if (registerError) {
            timerId = setTimeout(() => dispatch(clearState(register.CLEAR_REGISTER_ERROR)), 5000);
        }

        return () => {
            if (registerError) {
                clearTimeout(timerId);
                dispatch(clearState(register.CLEAR_REGISTER_ERROR));
            }
        };

    }, [registerError, dispatch]);

    return (
        <Formik
            initialValues={{
                name: registerData.name || '',
                surname: registerData.surname || '',
                email: registerData.email || '',
                category: '',
            }}
            validationSchema={Yup.object({
                name: Yup.string()
                    .min(2, '*Must be 2 characters or more')
                    .required('*Required'),
                surname: Yup.string()
                    .min(2, '*Must be 2 characters or more')
                    .required('*Required'),
                email: Yup.string()
                    .email('*Invalid email address')
                    .required('*Required'),
                category: Yup.string().required('*Required'),
            })}
            onSubmit={(values) => {
                const {email, name, surname, category} = values;
                const requestData = {
                    email,
                    name,
                    surname,
                    medical_categories: [category],
                    uid: registerData.uid
                }

                dispatch(registerRequest(requestData));
            }}
        >
            <Form className='register-form' autoComplete="on" noValidate={true}>
                <Field name="name"
                       type="text"
                       className='register-form__input'
                       placeholder='Your first name'
                />
                <ErrorMessage component="span" name="name" className='register-form__error'/>
                <Field name="surname"
                       type="text"
                       className='register-form__input'
                       placeholder='Your last name'
                />
                <ErrorMessage component="span" name="surname" className='register-form__error'/>
                <Field name="email"
                       type="email"
                       className='register-form__input'
                       placeholder='Your email'
                />
                <ErrorMessage component="span" name="email" className='register-form__error'/>
                <FormControl fullWidth>
                    <InputLabel htmlFor="qualification-label"
                                sx={labelStyles}
                    >
                        Your qualification
                    </InputLabel>
                    <Field name="category"
                           labelId="qualification-label"
                           sx={selectStyles}
                           className='register-form__select'
                           component={FormSelect}>
                        {
                            staticData.medical_categories?.map(category => (
                                <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                            ))
                        }
                    </Field>
                </FormControl>
                <ErrorMessage component="span" name="category" className='register-form__error'/>
                <Button type="submit"
                        isLoading={registerRequestProgress}
                        className='button button--green register-form__submit'>
                    Login
                </Button>
                {
                    registerError &&
                    <div className='register-form__error'
                         style={{bottom: 0, marginTop: '16px', fontSize: '16px'}}>
                        *Error: {registerError}
                    </div>
                }
            </Form>
        </Formik>
    );
};

export default RegisterForm;