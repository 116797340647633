import React from 'react';
import {ReactComponent as VideoActive} from "assets/vectors/topics/active/video.svg";
import {ReactComponent as VideoDefault} from "assets/vectors/topics/default/video.svg";
import {ReactComponent as VideoCompleted} from "assets/vectors/topics/completed/video.svg";
import {ReactComponent as CompletedIcon} from "assets/vectors/check.svg";

const VideoIcon = ({active, completed}) => {
    if (completed) {
        return <><VideoCompleted/><CompletedIcon className='complete-icon'/></>;
    }
    return active ? <VideoActive/> : <VideoDefault/>;
};

export default VideoIcon;