import action from '../constants/login';

export const getLoginServices = () => ({
    type: action.GET_LOGIN_SERVICES,
});

export const getLoginServicesError = (error) => ({
    type: action.GET_LOGIN_SERVICES_ERROR,
    payload: error
});

export const getLoginServicesSuccess = (data) => ({
    type: action.GET_LOGIN_SERVICES_SUCCESS,
    payload: data
});

export const loginRequest = (requestData) => ({
    type: action.LOGIN_REQUEST,
    payload: requestData,
});

export const loginAdminRequest = (requestData) => ({
    type: action.LOGIN_ADMIN_REQUEST,
    payload: requestData,
});

export const loginError = (errorData) => ({
    type: action.LOGIN_ERROR,
    payload: errorData,
});

export const loginSuccess = (successData) => ({
    type: action.LOGIN_SUCCESS,
    payload: successData,
});

export const logOutRequest = () => ({
    type: action.LOGOUT_REQUEST,
});

export const logOut = () => ({
    type: action.LOGOUT,
});
