import React from 'react';
import PptIcon from "components/pages/Course/MyCourse/LessonIcon/PptIcon";
import PdfIcon from "components/pages/Course/MyCourse/LessonIcon/PdfIcon";
import PresentationIcon from "components/pages/Course/MyCourse/LessonIcon/PresentationIcon";
import VideoIcon from "components/pages/Course/MyCourse/LessonIcon/VideoIcon";
import QuizIcon from "components/pages/Course/MyCourse/LessonIcon/QuizIcon";
import {ReactComponent as LockedIcon} from "assets/vectors/topics/lock.svg";

const LessonIcon = ({type, completed, active}) => {
    switch (type) {
        case 'pdf':
            return <PdfIcon active={active} completed={completed}/>;
        case 'presentation':
            return <PresentationIcon active={active} completed={completed}/>;
        case 'video':
            return <VideoIcon active={active} completed={completed}/>;
        case 'quiz':
            return <QuizIcon active={active} completed={completed}/>;
        case 'ppt':
            return <PptIcon active={active} completed={completed}/>;
        default:
            return <LockedIcon/>;
    }
};

export default LessonIcon;