import React from "react";
import {useMediaQuery} from 'react-responsive'
import Notification from "components/common/Header/UserInfo/Notification/Notification";
import UserData from "components/common/Header/UserInfo/UserData/UserData";

export default function UserInfo({openBlurContent, toggleMenu}) {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1200px)'});

    return (
        <div className="user-info">
            <Notification/>
            {
                isTabletOrMobile ?
                    <button className={`menu-toggle${openBlurContent ? ' menu-visible' : ''}`} onClick={toggleMenu}>
                        <span className="menu-toggle__inner">
                            <span className="menu-toggle__line"></span>
                            <span className="menu-toggle__last-line"></span>
                        </span>
                    </button>
                    :
                    <UserData/>
            }
        </div>
    )
}