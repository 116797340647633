import React from "react";
import "./LoadingSpinner.scss";

export default function LoadingSpinner() {

    return (
        <section className={`loading-wrap`}>

            <div className="loading">
                <div></div>
                <div></div>
            </div>

        </section>
    )

}