import React, {useState} from 'react';
import './GetCertificate.scss';
import GetCertificateForm from "components/Modal/GetCertificate/GetCertificateForm";
import Certificate from "components/Modal/GetCertificate/Certificate";

const GetCertificateModal = ({title, subtitle, description, modalData}) => {

    const [requestDigital, setRequestDigital] = useState(true);

    // console.log(modalData);
    return (
        <div className="get-certificate-modal">

            {
                requestDigital ?

                    <GetCertificateForm setRequestDigital={setRequestDigital} title={title} subtitle={subtitle} description={description} modalData={modalData}/>
                    :
                    <Certificate setRequestDigital={setRequestDigital}/>

            }

        </div>
    );
};

export default GetCertificateModal;