import React from 'react';
import {useDispatch} from "react-redux";
import {ReactComponent as FailIcon} from "assets/vectors/login/fail-load.svg";
import Button from "components/common/Button/Button";
import login from "redux/constants/login";
import {clearState} from "redux/actions/clearState";

const AuthorizationFail = ({closeModal}) => {
    const dispatch = useDispatch();

    const closeHandle = () => {
        closeModal();
        dispatch(clearState(login.CLEAR_LOGIN_ERROR));
    };

    return (
        <div className="authorization-modal-fail">
            <h2 className="authorization-modal__title">
                Ooops
            </h2>
            <p className="authorization-modal__description">
                Something went wrong. Please check your authorization data and try again
            </p>
            <FailIcon/>
            <div className="try-again">
                <Button
                    type="button"
                    className="button button--green"
                    onClick={closeHandle}
                >
                    Try again
                </Button>
            </div>
        </div>
    )
}

export default AuthorizationFail;