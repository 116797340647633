import React from 'react';
import OverviewDetails from "components/pages/Course/OverviewDetails/OverviewDetails";
import Sidebar from "components/pages/Course/Sidebar/Sidebar";

const CourseOverview = ({course}) => {
    return (
        <>
            <OverviewDetails course={course}/>
            <Sidebar course={course}/>
        </>
    );
};

export default CourseOverview;