import {put, fork, takeLatest, call, select} from "redux-saga/effects";
import action from 'redux/constants/certificates';
import {getData} from "redux/helpers/requestHandlers";
import {GET_CERTIFICATES} from "config/api";
import {getCertificatesError, getCertificatesSuccess} from "redux/actions/certificates";
import ApiError from "../../services/ErrorHandler/ApiError";
import responseHandler from "redux/helpers/responseHandler";

function* watchGetCertificatesData() {
    yield takeLatest(action.GET_CERTIFICATES, getCertificatesRequest)
}

function* getCertificatesRequest() {
    const token = yield select(state => state.login.authToken);
    const {data} = yield call(getData(GET_CERTIFICATES, token));

    yield responseHandler(data, function* () {
        yield put(getCertificatesSuccess(data.data));
    }, function* () {
        const err = new ApiError(data, `Can't get certificates`);

        yield put(getCertificatesError(err.message));
    })
}

export default function* () {
    yield fork(watchGetCertificatesData);
}
