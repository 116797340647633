import React from 'react';

import certificateImage from "assets/vectors/courses/getCourse.svg"

import Button from "components/common/Button/Button";
import modalConstants from "redux/constants/modal";
import {useDispatch} from "react-redux";

const Certificate = ({setRequestDigital}) => {

    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch({type: modalConstants.CLOSE_MODAL});
        setRequestDigital(true);
    };

    return (

        <div className="request-certificate">

            <h2 className="get-certificate-title">Thank you!</h2>

            <h3 className="thank-you-subtitle">Your certificate will be available on <span className={`green-color`}>"My certificates"</span> page in a few moment</h3>

            <img className={`certificate-image`} src={certificateImage} alt="certificate"/>

            <div className="request-wrap">
                <Button
                    type="button"
                    className="button button--green"
                    onClick={closeModal}
                >
                    Ok
                </Button>
            </div>

        </div>

    )
}

export default Certificate;