import React from 'react';
import './Modal.scss';
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as CloseIcon} from "assets/vectors/close.svg";
import modalConstants from "redux/constants/modal";
import DefaultModal from "components/Modal/DefaultModal/DefaultModal";
import CertificationsModal from "components/Modal/CertificationsModal/CertificationsModal";
import GetCertificateModal from "components/Modal/GetCertificate/GetCertificate";
import DeleteAccount from "components/Modal/DeleteAccount/DeleteAccount";
import Authorization from "components/Modal/Authorization/Authorization";
import ChangeAvatar from "components/Modal/ChangeAvatar/ChangeAvatar";
import DocCheck from "components/Modal/DocCheck/DocCheck";
import SuccessCourse from "components/Modal/SuccessCourse/SuccessCourse";

const Modal = () => {
    const dispatch = useDispatch();
    const isModalOpen = useSelector(state => state.modal.open);
    const modalType = useSelector(state => state.modal.modalType);
    const modalData = useSelector(state => state.modal.modalData);

    const closeModal = () => {
        dispatch({type: modalConstants.CLOSE_MODAL});
    };

    const renderModalContent = () => {
        switch (modalType) {
            case modalConstants.SUCCESS_COURSE:
                return <SuccessCourse
                    modalData={modalData}
                />
            case modalConstants.CERTIFICATIONS_MODAL:
                return <CertificationsModal
                    title="Request digital copy"
                    description="Please fill the form below and we will send on your email a digital copy of certificate as soon as possible"
                    modalData={modalData}
                />
            case modalConstants.CHANGE_AVATAR:
                return <ChangeAvatar
                    title="Upload your photo"
                    description="The following file types can be uploaded: JPG, JPEG, PNG."
                    modalData={modalData}
                />
            case modalConstants.GET_CERTIFICATION_MODAL:
                return <GetCertificateModal
                    title="Get certificate"
                    subtitle="Congratulations with finishing of the course "
                    description="To create your certificate please check and approve your data"
                    modalData={modalData}
                />
            case modalConstants.DELETE_ACCOUNT:
                return <DeleteAccount
                    title="Close account"
                    subtitle="Do you really want to close your account at MyHealth Academy?"
                    modalData={modalData}
                />
            case modalConstants.AUTHORIZATIONS:
                return <Authorization modalData={modalData} closeModal={closeModal} />
            case modalConstants.DOC_CHECK_MODAL:
                return <DocCheck modalData={modalData} />
            default:
                return <DefaultModal modalData={modalData}/>
        }
    }

    return (

        <div className={isModalOpen ? `modal active` : `modal`} onClick={closeModal}>
            <div className={isModalOpen ? `modal-content active` : `modal-content`} onClick={event => event.stopPropagation()}>
                <div className="close-modal" onClick={closeModal}>
                    <div className="icon"><CloseIcon/></div>
                </div>
                {
                    renderModalContent()
                }
            </div>
        </div>

    )
};

export default Modal;