import React, {useEffect, useState} from 'react';
import './AccountNavigation.scss';
import {Link, NavLink, useLocation} from "react-router-dom";
import {ReactComponent as ChangePhotoIcon} from "assets/vectors/menu-icons/change-photo.svg";
import userAvatar from "assets/images/ava.jpeg";
import defaultAva from "assets/vectors/defaultAva.svg";
import {ReactComponent as ProfileIcon} from "assets/vectors/settings-icons/profile.svg"
import {ReactComponent as NotificationSettingsIcon} from "assets/vectors/settings-icons/settings.svg";
import {ReactComponent as PrivacyIcon} from "assets/vectors/settings-icons/privacy.svg";
import {ReactComponent as ProfileIconActive} from "assets/vectors/settings-icons/profile-active.svg"
import {ReactComponent as NotificationSettingsIconActive} from "assets/vectors/settings-icons/settings-active.svg";
import {ReactComponent as PrivacyIconActive} from "assets/vectors/settings-icons/privacy-active.svg";
import {useDispatch, useSelector} from "react-redux";
import {openModal} from "redux/actions/modal";
import modalConstants from "redux/constants/modal";
import {ReactComponent as ArrowLeft} from "assets/vectors/arrow-left-grey.svg";
import {useMediaQuery} from "react-responsive";
import {ReactComponent as ArrowDown} from "assets/vectors/arrow-down-grey.svg";
import MobileNavTitle from "components/common/AccountNavigation/MobileNavTitle/MobileNavTitle";

export default function AccountNavigation() {
    const location = useLocation();
    const [showUserAvatar, setShowUserAvatar] = useState(true);
    const locationPath = ['/privacy'];
    const [openNav, setOpenNav] = useState(false);

    const toggleNav = () => setOpenNav(!openNav);

    useEffect(() => {
        if (locationPath.includes(location.pathname)) {
            setShowUserAvatar(false);
        } else {
            setShowUserAvatar(true);
        }

        if (openNav) setOpenNav(false);
    }, [location]);

    const dispatch = useDispatch();
    const getCurrentUserInfo = useSelector(state => state.userInfo);
    const isTabletOrMobile = useMediaQuery({maxWidth: 1200});

    const ChangePhoto = (dataModal) => {
        dispatch(openModal(modalConstants.CHANGE_AVATAR, dataModal))
    }

    return (
        <div className={`account-navigation`}>

            {
                    getCurrentUserInfo &&

                <>

                    {
                        isTabletOrMobile &&
                        <h1 className={`title`}> <Link className={`back-home`} to="/dashboard"><ArrowLeft className={`icon`} /></Link>  Account settings</h1>

                    }

                    <div className={`account-navigation-wrap`}>

                        {
                            showUserAvatar &&
                            <div className="user-avatar-wrap">
                                <div className="user-avatar">
                                    <img src={ getCurrentUserInfo.avatar ? getCurrentUserInfo.avatar : defaultAva } alt="user avatar"/>
                                </div>

                                <div className="change-photo" onClick={()=>ChangePhoto()}>
                                    <div className="icon"><ChangePhotoIcon/></div>
                                    <div className="text-change">Change photo</div>
                                </div>
                            </div>
                        }
                        <div className={`navigation-top`}>
                            <button onClick={toggleNav} className={`tabs__toggle tabs__toggle--profile ${openNav ? ' tabs__toggle--open' : ''}`}>
                                <MobileNavTitle pathname={location?.pathname}/>
                                <ArrowDown className='icon'/>
                            </button>
                            <nav className={`menu`}>
                                <NavLink to={'/account-settings'}>
                                    {({isActive}) => (
                                        <>
                                            {isActive ?
                                                <span className={`icon active`}><ProfileIconActive /></span>
                                                :
                                                <span className={`icon`}><ProfileIcon /></span>
                                            }
                                            <span className={`menu-name`}>Profile</span>
                                        </>

                                    )}

                                </NavLink>

                                {/*<NavLink to={'/notification-settings'}>*/}
                                {/*    {({isActive}) => (*/}
                                {/*        <>*/}
                                {/*            {isActive ?*/}
                                {/*                <span className={`icon active`}><NotificationSettingsIconActive /></span>*/}
                                {/*                :*/}
                                {/*                <span className={`icon`}><NotificationSettingsIcon /></span>*/}
                                {/*            }*/}
                                {/*            <span className={`menu-name`}>Notification settings</span>*/}
                                {/*        </>*/}

                                {/*    )}*/}
                                {/*</NavLink>*/}

                                <NavLink to={'/privacy'}>

                                    {({isActive}) => (
                                        <>
                                            {isActive ?
                                                <span className={`icon active`}><PrivacyIconActive /></span>
                                                :
                                                <span className={`icon`}><PrivacyIcon /></span>

                                            }
                                            <span className={`menu-name`}>Privacy</span>
                                        </>

                                    )}
                                </NavLink>


                            </nav>
                        </div>
                    </div>

                </>
            }

        </div>
    );
}