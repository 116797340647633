export const TestItems = [
    {
        id: 1,
        title: 'Biology molecular',
        lessons: '22',
        time: '180',
        accreditation: '5 accreditation tests',
        successChart:  70,
    },
    {
        id: 2,
        title: 'Biology molecular 2',
        lessons: '33',
        time: '334',
        accreditation: '1 accreditation tests',
        successChart:  22,
    },
    {
        id: 3,
        title: 'Biology molecular 3',
        lessons: '56',
        time: '120',
        accreditation: '12 accreditation tests',
        successChart:  78,
    },
    {
        id: 4,
        title: 'Biology molecular',
        lessons: '22',
        time: '180',
        accreditation: '5 accreditation tests',
        successChart:  70,
    },
    {
        id: 5,
        title: 'Biology molecular 2',
        lessons: '33',
        time: '334',
        accreditation: '1 accreditation tests',
        successChart:  22,
    },
    {
        id: 6,
        title: 'Biology molecular 3',
        lessons: '56',
        time: '120',
        accreditation: '12 accreditation tests',
        successChart:  78,
    },
];

