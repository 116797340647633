import notifications from './notifications';

export default class ApiError extends Error {
    constructor({
                    message = 'Api request failed',
                    error_code,
                    errors
                } = {},
                overwriteMessage = ''
    ) {
        super(overwriteMessage || message);

        this.name = 'ApiError';
        this.errorCode = error_code;
        this.errors = errors;
    }

    #transformErrorMessage(errorCode) {
        return notifications[errorCode] || this.message;
    }

    getErrorMessages() {
        if (this.errors?.length) {
            return this.errors.map(message => message.message).join(` \n`);
        } else {
            return this.#transformErrorMessage(this.errorCode);
        }
    }
};