import React from "react";
import {Link} from "react-router-dom";
import './Empty.scss';
import {ReactComponent as NotFoundImage} from "assets/empty/in-progress.svg";

import Button from "components/common/Button/Button";

export default function EmptyTab({emptyText}) {
    return (
        <div className={`empty-tab`}>
            <div className="image-wrap">
                <NotFoundImage/>
            </div>
            <div className="empty-text">{emptyText}</div>
            <div className="find-course">
                <Link to='/courses'>
                    <Button
                        type="button"
                        className="button button--green"
                    >
                        Find a course
                    </Button>
                </Link>
            </div>
        </div>
    )
};