import React from "react";
import "./Privacy.scss";
import {Link} from "react-router-dom";
import {ReactComponent as ArrowLeft} from "assets/vectors/arrow-left-grey.svg";

export default function Privacy() {

    return (
        <section className={`page-privacy`}>

            <h1 className={`title`}>
                <Link className={`back-home`} to="/dashboard"><ArrowLeft className={`icon`} /></Link>

                Privacy policy</h1>


            <p>Magni placerat congue, suspendisse exercitationem fugiat, nullam facilis eget velit, sodales commodi ante saepe, natus laboris, nihil optio expedita asperiores, doloremque litora torquent vivamus occaecati ipsum, varius minus, curabitur excepteur, anim metus alias quod officia, consectetur aliquet omnis harum anim pharetra occaecati natus sapien feugiat quia! Tenetur egestas bibendum odit, tristique fermentum, eos eu fusce lectus, quis integer eaque, mattis. Primis vehicula aspernatur eaque placerat placeat fuga etiam blandit impedit, quam habitant, temporibus iste, feugiat accusantium dolore, fusce, culpa hymenaeos? Sociis velit venenatis error aliquet, mauris aut accusantium hic netus dignissimos! Beatae error quidem hendrerit dui, dolores nobis excepturi, soluta.</p>

            <p>Ex reiciendis varius ac temporibus distinctio, nostrum fringilla diamlorem in quo purus quia facilisis dolore. Cumque. Facilis doloribus veniam delectus taciti varius sint consectetur tenetur purus placeat? Modi, platea sapiente impedit sapiente quasi dolore minus, interdum. Maiores dolor exercitation phasellus feugiat laudantium? Tortor fusce blandit, fusce possimus luctus incididunt montes fugiat excepturi! Suscipit fusce bibendum repudiandae vivamus tellus modi pharetra? Temporibus lorem vehicula. Sollicitudin, ea cubilia? Tristique mollis felis ullamcorper, lobortis dictumst nemo? Debitis! Amet! Neque occaecat incididunt, porro magnam laboris, eget iusto iure minima pretium curae excepteur dictumst laoreet. Rutrum dignissim! Mollit, voluptas temporibus est porttitor maxime mi sapien.</p>

            <p>Omnis nostrum, aut tempore nostra unde quaerat quae egestas fugit impedit ad, montes mollit curabitur? Porta nascetur sagittis. Aliquam velit illo! Mi, vivamus nulla justo expedita quaerat? Nostrum! Accusamus dis, pariatur nostrud illo dapibus cupidatat felis risus quis parturient. Laboris reiciendis vehicula tempor nesciunt! Enim adipiscing in taciti. Porttitor imperdiet, hendrerit hendrerit, hic aptent praesentium enim suspendisse, tincidunt autem porta? Aliquam ducimus pulvinar, dolores at reiciendis distinctio taciti, facilisis amet, optio vitae, eiusmod quis ab, facilis molestie mauris, voluptates labore purus nulla eveniet! Illum, error aliquam, nemo habitant viverra cursus? Leo molestie platea minus cumque distinctio! Accusantium et. Cubilia cras.</p>
        </section>
    )

}