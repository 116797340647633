import axios from "axios";

export const getData = (apiUrl, authToken, config = {}) => {
    if (authToken) {
        const {headers, ...otherConfig} = config;

        config = {
            'headers': {'Authorization': `Bearer ${authToken}`, ...config.headers},
            ...otherConfig
        }
    }

    return () => axios.get(apiUrl, config)
        .then(data => data)
        .catch(error => error.response)
}

export const postData = (apiUrl, authToken, requestData, config = {}) => {
    if (authToken) {
        const {headers, ...otherConfig} = config;

        config = {
            'headers': {'Authorization': `Bearer ${authToken}`, ...headers}, ...otherConfig
        }
    }

    return () => axios.post(apiUrl, requestData, config)
        .then(data => data)
        .catch(error => error.response)
}

export const updateData = (apiUrl, authToken, requestData, config = {}) => {
    if (authToken) {
        const {headers, ...otherConfig} = config;

        config = {
            'headers': {'Authorization': `Bearer ${authToken}`, ...config.headers},
            ...otherConfig
        }
    }

    return () => axios.patch(apiUrl, requestData, config)
        .then(data => data)
        .catch(error => error.response)
}

export const deleteData = (apiUrl, authToken, requestData = {}, config = {}) => {
    if (authToken) {
        const {headers, ...otherConfig} = config;

        config = {
            'headers': {'Authorization': `Bearer ${authToken}`, ...config.headers},
            'data': {
                ...requestData
            },
            ...otherConfig
        }
    }

    return () => axios.delete(apiUrl, config)
        .then(data => data)
        .catch(error => error.response)
}