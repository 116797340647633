import action from '../constants/contactForm';

export const submitForm = (formData) => ({
    type: action.SUBMIT_FORM,
    payload: formData
});

export const submitFormError = (errorData) => ({
    type: action.SUBMIT_FORM_ERROR,
    payload: errorData,
});

export const submitFormSuccess = () => ({
    type: action.SUBMIT_FORM_SUCCESS,
});