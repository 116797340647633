import {put, fork, takeLatest, call} from "redux-saga/effects";
import action from 'redux/constants/staticData';
import {getData} from "redux/helpers/requestHandlers";
import {STATIC_DATA_URL} from "config/api";
import {getStaticDataError, getStaticDataSuccess} from "redux/actions/staticData";
import ApiError from "../../services/ErrorHandler/ApiError";
import responseHandler from "redux/helpers/responseHandler";

function* watchGetStaticData() {
    yield takeLatest(action.GET_STATIC_DATA, getStaticDataRequest)
}

function* getStaticDataRequest() {
    const {data} = yield call(getData(STATIC_DATA_URL));

    yield responseHandler(data, function* () {
        yield put(getStaticDataSuccess(data.data));
    }, function* () {
        const err = new ApiError(data, `Can't get static data`);
        yield put(getStaticDataError(err.message));
    });
}

export default function* () {
    yield fork(watchGetStaticData);
}
