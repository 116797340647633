import action from '../constants/quiz';

export const getQuiz = (quizId) => ({
    type: action.GET_QUIZ,
    payload: quizId
});

export const getQuizError = (errorData) => ({
    type: action.GET_QUIZ_ERROR,
    payload: errorData,
});

export const getQuizSuccess = (responseData) => ({
    type: action.GET_QUIZ_SUCCESS,
    payload: responseData
});

export const saveQuiz = (lessonId, answers, closedQuiz) => ({
    type: action.SAVE_QUIZ,
    payload: {lessonId, answers, closedQuiz}
});

export const saveQuizError = (errorData) => ({
    type: action.SAVE_QUIZ_ERROR,
    payload: errorData,
});

export const saveQuizSuccess = () => ({
    type: action.SAVE_QUIZ_SUCCESS,
});

export const closeQuiz = (id) => ({
    type: action.CLOSE_QUIZ,
    payload: id,
});