import {put, fork, takeLatest, call, select, delay} from "redux-saga/effects";
import action from 'redux/constants/myCourses';
import {getData} from "redux/helpers/requestHandlers";
import {GET_MY_COURSES} from "config/api";
import ApiError from "../../services/ErrorHandler/ApiError";
import {getMyCoursesError, getMyCoursesSuccess} from "redux/actions/myCourses";
import responseHandler from "redux/helpers/responseHandler";

function* watchGetMyCoursesData() {
    yield takeLatest(action.GET_MY_COURSES, getMyCoursesRequest)
}

export function* getMyCoursesRequest() {
    const token = yield select(state => state.login.authToken);
    const {data} = yield call(getData(GET_MY_COURSES, token));

    yield responseHandler(data, function* () {
        yield put(getMyCoursesSuccess(data.data));
    }, function* () {
        const err = new ApiError(data, `Can't get courses`);

        yield put(getMyCoursesError(err.message));
    })
}

export default function* () {
    yield fork(watchGetMyCoursesData);
}
