import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Button from "components/common/Button/Button";
import {ReactComponent as BackArrow} from "assets/vectors/back-arrow.svg";
import Preview from "components/pages/Course/OverviewDetails/Preview/Preview";
import Content from "components/pages/Course/OverviewDetails/Content/Content";
import {completeLesson} from "redux/actions/completeLesson";
import CheckedLabel from "components/pages/Course/MyCourse/CheckedLabel/CheckedLabel";
import {ReactComponent as FullScreenIcon} from "assets/vectors/single-course/full-screen.svg";
import {useMediaQuery} from "react-responsive";
import {openModal} from "redux/actions/modal";
import {hideModalCertificate} from "redux/actions/completeLesson";
import modalConstants from "redux/constants/modal";

export default function Details({topicDetails = {}, courseId, toggleMobileLessons}) {
    const dispatch = useDispatch();
    const isLesson = 'type' in topicDetails;
    const {closedQuiz} = useSelector(state => state.singleCourse);
    const {completeLessonLoading, course, showModalSuccessCertificate} = useSelector(state => state.singleCourse);
    const isPresentation = topicDetails.type === 'presentation';
    const isQuizCompleted = topicDetails.quiz_answers_exists || closedQuiz === topicDetails.id;
    const frameRef = useRef();
    const openFullScreen = () => frameRef.current.requestFullscreen();

    const markComplete = () => isLesson ? dispatch(completeLesson({topicDetailsID: topicDetails.id, courseId: courseId})) : false;

    console.log(showModalSuccessCertificate)

    useEffect(() => {
        if (course.status === 'completed' && showModalSuccessCertificate) {
            dispatch(openModal(modalConstants.SUCCESS_COURSE, course.certificate_link))
            dispatch(hideModalCertificate())
        }
    }, [course]);

    const isTabletOrMobile = useMediaQuery({maxWidth: 980});

    const getViewButtonName = () => {
        switch (topicDetails.type) {
            case 'presentation':
                return 'View presentation';
            case 'quiz':
                return 'Feedback form';
            default:
                return 'View details';
        }
    };

    return (
        <div className="details">
            <section className="headline-course">
                <div className={`back-to-overview`}>
                    <Link className={`back`} to={`/dashboard/my-courses/${courseId}`}>
                        <BackArrow className={`icon`}/>
                        Back to Overview
                    </Link>
                </div>
                {topicDetails.completed && <CheckedLabel/>}
            </section>
            {isTabletOrMobile && <button className='details__view' onClick={toggleMobileLessons}>View details</button>}
            <h1 className={`title`}>{topicDetails.name}</h1>
            <section className="body-course">
                {
                    isPresentation && topicDetails.presentation_link ?
                        <div className="preview">
                            <div className='image-placeholder'>
                                {!isTabletOrMobile &&
                                <FullScreenIcon className='fullscreen-icon' onClick={openFullScreen}/>}
                                <iframe src={topicDetails.presentation_link}
                                        width='100%'
                                        height='100%'
                                        ref={frameRef}
                                        title='presentation'
                                        name='presentation'
                                        allow='fullscreen'
                                        frameBorder="0"
                                        style={{borderRadius: '8px'}}
                                />
                            </div>
                        </div>
                        :
                        topicDetails.image && (
                            <Link to={`?open=${topicDetails.type}`}
                                  onClick={(e) => (isPresentation || isQuizCompleted) && e.preventDefault()}
                                  className={`${isPresentation ? 'disabled-link' : ''}`}>
                                <Preview coverImg={topicDetails.image}/>
                            </Link>
                        )
                }
                {
                    topicDetails.description && <Content text={topicDetails.description}
                                                         contentType={isLesson ? 'lesson' : 'topic'}/>
                }
                <div className="actions-course">
                    {
                        !topicDetails.completed && isLesson && (
                            <Button type='button'
                                    isLoading={completeLessonLoading}
                                    disabled={completeLessonLoading}
                                    onClick={markComplete}
                                    className="button button--border mark-completed"
                            >
                                Mark as Completed
                            </Button>
                        )
                    }
                    {
                        isLesson && !isPresentation && !isQuizCompleted && (
                            <Link to={`?open=${topicDetails.type}`}
                                  className="button button--green view-presentation"
                            >
                                {getViewButtonName()}
                            </Link>
                        )
                    }
                    {isQuizCompleted && <button className='button button--green' disabled>Feedback provided</button>}
                </div>
            </section>
        </div>
    )
}