import React from "react";

export default function MyProgress({progress = 0}){
    return(
        <div className="my-progress">
            <div className="title-block">My progress</div>

            <div className="schedule">
                <div className="chart">
                    <div className="success-chart" style={{width:`${progress}%`}}></div>
                </div>
                <div className="success">{progress}%</div>
            </div>
        </div>
    )
}

