import action from '../constants/myCourses';

export const getMyCourses = () => ({
    type: action.GET_MY_COURSES,
});

export const getMyCoursesError = (errorData) => ({
    type: action.GET_MY_COURSES_ERROR,
    payload: errorData,
});

export const getMyCoursesSuccess = (responseData) => ({
    type: action.GET_MY_COURSES_SUCCESS,
    payload: responseData
});