import React from "react";
import {Link, useLocation} from "react-router-dom";
import {ReactComponent as PencilIcon} from "assets/vectors/courses/pencil.svg";
import {ReactComponent as ClockIcon} from "assets/vectors/courses/clock.svg";
import {ReactComponent as FileIcon} from "assets/vectors/courses/file.svg";
import Button from "components/common/Button/Button";

export default function CourseInProgress({course}) {
    let location = useLocation();

    return (
        <>
            <div className="image-prev">
                <img src={course.dashboard_image} alt="cover image"/>
            </div>
            <div className="item-info">
                <div className="head">
                    <h3 className="title">
                        <Link to={`/dashboard/my-courses/${course.id}`}
                              state={{from: location}}
                        >
                            {course.name}
                        </Link>
                    </h3>
                    <div className="view">
                        <Link to={`/dashboard/my-courses/${course.id}`} state={{from: location}}>
                            <Button
                                type="button"
                                className="button button--border"
                            >
                                View details
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="about-course">
                    <div className="lessons">
                        <PencilIcon className={`icon`}/>
                        {course.total_lessons} lessons
                    </div>
                    <div className="time">
                        <ClockIcon className={`icon`}/>
                        {course.course_duration} min
                    </div>
                    {/*TODO temporary hide #task MEC-71*/}
                    {/*<div className="accreditation">*/}
                    {/*    <FileIcon className={`icon`}/>*/}
                    {/*    {course.accreditation}*/}
                    {/*</div>*/}
                </div>
                <div className="schedule">
                    <div className="chart">
                        <div className="success-chart" style={{width: `${course.completion_percentage}%`}}></div>
                    </div>
                    <div className="success">{course.completion_percentage}%</div>
                </div>
                <div className="view view--isMobile">
                    <Link to={`/dashboard/my-courses/${course.id}`} state={{from: location}}>
                        <Button
                            type="button"
                            className="button button--border"
                        >
                            View details
                        </Button>
                    </Link>
                </div>
            </div>
        </>
    )
}
