import {put, spawn, takeLatest, call, select} from "redux-saga/effects";
import action from 'redux/constants/startCourse';
import {postData} from "redux/helpers/requestHandlers";
import {START_COURSE_URL} from "config/api";
import ApiError from "../../services/ErrorHandler/ApiError";
import {showPopup} from "redux/actions/popup";
import {startCourseError, startCourseSuccess} from "redux/actions/startCourse";
import responseHandler from "redux/helpers/responseHandler";

function* watchStartCCourse() {
    yield takeLatest(action.START_COURSE, startCourseRequest);
}

function* startCourseRequest({payload}) {
    const {authToken} = yield select(state => state.login);
    const {data} = yield call(postData(START_COURSE_URL(payload), authToken));

    yield responseHandler(data, function* () {
        yield put(startCourseSuccess(data.data));

    }, function* () {
        const err = new ApiError(data, `Can't start the  course.`);

        yield put(startCourseError(err.message));
        yield put(showPopup({title: 'Error', text: err.message}));
    });
}

export default function* () {
    yield spawn(watchStartCCourse);
}
