import React from "react";
// import DefaultImage from "assets/vectors/courses/courses-default.png";
import {Link, useLocation} from "react-router-dom";
import {ReactComponent as PencilIcon} from "assets/vectors/courses/grey-pencil.svg";
import {ReactComponent as ClockIcon} from "assets/vectors/courses/grey-clock.svg";
import {ReactComponent as FileIcon} from "assets/vectors/courses/grey-file.svg";
import {ReactComponent as CompletedIcon} from "assets/vectors/courses/completed.svg";
import {openModal} from "redux/actions/modal";
import modalConstants from "redux/constants/modal";
import {useDispatch} from "react-redux";
import Button from "components/common/Button/Button";

export default function CourseCompleted({course}) {
    const dispatch = useDispatch();
    let location = useLocation();

    const getCertificateOpenModalRequest = (dataModal) => {
        dispatch(openModal(modalConstants.GET_CERTIFICATION_MODAL, dataModal))
    };

    return (
        <>
            <div className="image-prev">
                <img src={course.dashboard_image} alt="cover image"/>
            </div>
            <div className="item-info">
                <div className="head">
                    <h3 className="title">
                        <Link to={`/dashboard/my-courses/${course.id}`}
                              state={{from: location}}
                        >
                            {course.name}
                        </Link>
                    </h3>
                    <div className="view get-certificate">
                        {/*TODO temporary hide #task MEC-71*/}
                        {/*<Button*/}
                        {/*    type="button"*/}
                        {/*    className="button button--border"*/}
                        {/*    onClick={() => {*/}
                        {/*        getCertificateOpenModalRequest({id: course.id, program: course.name})*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    Get certificate*/}
                        {/*</Button>*/}
                    </div>
                </div>
                <div className="about-course">
                    <div className="lessons">
                        <PencilIcon className={`icon`}/>
                        {course.total_lessons} lessons
                    </div>
                    <div className="time">
                        <ClockIcon className={`icon`}/>
                        {course.course_duration} min
                    </div>
                    {/*TODO temporary hide #task MEC-71*/}
                    {/*<div className="accreditation">*/}
                    {/*    <FileIcon className={`icon`}/>*/}
                    {/*    {course.accreditation} N/A*/}
                    {/*</div>*/}
                </div>
                <div className="status">
                    <div className="completed">
                        Completed
                        <CompletedIcon className={`icon`}/>
                    </div>
                </div>
            </div>
        </>
    )
}
