import React from "react";
import "./Empty.scss";

import {Link} from "react-router-dom";

const Empty = ({image,description}) => {

    return(
        <div className={`empty-wrap`}>
            <div className="image-wrap">
                <img src={image} alt="empty"/>
            </div>
            <div className="text-description">
                <p>{description}</p>
            </div>

            <div className="btn-wrap">
                <Link to='/courses'>
                    Find a course
                </Link>
            </div>

        </div>
    )
}

export default Empty;