import React, {useState} from "react";
import "./SearchResources.scss";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {ReactComponent as SearchIcon} from "assets/vectors/search.svg";
import {ReactComponent as CoursesCategoryIcon} from "assets/vectors/courses-category.svg";
import {ReactComponent as ArrowDownIcon} from "assets/vectors/arrow-down-grey.svg";
import {ReactComponent as ClearItemIcon} from "assets/vectors/courses/clear-item.svg";

export default function SearchResources() {

    const [coursesSearchValue, setCoursesSearchValue] = useState('');
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [openCategorylist, setOpenCategorylist] = useState(false);

    const handleCoursesSearchChange = (event) => {
        setCoursesSearchValue(event.target.value);
        console.log(event.target.value)
    }

    const GetCategory = () => {
        setOpenCategorylist(!openCategorylist)
    };

    const removeCategoryItem = (item) => {
        let arr = selectedCategory.filter(function (searchItem) {
            return searchItem !== item
        })
        setSelectedCategory(arr)
    };

    const removeAllCategoryItem = () => {
        setSelectedCategory([])
    };

    const resetFormCategory = (resetForm) => {
        // console.log(resetForm)
        setSelectedCategory([])
        resetForm([])
        // console.log(selectedCategory)
    };

    return (
        <>

            <section className="headline">
                <div className="title-block"><h1 className={`title`}>Resources</h1></div>
                <div className="get-category" onClick={GetCategory}>

                    <div className="icon"><CoursesCategoryIcon/></div>
                    <div className="text">Categories</div>
                    <div className = {`icon-arrow ${openCategorylist ? 'rotate' : ''}`}><ArrowDownIcon/></div>
                </div>
                <div className="search-courses">
                    <SearchIcon className={`icon`}/>

                    <input
                        name="search-courses"
                        onChange={handleCoursesSearchChange}
                        type="text"
                        value={coursesSearchValue}
                        placeholder={`Search`}/>

                </div>

                {
                    openCategorylist &&

                    <div className="modal-category">

                        <div className="modal-title">Medical categories</div>


                        <Formik
                            initialValues={{
                                jobType: selectedCategory,
                            }}
                            onSubmit={
                                (values, {setErrors, setSubmitting, setStatus}) => {
                                    setSubmitting(false);
                                    console.log(values);
                                    setSelectedCategory(values.jobType)
                                    setOpenCategorylist(false)
                                }
                            }
                        >

                            {({
                                  resetForm,
                                  handleSubmit,
                                  handleChange,
                                  values,
                                  errors,
                                  touched,
                                  isSubmitting,
                                  dirty,
                                  setFieldValue,
                                  handleBlur,
                                  status,
                                  actions,
                                  ...props
                              }) => {
                                console.log("Form VAlue",values)

                                return (

                                    <Form>

                                        <div className="all-category">
                                            <div className="category">
                                                <div className="category-name">Surgery</div>
                                                <ul>

                                                    <li>
                                                        <Field id={`awesome`} className={`styled-checkbox`} type="checkbox" name="jobType"value="Awesome"/>
                                                        <label htmlFor={`awesome`}>Are you awesome?</label>
                                                    </li>

                                                    <li>
                                                        <Field id={`Designer`} className={`styled-checkbox`} type="checkbox" name="jobType" value="Designer"/>
                                                        <label htmlFor={`Designer`}>Designer</label>
                                                    </li>


                                                    <li>
                                                        <Field id={`Developer`} className={`styled-checkbox`} type="checkbox" name="jobType" value="Developer"/>
                                                        <label htmlFor={`Developer`}>Developer</label>
                                                    </li>

                                                    <li>
                                                        <Field id="Product" className={`styled-checkbox`}type="checkbox" name="jobType" value="Product Manager"/>
                                                        <label htmlFor={`Product`}> Product Manager </label>
                                                    </li>

                                                    {/*<li>*/}
                                                    {/*    <input*/}
                                                    {/*        className={`styled-checkbox`}*/}
                                                    {/*        id="Founder"*/}
                                                    {/*        type="checkbox"*/}
                                                    {/*        name="jobType"*/}
                                                    {/*        value="founder"*/}
                                                    {/*        checked={values.jobType.includes('founder')}*/}
                                                    {/*        onChange={handleChange}*/}
                                                    {/*        // onBlur={handleBlur}*/}
                                                    {/*    />*/}
                                                    {/*    <label htmlFor="Founder"> CEO / Founder</label>*/}
                                                    {/*</li>*/}

                                                </ul>
                                            </div>
                                            <div className="category">
                                                <div className="category-name">INTERNAL MEDICINE 2</div>
                                                <ul>

                                                    <li>

                                                        <Field id={`awesome-2`} className={`styled-checkbox`} type="checkbox" name="jobType"value="awesome-2"/>
                                                        <label htmlFor={`awesome-2`}>Surgery</label>
                                                    </li>

                                                    <li>
                                                        <Field id={`Nephrology`} className={`styled-checkbox`} type="checkbox" name="jobType" value="Nephrology"/>
                                                        <label htmlFor={`Nephrology`}>Nephrology</label>
                                                    </li>


                                                    <li>
                                                        <Field id={`Urology`} className={`styled-checkbox`} type="checkbox" name="jobType" value="Urology"/>
                                                        <label htmlFor={`Urology`}>Urology</label>
                                                    </li>


                                                </ul>
                                            </div>



                                            <div className="category break"></div>
                                            <div className="category break"></div>
                                            <div className="category break"></div>
                                            <div className="category break"></div>
                                        </div>

                                        <div className="form-calculations">
                                            <button type="reset"
                                                    className={`reset-form`}
                                                    onClick={()=>resetFormCategory(resetForm)}
                                            >
                                                clear all
                                            </button>

                                            <button type="submit"
                                                    className="apply-form"
                                                    disabled={isSubmitting || !dirty}
                                            >
                                                Apply
                                            </button>
                                        </div>

                                    </Form>
                                )
                            }}

                        </Formik>


                    </div>

                }


            </section>

            {
                selectedCategory && selectedCategory.length > 0 &&

                <section className={`selected-category`}>
                    {
                        selectedCategory.map((item, index) => {

                            return (
                                <div className={`item`} key={index}>
                                    {item}
                                    <ClearItemIcon className={`remove-category-item`} onClick={()=>removeCategoryItem(item)} />
                                </div>
                            )

                        })
                    }

                    <button className={`clear-all-categories`} type={`button`} onClick={removeAllCategoryItem}>Clear all categories</button>
                </section>
            }

        </>
    )

}