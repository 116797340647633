import action from '../constants/courses';

export const getAllCourses = () => ({
    type: action.GET_COURSES,
});

export const getCoursesError = (errorData) => ({
    type: action.GET_COURSES_ERROR,
    payload: errorData,
});

export const getCoursesSuccess = (responseData) => ({
    type: action.GET_COURSES_SUCCESS,
    payload: responseData
});