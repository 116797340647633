import {connectRouter} from "connected-react-router";
import {combineReducers} from "redux";
import modal from './modal';
import login from './login';
import registration from './register';
import staticData from './staticData';
import userInfo from './userInfo';
import popup from './popup';
import certificates from './certificates';
import courses from './courses';
import singleCourse from './singleCourse';
import userProfile from './userProfile';
import myCourses from './myCourses';
import favoriteCourses from './favoriteCourses';
import contactForm from './contactForm';
import accountSettings from './accountSettings';

export const rootReducer = (history) =>
    combineReducers({
        siteRouter: connectRouter(history),
        modal,
        login,
        registration,
        staticData,
        userInfo,
        popup,
        courses,
        certificates,
        singleCourse,
        userProfile,
        myCourses,
        favoriteCourses,
        contactForm,
        accountSettings
    });
