export const tabs = [
    {
        link: '/dashboard',
        title: 'In progress',
    },
    // TODO temporary hide #task MEC-97
    // {
    //     link: 'my-courses',
    //     title: "My courses",
    // },
    {
        link: 'completed',
        title: "Completed courses",
    },
    // TODO temporary hide #task MEC-71
    // {
    //     link: 'on-hold',
    //     title: "Courses on hold",
    // }
];