import certificates from '../constants/certificates';

const initialState = {
    certificates: [],
    loading: true,
    certificatesError: '',
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case certificates.GET_CERTIFICATES_SUCCESS:
            return {
                ...state,
                certificates: payload,
                loading: false,
                certificatesError: '',
            };
        case certificates.GET_CERTIFICATES_ERROR:
            return {
                ...state,
                certificates: [],
                loading: false,
                certificatesError: payload,
            };
        default:
            return state;
    }
}