import React, {useEffect} from "react";
import "./Dashboard.scss";
import {useDispatch} from "react-redux";
import Tabs from "components/pages/Dashboard/Tabs/Tabs";
import Sidebar from "components/pages/Dashboard/Sidebar/Sidebar";
import {getMyCourses} from "redux/actions/myCourses";
import {getFavoriteCourses} from "redux/actions/favoriteCourse";

export default function Dashboard() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMyCourses());
        dispatch(getFavoriteCourses());
    }, [dispatch]);

    return (
        <section className={`page-dashboard`}>
            <Tabs/>
            <Sidebar/>
        </section>
    )

}