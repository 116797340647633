import React, {useRef, useEffect} from 'react';
import * as Yup from 'yup';
import {Formik, Form} from 'formik';
import Button from "components/common/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import TextInput from "components/common/FormEllements/TextInput";
import TextArea from "components/common/FormEllements/TextArea";
import {submitForm} from "redux/actions/contactForm";
import {clearState} from "redux/actions/clearState";

const ContactForm = () => {
    const dispatch = useDispatch();
    const {loading, submitSuccess, submitError} = useSelector(state => state.contactForm);
    const {email = ''} = useSelector(state => state.userInfo);
    const formRef = useRef();

    useEffect(() => {
        if (submitSuccess) {
            formRef?.current?.resetForm();
        }

        if (submitSuccess || submitError) {
            return () => dispatch(clearState('CLEAR_SUBMIT_FORM'));
        }

    }, [submitSuccess]);

    return (
        <div className="certificate-form">
            <h1 className={`form-title`}>Contact us</h1>
            <p className={`form-description`}>To contact us, please fill out the form below. We will reply to your
                questions as soon as possible</p>
            <Formik
                enableReinitialize
                innerRef={formRef}
                initialValues={{
                    email,
                    comment: ''
                }}
                validationSchema={Yup.object({
                    email: Yup.string().email('Invalid email address').required('Required'),
                    comment: Yup.string()
                        .min(10, "Must be 10 characters or more")
                        .trim()
                        .required("Required"),

                })}
                onSubmit={
                    (values, {setSubmitting}) => {
                        setSubmitting(false);
                        const {email, comment} = values;
                        dispatch(submitForm({email, comment: comment?.trim()}));
                    }
                }
            >
                {({
                      isClearable,
                      handleReset,
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      touched,
                      isSubmitting,
                      dirty,
                      setFieldValue,
                      handleBlur,
                      status,
                      ...props
                  }) => {

                    return (
                        <Form className={`modal-form`}>
                            <div className={`row${errors.email && touched.email ? ' input-error' : ''}`}>
                                <TextInput
                                    label="Your email"
                                    name="email"
                                    type="email"
                                    placeholder="Your email"

                                />
                            </div>
                            <div className={`row${errors.comment && touched.comment ? ' input-error' : ''}`}>
                                <TextArea
                                    label="Your comment"
                                    name="comment"
                                    rows="6"
                                    placeholder="Your comment"
                                />
                            </div>
                            <div className="submit-wrap">
                                <Button
                                    isLoading={loading}
                                    spinner={{color: 'white'}}
                                    type="submit"
                                    className="button button--green"
                                >
                                    Send a form
                                </Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default ContactForm;