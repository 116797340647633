import React from 'react';
import './EmptyPage.css';
import {Link, useLocation} from "react-router-dom";
import NotFound from "components/pages/NotFound/NotFound";
import {ReactComponent as ArrowLeft} from "assets/vectors/arrow-left-grey.svg";

const EmptyPage = () => {
    const location = useLocation();

    return (
        <div className='empty-page'>
            <Link className={`presentation__back`} to={location.pathname}>
                <ArrowLeft className={`icon`}/>
                <h1 className={`presentation__title`}>Back</h1>
            </Link>
            <NotFound/>
        </div>
    );
};

export default EmptyPage;