import {all} from "redux-saga/effects";
import login from './login';
import register from './register';
import accountSettings from './accountSettings';
import staticData from './staticData';
import userInfo from './userInfo';
import courses from './courses';
import certificates from './certificates';
import singleCourse from './singleCourse';
import startCourse from './startCourse';
import completeLesson from './completeLesson';
import myCourses from './myCourses';
import userProfile from './userProfile';
import favoriteCourses from './favoriteCourses';
import addFavoriteCourse from './addToFavoriteCourse';
import quiz from './quiz';
import contactForm from './contactForm';

export default function* rootSaga() {
    yield all([
        login(),
        register(),
        accountSettings(),
        staticData(),
        userInfo(),
        courses(),
        certificates(),
        singleCourse(),
        startCourse(),
        completeLesson(),
        userProfile(),
        myCourses(),
        favoriteCourses(),
        addFavoriteCourse(),
        quiz(),
        contactForm()
    ]);
}
