import React from 'react';
import './ProgressBar.scss';

const ProgressBar = ({complete = 0}) => {
    return (
        <div className='progress-bar'>
            <div className='progress-bar__fill' style={{width: `${complete}%`}}></div>
        </div>
    );
};

export default ProgressBar;