import React from "react";
import {ReactComponent as FlexibleDeadlinesIcon} from "assets/vectors/single-course/flexible-deadlines.svg";
import {ReactComponent as OnlineIcon} from "assets/vectors/single-course/online.svg";
import {ReactComponent as SimpleCertificationIcon} from "assets/vectors/single-course/simple-certification.svg";


export default function AdditionalInformation() {

    return (
        <div className="additional-information">
            <div className="additional-information-list">
                <div className="item">
                    <div className="icon"><FlexibleDeadlinesIcon/></div>
                    <div className="text-wrap">
                        <h3 className="title">Flexible deadlines </h3>
                        <p className="content">You can pause and continue your course any time.</p>
                    </div>
                </div>
                <div className="item">
                    <div className="icon"><SimpleCertificationIcon/></div>
                    <div className="text-wrap">
                        <h3 className="title">Simple Certification</h3>
                        <p className="content">Earn a Certificate upon completion.</p>
                    </div>
                </div>
                <div className="item">
                    <div className="icon"><OnlineIcon/></div>
                    <div className="text-wrap">
                        <h3 className="title">100% online</h3>
                        <p className="content">Start instantly and learn your own schedule.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
