import React from "react";
import {minToHours} from "helpers/common";
import {ReactComponent as LengthIcon} from "assets/vectors/single-course/course-length.svg";
import {ReactComponent as PointsIcon} from "assets/vectors/single-course/course-points.svg";
import PointsTypes from "components/pages/PointsTypes/PointsTypes";


export default function AboutCourse({duration, points}) {
    const {hours, minutes} = minToHours(duration);

    return (
        <div className="about">
            <h3 className="title-block">About this course</h3>
            <div className="about-list-items">
                <div className="item">
                    <div className="icon"><LengthIcon/></div>
                    <div className="text-wrap">
                        <h3 className="title">Course length</h3>
                        <p className="content">{hours}h {minutes}m</p>
                    </div>
                </div>
                <PointsTypes {...points}/>
            </div>
        </div>
    )
}
