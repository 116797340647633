import React, {useEffect} from 'react';
import './Navigation.scss';
import {NavLink} from "react-router-dom";
import {ReactComponent as DashboardIcon} from "assets/vectors/menu-icons/inactive/dashboard.svg"
import {ReactComponent as AllCoursesIcon} from "assets/vectors/menu-icons/inactive/all-courses.svg";
import {ReactComponent as CertificationIcon} from "assets/vectors/menu-icons/inactive/certification.svg";
import {ReactComponent as ResourcesIcon} from "assets/vectors/menu-icons/inactive/resources.svg";
import {ReactComponent as MedicalIcon} from "assets/vectors/menu-icons/inactive/medical.svg";
import {ReactComponent as ContactUsIcon} from "assets/vectors/menu-icons/inactive/contact-us.svg";
import {ReactComponent as DashboardIconActive} from "assets/vectors/menu-icons/active/dashboard.svg"
import {ReactComponent as AllCoursesIconActive} from "assets/vectors/menu-icons/active/all-courses.svg";
import {ReactComponent as CertificationIconActive} from "assets/vectors/menu-icons/active/certification.svg";
import {ReactComponent as ResourcesIconActive} from "assets/vectors/menu-icons/active/resources.svg";
import {ReactComponent as MedicalIconActive} from "assets/vectors/menu-icons/active/medical.svg";
import {ReactComponent as ContactUsIconActive} from "assets/vectors/menu-icons/active/contact-us.svg";
import UserData from "components/common/Header/UserInfo/UserData/UserData";
import {useMediaQuery} from "react-responsive";
import useViewportHeight from "../../../hooks/useViewportHeight";



export default function Navigation({show = false}) {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1200px)'});

    useViewportHeight();

    return (
        <div className={`navigation${show ? ' open': ''}`}>
            <div className={`navigation-top`}>
                <nav className={`menu`}>
                    <NavLink to={'/dashboard'}>
                        {({isActive}) => (
                            <>
                                {isActive ? <DashboardIconActive className={`icon`}/> :
                                    <DashboardIcon className={`icon`}/>}
                                <span className={`menu-name`}>Dashboard</span>
                            </>
                        )}
                    </NavLink>
                    <NavLink to='/courses'>
                        {({isActive}) => (
                            <>
                                {isActive ? <AllCoursesIconActive className={`icon`}/> :
                                    <AllCoursesIcon className={`icon`}/>}
                                <span className={`menu-name`}>All courses</span>
                            </>

                        )}
                    </NavLink>
                    <NavLink to={'/my-certifications'}>
                        {({isActive}) => (
                            <>
                                {isActive ? <CertificationIconActive className={`icon`}/> :
                                    <CertificationIcon className={`icon`}/>}
                                <span className={`menu-name`}>My certifications</span>
                            </>
                        )}
                    </NavLink>
                    {/*<NavLink to={'/resources'}>*/}
                    {/*    {({isActive}) => (*/}
                    {/*        <>*/}
                    {/*            {isActive ? <ResourcesIconActive className={`icon`}/> :*/}
                    {/*                <ResourcesIcon className={`icon`}/>}*/}
                    {/*            <span className={`menu-name`}>Resources</span>*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*</NavLink>*/}
                </nav>
            </div>
            <div className={`navigation-bottom`}>
                <nav className={`menu`}>
                    {/*TODO tempoorary hidden until the page is done*/}
                    {/*<NavLink to={'/medical-societies'}>*/}
                    {/*    {({isActive}) => (*/}
                    {/*        <>*/}
                    {/*            {isActive ? <MedicalIconActive className={`icon`}/> : <MedicalIcon className={`icon`}/>}*/}
                    {/*            <span className={`menu-name`}>Medical Societies</span>*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*</NavLink>*/}
                    <NavLink to={'/contact-us'}>
                        {({isActive}) => (
                            <>
                                {isActive ? <ContactUsIconActive className={`icon`}/> :
                                    <ContactUsIcon className={`icon`}/>}
                                <span className={`menu-name`}>Contact us</span>
                            </>
                        )}
                    </NavLink>
                </nav>
                {
                    isTabletOrMobile &&
                    <UserData />
                }
            </div>
        </div>
    );
};