import React from 'react';
import './Inputs.scss';

const Radio = ({label, ...otherProps}) => {
    return (
        <label className='c-input'>
            <input type="radio" className='c-input__input' {...otherProps}/>
            <div className='c-input__box c-input__box--radio'/>
            {label}
        </label>
    );
};

export default Radio;