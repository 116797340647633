import React from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import "./Courses.scss";
import CoursesList from "components/pages/Courses/CoursesList";
import EmptyCourses from "components/pages/Courses/EmptyCourses";

export default function CoursesOverview({allCourses}) {
    const statusSearchCourses = true;

    return (
        <>
            {
                statusSearchCourses ?
                    <PerfectScrollbar className={`scrollbar-container`}>
                        <CoursesList allCourses={allCourses}/>

                        {/*<RecommendedCourses/>*/}
                    </PerfectScrollbar>
                    :
                    <EmptyCourses/>
            }
        </>
    )
}