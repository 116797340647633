import React from 'react';
import './Inputs.scss';
import {ReactComponent as CheckIcon} from "assets/vectors/checked.svg";

const Checkbox = ({label, ...otherProps}) => {
    return (
        <label className='c-input'>
            <input type="checkbox" className='c-input__input' {...otherProps}/>
            <div className='c-input__box c-input__box--checkbox'>
                <CheckIcon className='c-input__icon'/>
            </div>
            {label}
        </label>
    );
};

export default Checkbox;