import form from '../constants/contactForm';

const initialState = {
    loading: false,
    submitError: '',
    submitSuccess: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case form.SUBMIT_FORM:
            return {
                ...state,
                ...initialState,
                loading: true
            };
        case form.SUBMIT_FORM_SUCCESS:
            return {
                ...state,
                loading: false,
                submitError: '',
                submitSuccess: true
            };
        case form.SUBMIT_FORM_ERROR:
            return {
                ...state,
                loading: false,
                submitError: payload,
                submitSuccess: false
            };
        case form.CLEAR_SUBMIT_FORM:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}