import React, {useEffect} from "react";
import {Link, useNavigate, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Button from "components/common/Button/Button";
import {ReactComponent as BackArrow} from "assets/vectors/back-arrow.svg";
import Preview from "components/pages/Course/OverviewDetails/Preview/Preview";
import Content from "components/pages/Course/OverviewDetails/Content/Content";
import Skills from "components/pages/Course/OverviewDetails/Skills/Skills";
import {startCourse} from "redux/actions/startCourse";
import MyProgress from "components/pages/Course/OverviewDetails/MyProgress/MyProgress";
import CheckedLabel from "components/pages/Course/MyCourse/CheckedLabel/CheckedLabel";
import {addFavoriteCourse} from "redux/actions/favoriteCourse";
import action from '../../../../redux/constants/favoriteCourses';
import {getProgressCourseLink} from "../../../../helpers/courses";

export default function OverviewDetails({course}) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const {startCourseLoading, startedCourse} = useSelector(state => state.singleCourse);
    const {addToFavoriteLoading, addedToFavoriteCourse} = useSelector(state => state.favoriteCourses);
    const detailedCourseLink = getProgressCourseLink(course.topics);
    const location = useLocation();

    const getBackLink = () => {
        const from = location.state?.from?.pathname;
        const status = course.is_favoured ? 'favorite' : course.status;

        if (from) return from;

        switch (status) {
            case 'completed':
                return '/dashboard/completed';
            case 'favorite':
                return '/dashboard/my-courses';
            case 'in_progress':
                return '/dashboard';
            default:
                return '/courses';
        }
    };

    const handleStartCourse = () => {
        dispatch(startCourse(course.id))
    };

    const handleAddToFavorite = () => {
        dispatch(addFavoriteCourse(course.id))
    };

    useEffect(() => {
        if (startedCourse && startedCourse.id) {
            navigate(`/dashboard/my-courses/${startedCourse.id}`);
        }

        if (addedToFavoriteCourse) return () => dispatch({type: action.CLEAR_ADDED_FAVORITE_COURSE})

    }, [startedCourse, navigate, addedToFavoriteCourse, dispatch]);

    return (
        <div className="details">
            <section className="headline-course">
                <div className={`back-to-overview`}>
                    <Link className={`back`} to={getBackLink()}>
                        <BackArrow className={`icon`}/>
                    </Link>
                    <h1 className={`title`}>{course.name}</h1>
                </div>

                <div className="add-courses">
                    {/*TODO temporary disabled #task MEC-97*/}
                    {/*{*/}
                    {/*    !course.status ?*/}
                    {/*        addedToFavoriteCourse || course.is_favoured ?*/}
                    {/*            <CheckedLabel text='In favorites'/>*/}
                    {/*            :*/}
                    {/*            <Button type="button"*/}
                    {/*                    onClick={handleAddToFavorite}*/}
                    {/*                    isLoading={addToFavoriteLoading}*/}
                    {/*                    spinner={{size: 28}}*/}
                    {/*                    className="button button--border"*/}
                    {/*            >*/}
                    {/*                Add to My Courses*/}
                    {/*            </Button>*/}
                    {/*        :*/}
                    {/*        null*/}

                    {/*TODO temporary disabled #task MEC-48*/}
                    {/*<Button type="button"*/}
                    {/*        className="button button--border"*/}
                    {/*>*/}
                    {/*    Get certificate*/}
                    {/*</Button>*/}
                </div>
            </section>
            <section className="body-course">
                {course.status && <MyProgress progress={course.completion_percentage}/>}
                {course.overview_logo && <Preview coverImg={course.overview_logo}/>}
                {course.overview_description && <Content text={course.overview_description}/>}
                {course.medical_categories?.length > 0 && <Skills skills={course.medical_categories}/>}
                <div className="actions-course">
                    {
                        course.status ?
                            <Link to={detailedCourseLink}>
                                <Button
                                    type="button"
                                    className="button button--green keep"
                                >
                                    Keep learning
                                </Button>
                            </Link>
                            :
                            <Button type="button"
                                    isLoading={startCourseLoading}
                                    disabled={startedCourse || startCourseLoading}
                                    onClick={handleStartCourse}
                                    className="button button--green start">
                                {
                                    startedCourse ? 'Course started' : 'Start course'
                                }
                            </Button>
                    }
                    {/*TODO temporary disabled*/}
                    {/*<Button*/}
                    {/*    type="button"*/}
                    {/*    className="button button--border pause"*/}
                    {/*    onClick={() => {*/}
                    {/*        PauseCourse(courseId)*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Pause course*/}
                    {/*</Button>*/}
                </div>
            </section>
        </div>
    );
}
