import React from 'react';
import './CheckedLabel.scss';
import {ReactComponent as CheckIcon} from "assets/vectors/check-default.svg";

const CheckedLabel = ({text = 'Completed'}) => {
    return (
        <div className='check-label'>
            {text}
            <CheckIcon className='check-label__icon'/>
        </div>
    );
};

export default CheckedLabel;