import React from "react";
import EmptyTab from "components/pages/Dashboard/Tabs/EmptyTab";
import {ArticlesTabs} from './testData'
import ArticlesResource from "components/pages/Resources/ArticlesResource";

export default function Articles(props) {
    const TextEmpty = '1 You don’t have any active courses yet. To start learning select course from page “All courses” and start learn it';
    return (
        <>
            {
                props.statusEmpty ?

                    <div className={`in-progress`}>

                        <div className="list-items">

                            {
                                ArticlesTabs.map((item) => (
                                        <ArticlesResource key={item.id} item={item}/>
                                    )
                                )
                            }

                        </div>
                    </div>
                    :
                    <EmptyTab emptyText={TextEmpty} />
            }

        </>
    )
}