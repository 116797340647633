import React, {useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux"
import Button from "components/common/Button/Button";
import uploadPhotoIcon from "assets/vectors/settings-icons/upload-photo.svg";
import {Formik, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {setAccountAvatar} from "redux/actions/accountSettings";

export default function Avatar() {
    const {avatarLoading} = useSelector(state => state.accountSettings);
    const [fileName, setFileName] = useState(null)
    const dispatch = useDispatch();
    const hiddenFileInput = useRef(null);
    const FILE_SIZE_FOR_AVATAR = 5 * 1024 * 1024;
    const SUPPORTED_FORMATS_AVATAR = [
        "image/jpg",
        "image/jpeg",
        "image/png",
    ];
    const handleLoadImgClick = () => hiddenFileInput.current.click();

    const handleLoadImgChange = (event, setFieldValue, handleBlur) => {
        const avatarFileUploaded = event.target.files[0];

        setFileName(avatarFileUploaded.name)
        handleBlur(event);

        if (avatarFileUploaded) {
            let imageUrl;
            let reader = new FileReader();

            reader.onloadend = () => {
                imageUrl = reader.result;

                setFieldValue('avatar', {
                    avatarSize:avatarFileUploaded.size,
                    imageUrl,
                    avatarType:avatarFileUploaded.type,
                    allInfo:avatarFileUploaded
                });
            };

            reader.readAsDataURL(avatarFileUploaded);
        }
    };

    return (
        <Formik
            initialValues={{
                avatar: '',
            }}
            validationSchema={Yup.object({
                avatar: Yup.mixed()
                    .test(
                        "fileSize",
                        "File too large",
                        (value) => {
                        return value && value.avatarSize <= FILE_SIZE_FOR_AVATAR
                    })
                    .test(
                        "fileFormat",
                        "Unsupported Format",
                        (value) => {
                            return value && SUPPORTED_FORMATS_AVATAR.includes(value.avatarType)
                        })

            })}
            onSubmit={
                (values, {setErrors, setSubmitting, setStatus}) => {
                    setSubmitting(false);
                    setStatus(false);
                    dispatch(setAccountAvatar({ accountData: values, setErrors: setErrors}));
                }
            }
        >

            {({
                  values,
                  handleSubmit,
                  errors,
                  touched,
                  isSubmitting,
                  dirty,
                  setFieldValue,
                  handleBlur,
                  status,
                  setStatus,
                  isValid,
                  ...props
              }) => {

                return (
                    <Form>
                        <div className="avatar-wrap">
                            <div className="upload-wrap" onClick={handleLoadImgClick}>
                                <img className={`icon-upload`} src={uploadPhotoIcon} alt="icon upload"/>
                                <p className={`upload-file-description`}>Maximum file size is 5 MB </p>
                                {
                                    fileName && <p className={`file-name`}>{fileName}</p>
                                }
                                <ErrorMessage className={`upload-avatar-error`} component="div" name="avatar"/>
                            </div>
                            <div className="upload-actions-wrap">
                                <Button
                                    type="submit"
                                    isLoading={avatarLoading}
                                    spinner={{color: 'white'}}
                                    className="button button--green"
                                    disabled={isSubmitting || !isValid || !dirty}
                                >
                                    Upload
                                </Button>
                            </div>
                        </div>
                        <input
                            accept='image/*'
                            type="file"
                            ref={hiddenFileInput}
                            name="avatar"
                            onChange={event => handleLoadImgChange(event, setFieldValue, handleBlur)}
                            style={{display: "none"}}
                        />
                    </Form>
                )
            }}
        </Formik>
    );
};