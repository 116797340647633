import React, {useEffect} from "react";
import './Login.scss';
import LoginPage from "assets/vectors/login/login.svg";
import modalConstants from "redux/constants/modal";
import {Navigate, useSearchParams, Link} from 'react-router-dom';
import {closeModal, openModal} from "redux/actions/modal";
import {useDispatch} from "react-redux";
import LoginServices from "components/pages/Login/LoginServices/LoginServices";
import useLoginServices from "../../../hooks/useLoginServices";
import {loginRequest} from "redux/actions/login";
import Header from "components/common/Header/Header";
import {useRequireRegister} from "../../../hooks/useRequireRegister";
import LoadingSpinner from "components/common/LoadingSpinner/LoadingSpinner";

const Login = () => {
    let [searchParams] = useSearchParams();
    const loginServices = useLoginServices();
    const isRegisterRequired = useRequireRegister();
    const authCode = searchParams.get('code');
    const dispatch = useDispatch();

    useEffect(() => {
        if (authCode) {
            dispatch(openModal(modalConstants.AUTHORIZATIONS));
            dispatch(loginRequest(authCode));
        }

        return () => dispatch(closeModal());

    }, [authCode]);

    return (
        isRegisterRequired ?
            <Navigate replace to={'/login/form'}/>
            :
            <>
                <Header isUserAuthorized={false}/>
                <div className='site-container-not-authorized'>
                    <div className='page-login'>
                        <div className='login-wrap'>
                            <div className="text-title">
                                <h1>Login</h1>
                            </div>
                            <div className="text-description">
                                <p>Pick a service you want to use to log in</p>
                            </div>
                            <div className="btn-wrap">
                                {
                                    loginServices && Object.keys(loginServices).length !== 0 ?
                                        <LoginServices services={loginServices}/>
                                        :
                                        <LoadingSpinner/>
                                }
                                <Link to={'form'} className='admin-login' state={'isAdmin'}>
                                    <span className='link'>Login</span> as administrator
                                </Link>
                            </div>
                            <div className="image-wrap">
                                <img src={LoginPage} alt="Login page img"/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
};

export default Login;