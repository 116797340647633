import React from 'react';
import {ReactComponent as PrivacyIconActive} from "assets/vectors/settings-icons/privacy-active.svg";
import {ReactComponent as NotificationSettingsIconActive} from "assets/vectors/settings-icons/settings-active.svg";
import {ReactComponent as ProfileIconActive} from "assets/vectors/settings-icons/profile-active.svg";

const MobileNavTitle = ({pathname = ''}) => {
    const getIconAndTitle = () => {
        if (pathname.includes('/privacy')) return {
            icon: <PrivacyIconActive/>,
            title: 'Privacy'
        };
        if (pathname.includes('/notification-settings')) return {
            icon: <NotificationSettingsIconActive/>,
            title: 'Notification settings'
        };

        return {
          icon: <ProfileIconActive/>,
          title: 'Profile'
        };
    };

    const navTitle = getIconAndTitle();

    return (
        <>
            <div className='profile-icon'>{navTitle.icon}</div>
            <span className="text">{navTitle.title}</span>
        </>
    );
};

export default MobileNavTitle;