import React from 'react';
import {ReactComponent as CompleteIcon} from "assets/vectors/single-course/quiz-complete.svg";
import {Link, useLocation} from "react-router-dom";

const CompletedQuiz = () => {
    const location = useLocation();

    return (
        <section className='quiz__card quiz__card--complete'>
            <h2 className='quiz__title quiz__title--complete'>You have answered all questions</h2>
            <div className="quiz__content">
                <CompleteIcon className='quiz__complete-icon'/>
                <Link className='button button--green quiz__complete-btn'
                      to={location.pathname}>
                    Ok
                </Link>
            </div>
        </section>
    );
};

export default CompletedQuiz;