import React from 'react';
import Button from "components/common/Button/Button";
import {useSelector} from "react-redux";
import './NavButtons.scss';

const NavButtons = ({saveQuiz, next, prev, steps = {}}) => {
    const {saveQuizLoading} = useSelector(state => state.singleCourse);
    const isFirst = steps.current === 0;
    const isLast = steps.current >= steps.total - 1;


    return (
        <div className='nav-buttons'>
            <Button className='button button--green'
                    onClick={prev}
                    disabled={isFirst}
            >
                Prev
            </Button>
            {
                isLast && !steps.required ?
                    <Button className='button button--green'
                            onClick={saveQuiz}
                            spinner={{color: 'white'}}
                            isLoading={saveQuizLoading}
                            disabled={steps.required || saveQuizLoading}
                    >
                        Submit
                    </Button>
                    :
                    <Button className='button button--green'
                            onClick={next}
                            disabled={steps.required}
                    >
                        {
                            steps.required ?
                                '*Required'
                                :
                                'Next'
                        }
                    </Button>
            }
        </div>
    );
};

export default NavButtons;