import React from "react";
import {ReactComponent as CalendarIcon} from "assets/vectors/dashboard/calendar.svg";

export default function ListItems (){

    return(
        <div className="item">
            <div className="date">
                <div className="number">23</div>
                <div className="day-of-week">Thu</div>
            </div>
            <div className="info">
                <h3 className="title">Anatomy and Pathology required for Medical Coding Training</h3>
                <div className="full-date">
                    <div className="icon"><CalendarIcon/></div>
                    <div className="calendar-date">23 September 2222</div>
                </div>
            </div>
        </div>
    )
}
