import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getMySingleCourse, getSingleCourse} from "redux/actions/singleCourse";
import {clearState} from "redux/actions/clearState";
import action from '../redux/constants/singleCourse';

export const useCourseData = () => {
    const dispatch = useDispatch();
    const {courseId, myCourseId} = useParams();
    const {course} = useSelector(state => state.singleCourse);

    useEffect(() => {
        if (courseId) {
            dispatch(getSingleCourse(courseId));
        }

        if (myCourseId) {
            dispatch(getMySingleCourse(myCourseId));
        }

        return () => dispatch(clearState(action.CLEAR_SINGLE_COURSE));

    }, [courseId, myCourseId, dispatch]);

    return course;
}