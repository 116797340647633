import React from "react";
import EmptyTab from "components/pages/Dashboard/Tabs/EmptyTab";
import {DocumentsTabs} from './testData'
import DocumentsResource from "components/pages/Resources/DocumentsResource";

export default function Documents(props) {
    const TextEmpty = '44 You don’t have any active courses yet. To start learning select course from page “All courses” and start learn it';
    return (
        <>
            {
                props.statusEmpty ?

                    <div className={`in-progress`}>

                        <div className="list-items">

                            {
                                DocumentsTabs.map((item) => (
                                        <DocumentsResource key={item.id} item={item}/>
                                    )
                                )
                            }

                        </div>
                    </div>
                    :
                    <EmptyTab emptyText={TextEmpty} />
            }

        </>
    )
}