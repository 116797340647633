import React from "react";
import {useDispatch, useSelector} from "react-redux"

// import userAvatar from "assets/images/ava.jpeg";
import defaultAva from "assets/vectors/defaultAva.svg";
import {ReactComponent as ProfileMenuArrow} from "assets/vectors/arrow-down-green.svg";
import ProfileMenu from "components/common/Header/UserInfo/ProfileMenu/ProfileMenu";

export default function UserData() {

    const getCurrentUserInfo = useSelector(state => state.userInfo);

    return (
        <>
            {
                getCurrentUserInfo &&

                <div className="user-data">
                    <div className="user-avatar">
                        <img src={ getCurrentUserInfo.avatar ? getCurrentUserInfo.avatar :defaultAva} alt="111 user avatar"/>
                    </div>
                    <div className="user-full-name">{getCurrentUserInfo.name} {getCurrentUserInfo.surname}</div>

                    <div className={`arrow`}><ProfileMenuArrow/></div>

                    <ProfileMenu/>
                </div>

            }
        </>


    )
}