import React from "react";
import "./NotificationSettings.scss";

export default function NotificationSettings() {

    return (
        <section className={`page-notification-settings`}>
            <h1>NotificationSettings hello</h1>
        </section>
    )

}