import React from "react";
import "./Course.scss";
import {useSelector} from "react-redux";
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import {useCourseData} from "../../../hooks/useCourseData";
import CourseOverview from "components/pages/Course/CourseOverview/CourseOverview";
import MyCourse from "components/pages/Course/MyCourse/MyCourse";
import NotFound from "components/pages/NotFound/NotFound";
import {getProgressCourseLink} from "../../../helpers/courses";
import EmptyLayout from "components/common/EmptyLayout/EmptyLayout";

export default function Course() {
    const course = useCourseData();
    const location = useLocation();
    const {courseError} = useSelector(state => state.singleCourse);
    const isInProgress = course.status === 'in_progress';
    const from = location.state?.from?.pathname;
    let progressCourseLink = null;

    if (isInProgress && from) {
        progressCourseLink = getProgressCourseLink(course.topics);
    }

    return (
        <section className='details-course'>
            {
                Object.keys(course).length === 0 ?
                    <EmptyLayout isEmpty={courseError}/>
                    :
                    <Routes>
                        <Route path='/' element={
                            progressCourseLink ?
                                <Navigate replace to={progressCourseLink}/>
                                :
                                <CourseOverview course={course}/>

                        }/>
                        <Route path='/topic/:topicId' element={<MyCourse course={course}/>}/>
                        <Route path='/lesson/:lessonId' element={<MyCourse course={course}/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
            }
        </section>
    )
}