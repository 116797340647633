import courses from '../constants/favoriteCourses';

const initialState = {
    courses: [],
    loading: true,
    coursesError: '',
    addToFavoriteLoading: false,
    addedToFavoriteCourse: null
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case courses.GET_FAVORITE_COURSES_SUCCESS:
            return {
                ...state,
                courses: payload,
                loading: false,
                coursesError: '',
            };
        case courses.GET_FAVORITE_COURSES_ERROR:
            return {
                ...state,
                courses: [],
                loading: false,
                coursesError: payload,
            };
        case courses.ADD_FAVORITE_COURSE:
            return {
                ...state,
                addToFavoriteLoading: true
            };
        case courses.ADD_FAVORITE_COURSE_ERROR:
            return {
                ...state,
                addedToFavoriteCourse: null,
                addToFavoriteLoading: false
            };
        case courses.ADD_FAVORITE_COURSE_SUCCESS:
            return {
                ...state,
                addedToFavoriteCourse: payload,
                addToFavoriteLoading: false
            };
        case courses.CLEAR_ADDED_FAVORITE_COURSE:
            return {
                ...state,
                addedToFavoriteCourse: null,
                addToFavoriteLoading: false
            };
        default:
            return state;
    }
}