import React from "react";

import UpcomingCourses from "components/pages/Dashboard/Sidebar/UpcomingCourses/UpcomingCourses";
import MyPoints from "components/pages/Dashboard/Sidebar/MyPoints";

export default function Sidebar() {
    return (
        <div className="sidebar">
            <MyPoints/>
            <UpcomingCourses/>
        </div>
    )
}
