export const ArticlesTabs = [
    {
        id: 1,
        title: 'Biology molecular',

        description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
    },
    {
        id: 2,
        title: 'Biology molecular 2',
        description: 'Quae voluptatibus. Varius, odit soluta torquent, tenetur posuere nullam vitae aut necessitatibus quasi praesent arcu iure, euismod facilisi libero ipsam, nobisq',
    },
    {
        id: 3,
        title: 'Biology molecular 3',

        description: 'Totam suscipit tempora, aenean officiis, nisl ducimus urna, pretium, culpa. Expedita inventore! Blandit consequat tincidunt officia conubia laboris integer voluptatum? ',
    },
];

export const DocumentsTabs = [
    {
        id: 1,
        title: 'Biology molecular3',
        description: 'Saepe sunt morbi justo. Placeat ullam facilisis praesentium, sodales nunc, vestibulum nascetur voluptatibus laboriosam blanditiis. Cubilia. Labore, bibendum, bibendum sociis,',
    },
    {
        id: 2,
        title: 'Biology molecular 4',
        startingDate: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
    },
    {
        id: 3,
        title: 'Biology molecular 5',
        description: 'Saepe sunt morbi justo. Placeat ullam facilisis praesentium, sodales nunc, vestibulum nascetur voluptatibus laboriosam blanditiis. Cubilia. Labore, bibendum, bibendum sociis,',
    },
];

export const VideoTabs = [
    {
        id: 57,
        title: 'Biology molecular 6',

        description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
    },
    {
        id: 462,
        title: 'Biology molecular 7',
        description: 'Totam suscipit tempora, aenean officiis, nisl ducimus urna, pretium, culpa. Expedita inventore! Blandit consequat tincidunt officia conubia laboris integer voluptatum? ',
    },
    {
        id: 2343,
        title: 'Biology molecular 8',
        description: 'Quae voluptatibus. Varius, odit soluta torquent, tenetur posuere nullam vitae aut necessitatibus quasi praesent arcu iure, euismod facilisi libero ipsam, nobisq',

    },
];

export const InteractivePresentationsTabs = [
    {
        id: 351,
        title: 'Biology molecular 10',
        description: 'Enim? Libero, tincidunt tenetur mi placeat distinctio mus, nec praesent necessitatibus reprehenderit cras lacus curabitur euismod ipsam facilisis laboriosam iusto eum deleniti ',
    },
    {
        id: 275,
        title: 'Biology molecular 11',
        startingDate: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
    },
    {
        id: 333,
        title: 'Biology molecular 12',
        description: 'Saepe sunt morbi justo. Placeat ullam facilisis praesentium, sodales nunc, vestibulum nascetur voluptatibus laboriosam blanditiis. Cubilia. Labore, bibendum, bibendum sociis,',
    },
];