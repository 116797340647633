import React from 'react';
import {ReactComponent as PresentationActive} from "assets/vectors/topics/active/presentation.svg";
import {ReactComponent as PresentationDefault} from "assets/vectors/topics/default/presentation.svg";
import {ReactComponent as PresentationCompleted} from "assets/vectors/topics/completed/presentation.svg";
import {ReactComponent as CompletedIcon} from "assets/vectors/check.svg";

const PresentationIcon = ({completed, active}) => {
    if (completed) {
        return <><PresentationCompleted /><CompletedIcon className='complete-icon'/></>;
    }

    return active ? <PresentationActive /> : <PresentationDefault />;
};

export default PresentationIcon;