import React from "react";
import "./CoursesList.scss";
import {ReactComponent as ClockIcon} from "assets/vectors/clock.svg";
import {ReactComponent as PencilIcon} from "assets/vectors/pencil.svg";
import {Link, useLocation} from "react-router-dom";
import StatusOfCourse from "components/pages/Courses/StatusOfCourse";

export default function CoursesList({allCourses}) {
    const location = useLocation();

    return (
        <section className="courses-list">
            <div className="list">
                {
                    allCourses.map(item => {
                            const singleCourseLink = `/courses`;
                            const mySingleCourseLink = `/dashboard/my-courses`;
                            const courseLink = item.status ? mySingleCourseLink : singleCourseLink;

                            return (
                                <div className="item" key={item.id}>
                                    <Link to={`${courseLink}/${item.status ? item.user_course_id : item.id}`}
                                          state={{from: location}}
                                    >
                                        <div className="image-container">
                                            <img className={`image`} src={item.catalog_logo} alt=""/>
                                            <div className="item-data">
                                                {
                                                    item.status && <StatusOfCourse getStatus={item.status}/>
                                                }
                                                <div className="time">
                                                    <div className="icon"><ClockIcon/></div>
                                                    <div className="minutes">{item.course_duration} min</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-container">
                                            <div className="title">{item.name}</div>
                                            <div className="lessons">
                                                <div className="icon"><PencilIcon/></div>
                                                <div className="count-lessons">{item.total_lessons}&nbsp;lesson</div>
                                            </div>
                                        </div>
                                        <div className="description">{item.catalog_description}</div>
                                    </Link>
                                </div>
                            )
                        }
                    )
                }
            </div>
        </section>
    )
}