import action from "redux/constants/popup";

export function showPopup(content = {}, isSuccess = false, closeTimer) {
    return {
        type: action.SHOW_POPUP,
        payload: {content, closeTimer, isSuccess}
    }
}

export function hidePopup() {
    return {
        type: action.HIDE_POPUP
    }
}

