import action from '../constants/startCourse';

export const startCourse = (courseId) => ({
    type: action.START_COURSE,
    payload: courseId
});

export const startCourseError = (errorData) => ({
    type: action.START_COURSE_ERROR,
    payload: errorData,
});

export const startCourseSuccess = (responseData) => ({
    type: action.START_COURSE_SUCCESS,
    payload: responseData
});