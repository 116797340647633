import {put, fork, takeLatest, call, select, delay} from "redux-saga/effects";
import action from 'redux/constants/quiz';
import {getData, postData} from "redux/helpers/requestHandlers";
import {QUIZ_ROUTE, QUIZ_SAVE_ROUTE} from "config/api";
import ApiError from "../../services/ErrorHandler/ApiError";
import responseHandler from "redux/helpers/responseHandler";
import {closeQuiz, getQuizError, getQuizSuccess, saveQuizError, saveQuizSuccess} from "redux/actions/quiz";
import {showPopup} from "redux/actions/popup";
import qs from 'qs';

function* watchGetQuizData() {
    yield takeLatest(action.GET_QUIZ, getQuizRequest)
}

function* getQuizRequest({payload}) {
    const token = yield select(state => state.login.authToken);
    const {data} = yield call(getData(QUIZ_ROUTE(payload), token));

    yield responseHandler(data, function* () {
        yield put(getQuizSuccess(data.data));
    }, function* () {
        const err = new ApiError(data, `Can't get quiz.`);

        yield put(getQuizError(err.message));
        yield put(showPopup({title: 'Error', text: err.message}));
    })
}

function* watchSaveQuizData() {
    yield takeLatest(action.SAVE_QUIZ, saveQuizRequest)
}

function* saveQuizRequest({payload}) {
    const {lessonId, answers, closedQuiz} = payload;
    const token = yield select(state => state.login.authToken);
    const {data} = yield call(postData(QUIZ_SAVE_ROUTE(lessonId), token, qs.stringify(answers), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
    }));

    yield responseHandler(data, function* () {
        yield put(saveQuizSuccess());
        yield put(closeQuiz(closedQuiz));
    }, function* () {
        const err = new ApiError(data, `Can't save quiz answers.`);

        yield put(saveQuizError(err.message));
        yield put(showPopup({title: 'Error', text: err.message}));
    });
}

export default function* () {
    yield fork(watchGetQuizData);
    yield fork(watchSaveQuizData);
}
