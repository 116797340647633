import React from "react";

import {ReactComponent as InteractivePresentationIcon} from "assets/vectors/courses/interactive-presentation.svg";
import {ReactComponent as PdfIcon} from "assets/vectors/courses/pdf.svg";
import {ReactComponent as PptIcon} from "assets/vectors/courses/ppt.svg";
import {ReactComponent as VideoIcon} from "assets/vectors/courses/video.svg";
import {minToHours} from "../../../../../helpers/common";

export default function Materials({pdfFile,duration}) {
    const {hours, minutes} = minToHours(duration);

    // console.log(pdfFile)

    return (
        <div className="materials">
            <h3 className="title-block">Materials</h3>
            {/*TODO For now we've got only one type of lessons. */}
            <div className="list-items">

                {
                    pdfFile.length > 0 &&

                    <a className="files item" href={pdfFile[0]} target='_blank'>
                        <div className="icon"><PdfIcon/></div>
                        <div className="item-details">
                            <div className="head">PDF file</div>
                            {/*<div className="get-info">12h</div>*/}
                        </div>
                    </a>
                }

                <div className="presentations item">
                    <div className="icon"><InteractivePresentationIcon/></div>
                    <div className="item-details">
                        <div className="head">Interactive presentations</div>
                        <div className="get-info">{hours}h {minutes}m</div>
                    </div>
                </div>
                {/*<div className="video item">*/}
                {/*    <div className="icon"><VideoIcon/></div>*/}
                {/*    <div className="item-details">*/}
                {/*        <div className="head">Video lectures</div>*/}
                {/*        <div className="get-info">56h</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="ppt item">*/}
                {/*    <div className="icon"><PptIcon/></div>*/}
                {/*    <div className="item-details">*/}
                {/*        <div className="head">PPT presentation</div>*/}
                {/*        <div className="get-info">24h 48 min</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

