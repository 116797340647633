import courses from '../constants/myCourses';

const initialState = {
    courses: [],
    loading: true,
    coursesError: '',
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case courses.GET_MY_COURSES_SUCCESS:
            return {
                ...state,
                courses: payload,
                loading: false,
                coursesError: '',
            };
        case courses.GET_MY_COURSES_ERROR:
            return {
                ...state,
                courses: [],
                loading: false,
                coursesError: payload,
            };
        default:
            return state;
    }
}