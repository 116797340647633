import React, {useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import Details from "components/pages/Course/MyCourse/Details/Details";
import Topics from "components/pages/Course/MyCourse/Topics/Topics";
import {getMyCourseDetails} from "../../../../helpers/courses";
import FullScreen from "components/pages/Course/FullScreen/FullScreen";

export default function MyCourse({course}) {
    const {lessonId, topicId} = useParams();
    const [searchParams] = useSearchParams();
    const [mobileLessonsOpen, setMobileLessonsOpen] = useState(false);
    const open = searchParams.get('open');

    const topicDetails = getMyCourseDetails({
        id: +lessonId || +topicId,
        topics: course.topics,
        type: lessonId && 'lesson'
    });

    const toggleMobileLessons = () => setMobileLessonsOpen(!mobileLessonsOpen);

    return (
        open === topicDetails.type ?
            <FullScreen lesson={topicDetails}/>
            :
            <>
                <Details courseStatus={course.status}
                         topicDetails={topicDetails}
                         toggleMobileLessons={toggleMobileLessons}
                         courseId={course.id}
                />
                <Topics topics={course.topics}
                        toggleMobileLessons={toggleMobileLessons}
                        mobileLessonsOpen={mobileLessonsOpen}
                        courseId={course.id}
                />
            </>
    )
}