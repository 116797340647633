import React from "react";

import {Link} from "react-router-dom";
import {ReactComponent as ReadIcon} from "assets/vectors/resource/read.svg";
import {ReactComponent as DocumentIcon} from "assets/vectors/resource/documents.svg";
import Button from "components/common/Button/Button";

export default function DocumentsResource({item}){
    //
    //console.log(item);

    return(
        <div className="item">
            <div className="document-prev">
                <DocumentIcon className={`icon-document`} />
            </div>

            <div className="item-info">

                <div className="head">
                    <h3 className="title">{item.title}</h3>
                    <div className="view">
                        <Link to={`/download-file`}>
                            <Button
                                type="button"
                                className="button button--border"
                            >
                                Download
                            </Button>
                        </Link>
                    </div>
                </div>

                <div className="about-resource">
                    {item.description}
                </div>

                <div className="document-info">

                    <div className="view">

                        <ReadIcon className={`icon`} />
                        Viewed by 7 654 users
                    </div>

                </div>
            </div>
        </div>
    )
}
