import React from "react";
import "./MedicalSocieties.scss";

export default function MedicalSocieties() {

    return (
        <section className={`page-medical-societies`}>
            <h1>MedicalSocieties hello</h1>
        </section>
    )

}