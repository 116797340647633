import React, {useState} from "react";

export default function Skills({skills = []}) {
    const [showLimit, setShowLimit] = useState(5);

    const showAllSkills = () => {
        setShowLimit(0);
    };

    const skillList = skills.map((skill, index) => {
        if (showLimit !== 0 && index < showLimit) {
            return <li className={`item`} key={skill.id}>{skill.name}</li>;
        }

        if (showLimit === 0) {
            return <li className={`item`} key={skill.id}>{skill.name}</li>;
        }
    });

    return (
        <div className="skills">
            <div className="title-block">Skills you will gain</div>
            <ul className="skills-list">
                {skillList}
                {
                    showLimit && skills.length > showLimit ?
                        <li className={`item show-all-skills`} onClick={showAllSkills}>
                            Show all
                        </li>
                        :
                        null
                }
            </ul>
        </div>
    )
}
