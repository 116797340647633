import React, {useState, useEffect} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {ReactComponent as ArrowDown} from "assets/vectors/arrow-down-grey.svg";

export default function Navigation({tabs = []}) {
    const location = useLocation();
    const pathName = location?.pathname;
    const baseTab = tabs[0]?.link;
    const matchedToLocationTab = tabs.find(tab => pathName === `${baseTab}/${tab.link}` || pathName === tab.link);
    const [openNav, setOpenNav] = useState(false);

    const toggleNav = () => setOpenNav(!openNav);

    useEffect(() => {
        return () => setOpenNav(false);
    }, [pathName]);

    return (
        <>
            <button onClick={toggleNav} className={`tabs__toggle ${openNav ? ' tabs__toggle--open' : ''}`}>
                {matchedToLocationTab?.title}
                <ArrowDown className='icon'/>
            </button>
            <ul className='tabs__nav'>
                {
                    tabs.map(tab => (
                        <li className='tabs-item' key={tab.link}>
                            <NavLink to={tab.link} end className='tabs-item__link'>{tab.title}</NavLink>
                        </li>
                    ))
                }
            </ul>
        </>
    );
}