import React from "react";
import "./NotFound.scss";
import NotFoundImage from "assets/vectors/not-found/404.svg";

export default function NotFound() {

    return (
        <section className={`page-not-found`}>
            <h1 className={`title-page`}>404</h1>
            <img className={`image-not-found`} src={NotFoundImage} alt=""/>
            <p className={`description-not-found`}>Sorry, the page you requested could not be found</p>
        </section>
    )

}