export default {
    GET_LOGIN_SERVICES: "GET_LOGIN_SERVICES",
    GET_LOGIN_SERVICES_ERROR: "GET_LOGIN_SERVICES_ERROR",
    GET_LOGIN_SERVICES_SUCCESS: "GET_LOGIN_SERVICES_SUCCESS",
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_ADMIN_REQUEST: 'LOGIN_ADMIN_REQUEST',
    LOGIN_ERROR: 'LOGIN_ERROR',
    CLEAR_LOGIN_ERROR: 'CLEAR_LOGIN_ERROR',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT: 'LOGOUT',
};