import action from '../constants/staticData';

export const getStaticData = () => ({
    type: action.GET_STATIC_DATA,
});

export const getStaticDataError = (errorData) => ({
    type: action.GET_STATIC_DATA_ERROR,
    payload: errorData,
});

export const getStaticDataSuccess = (responseData) => ({
    type: action.GET_STATIC_DATA_SUCCESS,
    payload: responseData
});