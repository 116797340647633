export const labelStyles = {
    fontSize: '16px',
    color: '#C9C9C9',
    fontFamily: 'Poppins',
    letterSpacing: '0.01em',

    '&.Mui-focused': {
        color: '#05060B',
        background: 'white',
        padding: '0 3px',
        fontWeight: 300,
        fontSize: '12px',
        lineHeight: '18px',
        transform: 'translate(14px, -9px) scale(1)',
        letterSpacing: '0.03em'
    },
    '&.MuiInputLabel-root': {
        background: 'white',
        padding: '0 3px'
    }
};

export const selectStyles = {
    borderRadius: '6px',
    '& .MuiOutlinedInput-notchedOutline': {
        transition: 'border-color .25s',
    },
    ':hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#1A89A0',
        borderWidth: '1px',
    },
    '& .MuiSelect-select': {
        padding: '14px 32px 14px 16px',
        fontSize: '16px',
        lineHeight: '28px'
    },
    '& .MuiSelect-icon': {
        top: 'calc(50% - 11px)',
        right: '20px'
    }
};