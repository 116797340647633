import React from "react";

import TestImage from "assets/vectors/courses/test.png";
import {Link} from "react-router-dom";
import {ReactComponent as ReadIcon} from "assets/vectors/resource/read.svg";
import Button from "components/common/Button/Button";

export default function ArticlesResource({item}){
    //
    //console.log(item);

    return(
        <div className="item">
            <div className="image-prev">
                <img src={TestImage} alt=""/>
            </div>

            <div className="item-info">

                <div className="head">
                    <h3 className="title">{item.title}</h3>
                    <div className="view">
                        <Link to={`/resources/${item.id}/read`}>
                            <Button
                                type="button"
                                className="button button--border"
                            >
                                Read
                            </Button>
                        </Link>
                    </div>
                </div>

                <div className="about-resource">
                    {item.description}
                </div>

                <div className="viewed">

                    <div className="read">
                        <ReadIcon className={`icon`} />
                        Viewed by 1 234 users
                    </div>

                </div>
            </div>
        </div>
    )
}
