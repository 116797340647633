import React, {useEffect} from "react";
import "./MyCertifications.scss";
import 'react-perfect-scrollbar/dist/css/styles.css';
import Certifications from "components/pages/MyCertifications/Certifications";
import Empty from "components/pages/Empty/Empty";
import SearchCertificate from "components/pages/MyCertifications/SearchCertificate";

import emptyPageImage from "assets/empty/my-certifications.svg";
import {useDispatch, useSelector} from "react-redux";
import {getAllCertificates} from "redux/actions/certificates";
import LoadingSpinner from "components/common/LoadingSpinner/LoadingSpinner";


export default function MyCertifications() {

    const {certificates, loading} = useSelector(state => state.certificates);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllCertificates());
    }, [dispatch]);

    return (
        <section className={`page-my-certifications`}>

            {
                loading ?
                    <LoadingSpinner/>
                    :
                    <>
                        <SearchCertificate/>
                        {
                            certificates.length === 0 ?
                                <Empty
                                    image={emptyPageImage}
                                    desription={`You have no certificates yet. To get certificate you need finish a course`}
                                />
                                :
                                <Certifications allCertificates={certificates}/>
                        }
                    </>
            }
        </section>
    )

}