import React from 'react';
import Checkbox from "components/common/Inputs/Checkbox";
import uniqid from 'uniqid';

const CheckboxGroup = ({options = [], values = [], handleChange}) => {
    return (
        options.map(option => {
                const checkboxId = uniqid();
                const value = values.find(val => val === option.option) || '';

                return (
                    <Checkbox key={checkboxId}
                              value={value}
                              label={option.option}
                              onChange={handleChange}
                              checked={value.includes(option.option)}
                              name={option.option}
                    />
                )
            }
        )
    );
};

export default CheckboxGroup;