import singleCourse from '../constants/singleCourse';
import startCourse from '../constants/startCourse';
import completeLesson from '../constants/completeLesson';
import quiz from '../constants/quiz';
import {transformSingleCourse} from "redux/helpers/transformSingleCourse";

const initialState = {
    course: {},
    courseError: '',
    startCourseLoading: false,
    startedCourse: null,
    completeLessonLoading: false,
    quiz: {},
    quizLoading: false,
    saveQuizLoading: false,
    showModalSuccessCertificate : false
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case singleCourse.GET_SINGLE_COURSE_SUCCESS:
            return {
                ...state,
                course: transformSingleCourse(payload),
                courseError: '',
            };
        case singleCourse.GET_SINGLE_COURSE_ERROR:
            return {
                ...state,
                course: {},
                courseError: payload,
            };
        case startCourse.START_COURSE:
            return {
                ...state,
                startCourseLoading: true,
                startedCourse: null,
            }
        case startCourse.START_COURSE_SUCCESS:
            return {
                ...state,
                startCourseLoading: false,
                startedCourse: payload,
            }
        case startCourse.START_COURSE_ERROR:
            return {
                ...state,
                startCourseLoading: false,
            }
        case completeLesson.COMPLETE_LESSON:
            return {
                ...state,
                completeLessonLoading: true,
            }
        case completeLesson.COMPLETE_LESSON_SUCCESS:
            return {
                ...state,
                course: transformSingleCourse(state.course, payload),
                completeLessonLoading: false,
            }
        case completeLesson.COMPLETE_LESSON_ERROR:
            return {
                ...state,
                completeLessonLoading: false,
            }
        case quiz.GET_QUIZ_SUCCESS:
            return {
                ...state,
                quiz: payload,
                quizLoading: false
            };
        case quiz.GET_QUIZ_ERROR:
            return {
                ...state,
                quiz: {},
                quizLoading: false
            };
        case quiz.GET_QUIZ:
            return {
                ...state,
                quiz: {},
                quizLoading: true
            };
        case quiz.SAVE_QUIZ:
            return {
                ...state,
                saveQuizLoading: true
            }
        case quiz.SAVE_QUIZ_SUCCESS:
        case quiz.SAVE_QUIZ_ERROR:
            return {
                ...state,
                saveQuizLoading: false
            }
        case 'CLOSE_QUIZ':
            return {
                ...state,
                closedQuiz: payload,
                saveQuizLoading: false

            }
        case singleCourse.CLEAR_SINGLE_COURSE:
            return {
                ...initialState,
            };

        case completeLesson.SHOW_MODAL_SUCCESS_CERTIFICATE:
            return {
                ...state,
                showModalSuccessCertificate: true,
            }
        case completeLesson.HIDE_MODAL_SUCCESS_CERTIFICATE:
            return {
                ...state,
                showModalSuccessCertificate: false,
            }

        default:
            return state;
    }
}