import React from 'react';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {ReactComponent as DownArrowIcon} from "assets/vectors/arrow-down-grey.svg";
import {selectStyles} from "components/forms/selectStyles";
import Rating from "components/pages/Course/Quiz/Rating/Rating";
import Textarea from 'react-autosize-textarea';
import RadioGroup from "components/pages/Course/Quiz/RadioGroup/RadioGroup";
import uniqid from 'uniqid';
import CheckboxGroup from "components/pages/Course/Quiz/CheckboxGroup/CheckboxGroup";

const Questions = ({questions = [], setQuizState, quizState}) => {
    const quizStep = quizState.step;

    const handleCheckboxesChange = (e, questionId) => {
        const value = e.target.name;
        const answers = quizState[questionId];
        let updatedAnswers = [];

        const findAnswer = answers.find(answer => answer === value);

        if (findAnswer) {
            updatedAnswers = answers.filter(answer => answer !== value);
        } else {
            updatedAnswers = [...answers, value];
        }

        setQuizState({
            ...quizState, [questionId]: updatedAnswers
        });
    };

    const handleRadioChange = (e, questionId) => {
        setQuizState({
            ...quizState, [questionId]: e.target.name
        });
    };

    const handleInputChange = (e, questionId) => {
        setQuizState({
            ...quizState, [questionId]: e.target.value
        });
    };

    const handleRateChange = (rate, questionId) => {
        setQuizState({
            ...quizState, [questionId]: rate
        });
    };

    const renderQuizStep = () => {
        const questionId = questions[quizStep]?.id;

        switch (questions[quizStep]?.type) {
            case 'text':
                return <Textarea className='quiz__textarea'
                                 value={quizState[questionId]}
                                 onChange={e => handleInputChange(e, questionId)}
                                 placeholder='Your answer'/>;
            case 'radio':
                return <RadioGroup type={questions[quizStep].type}
                                   value={quizState[questionId]}
                                   onChange={e => handleRadioChange(e, questionId)}
                                   options={questions[quizStep]?.options}
                />
            case 'multi_select':
                return <CheckboxGroup type={questions[quizStep].type}
                                      values={quizState[questionId]}
                                      handleChange={e => handleCheckboxesChange(e, questionId)}
                                      options={questions[quizStep]?.options}

                />;
            case 'dropdown':
                return (
                    <Select placeholder='Select'
                            className='quiz__select'
                            value={quizState[questionId]}
                            onChange={e => handleInputChange(e, questionId)}
                            IconComponent={(css = []) => <div {...css}><DownArrowIcon/></div>}
                            sx={selectStyles}
                    >
                        {
                            questions[quizStep]?.options.map(item => (
                                <MenuItem key={uniqid()} value={item.option}>{item.option}</MenuItem>
                            ))
                        }
                    </Select>
                );
            case 'scale1_5':
                return <Rating scale={5}
                               value={quizState[questionId]}
                               handleChange={rate => handleRateChange(rate, questionId)}/>;
            case 'scale1_10':
                return <Rating scale={10}
                               value={quizState[questionId]}
                               handleChange={rate => handleRateChange(rate, questionId)}/>;
            default:
                return null;
        }
    }


    return (
        <>
            <p className='quiz__question'>
                {questions[quizStep]?.question}{questions[quizStep]?.required && '*'}
            </p>
            {renderQuizStep()}
        </>
    );
};

export default Questions;