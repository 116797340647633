import React from "react";
import Select   from "@mui/material/Select";
import {ReactComponent as DownArrowIcon} from "assets/vectors/arrow-down-grey.svg";

const DropdownIndicator = (props) => {
    return (
        <div {...props}>
            <DownArrowIcon className={`dropdown-custom`}  />
        </div>
    );
};

const FormSelect = ({children, form = {}, field = {}, ...otherProps}) => {
    const {name, value} = field;
    const {setFieldValue} = form;

    return (
        <Select
            IconComponent={DropdownIndicator}
            name={name}
            value={value}
            onChange={e => setFieldValue(name, e.target.value)}
            {...otherProps}
        >
            {children}
        </Select>
    );

};


export default FormSelect;