import React, {useEffect, useState} from 'react';
import './ImageWithPlaceholder.scss';

const ImageWithPlaceholder = ({src, alt = 'image', ...props}) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (visible) setVisible(false);
    }, [src])

    return (
        <div className='image-placeholder' {...props}>
            <img src={src}
                 className={`image-placeholder__image ${visible ? 'image-placeholder__image--loaded' : ''}`}
                 onLoad={() => setVisible(true)}
                 alt={alt}/>
        </div>
    );
};

export default ImageWithPlaceholder;