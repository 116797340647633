import React, {useEffect} from 'react';
import viewportHeightFix from "../helpers/viewportHeightFix";

const useViewportHeight = (callback) => {
    // hack functionality to avoid 100vh problems on mobile devices
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    useEffect(() => {
        viewportHeightFix();

        window.addEventListener('resize', viewportHeightFix);

        return () => {
            window.removeEventListener('resize', viewportHeightFix);
            document.documentElement.style.removeProperty('--vh');
        }
    }, []);
};

export default useViewportHeight;