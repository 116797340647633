import popup from 'redux/constants/popup';

const initialState = {
    show: false,
    content: {
        title: '',
        text: ''
    },
    closeTimer: null,
    isSuccess: false
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case popup.SHOW_POPUP:
            return {
                ...state,
                show: true,
                content: {
                    ...state.content,
                    ...payload.content
                },
                closeTimer: payload.closeTimer,
                isSuccess: payload.isSuccess
            };
        case popup.HIDE_POPUP:
            return {
                ...state,
                show: false,
                closeTimer: null
            };
        default:
            return state;
    }
}