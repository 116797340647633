import {put, fork, takeLatest, select, call} from "redux-saga/effects";
import action from 'redux/constants/login';
import {getData, postData} from "redux/helpers/requestHandlers";
import {LOGIN_ADMIN_URL, LOGIN_SERVICES_URL, LOGIN_SRXL_URL, LOGOUT_URL} from "config/api";
import {
    getLoginServicesError,
    getLoginServicesSuccess,
    loginError,
    loginSuccess, logOut,
} from "redux/actions/login";
import {clearLocalStorage, saveAuthTokenToStorage} from "../../services/login";
import {registerRequired} from "redux/actions/register";
import {showPopup} from "redux/actions/popup";
import ApiError from "../../services/ErrorHandler/ApiError";
import responseHandler from "redux/helpers/responseHandler";

function* watchGetLoginServices() {
    yield takeLatest(action.GET_LOGIN_SERVICES, getLoginServicesRequest)
}

function* getLoginServicesRequest() {
    const {data} = yield call(getData(LOGIN_SERVICES_URL));

    yield responseHandler(data, function* () {
        yield put(getLoginServicesSuccess(data.data));
    }, function* () {
        const err = new ApiError(data, `Can't get login services`);

        yield put(getLoginServicesError(err.message));
        yield put(showPopup({title: err.name, text: err.message}));
    })
}

function* watchLogin() {
    yield takeLatest(action.LOGIN_REQUEST, loginRequest)
}

function* loginRequest({payload}) {
    const {data} = yield call(postData(LOGIN_SRXL_URL, null, {code: payload}));

    yield responseHandler(data, function* () {
        const responseData = data.data;

        if (responseData.uid) {
            yield put(registerRequired(responseData));

        } else if (responseData.token) {
            yield saveAuthTokenToStorage(responseData.token);
            yield put(loginSuccess(responseData.token));

        } else {
            yield put(showPopup({title: 'Login error', text: `Can't log in.`}));
        }
    }, function* () {
        const err = new ApiError(data, `Login request failed`);

        yield put(loginError(err.message));
        yield put(showPopup({title: err.name, text: err.message}));
    })
}

function* watchAdminLogin() {
    yield takeLatest(action.LOGIN_ADMIN_REQUEST, loginAdminRequest)
}

function* loginAdminRequest({payload}) {
    const {data} = yield call(postData(LOGIN_ADMIN_URL, null, payload));

    yield responseHandler(data, function* () {
        const responseData = data.data;

        if (responseData.token) {
            yield saveAuthTokenToStorage(responseData.token);
            yield put(loginSuccess(responseData.token));

        } else {
            yield put(showPopup({title: 'Login error', text: `Can't log in.`}));
        }
    }, function* () {
        const err = new ApiError(data);

        yield put(loginError(err.getErrorMessages()));
    })
}

function* watchLogout() {
    yield takeLatest(action.LOGOUT_REQUEST, handleLogout);
}

function* handleLogout() {
    const token = yield select(state => state.login.authToken);
    const {data} = yield call(postData(LOGOUT_URL, token));

    yield responseHandler(data, function* () {
        yield clearLocalStorage();
        yield put(logOut());
    }, function* () {
        console.error(`Service logout error`);
        yield clearLocalStorage();
        yield put(logOut());
    });
}

export default function* () {
    yield fork(watchGetLoginServices);
    yield fork(watchLogin);
    yield fork(watchLogout);
    yield fork(watchAdminLogin);
}