import {put, fork, takeLatest, call, select} from "redux-saga/effects";
import action from 'redux/constants/singleCourse';
import {getData} from "redux/helpers/requestHandlers";
import {GET_MY_SINGLE_COURSE_URL, GET_SINGLE_COURSE_URL} from "config/api";
import ApiError from "../../services/ErrorHandler/ApiError";
import {showPopup} from "redux/actions/popup";
import {getSingleCourseError, getSingleCourseSuccess} from "redux/actions/singleCourse";
import responseHandler from "redux/helpers/responseHandler";

function* watchGetSingleCourse() {
    yield takeLatest([action.GET_SINGLE_COURSE, action.GET_MY_SINGLE_COURSE], getSingleCourseRequest);
}

function* getSingleCourseRequest({type, payload}) {
    const route = type === action.GET_SINGLE_COURSE ? GET_SINGLE_COURSE_URL(payload) : GET_MY_SINGLE_COURSE_URL(payload);
    const {authToken} = yield select(state => state.login);
    const {data} = yield call(getData(route, authToken));

    yield responseHandler(data, function* () {
        yield put(getSingleCourseSuccess(data.data));
    }, function* () {
        const err = new ApiError(data, `Can't get course details.`);

        yield put(getSingleCourseError(err.message));
        yield put(showPopup({title: 'Error', text: err.message}));
    })
}

export default function* () {
    yield fork(watchGetSingleCourse);
}
