import userProfile from '../constants/userProfile';

const initialState = {
    userProfileInfo: {},
    userProfileError: '',
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case userProfile.UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfileInfo: payload,
                userProfileError: '',
                // deleteUserProfile: false,
            };
        case userProfile.UPDATE_USER_PROFILE_ERROR:
            return {
                ...state,
                userProfileInfo: {},
                userProfileError: payload,
                // deleteUserProfile: false,
            };

        // case userProfile.DELETE_USER_PROFILE_SUCCESS:
        //     return {
        //         ...state,
        //         userProfileInfo: {},
        //         userProfileError: '',
        //         deleteUserProfile: true,
        //     };
        case userProfile.DELETE_USER_PROFILE_ERROR:
            return {
                ...state,
                userProfileInfo: {},
                userProfileError: payload,
                // deleteUserProfile: false
            };
        default:
            return state;
    }
}