import React , {useState,useEffect}from "react";
import "./AccountSettings.scss";
import {Formik, Field, Form, ErrorMessage, useField} from 'formik';
import TextInput from "components/common/FormEllements/TextInput";
import * as Yup from "yup";
import Button from "components/common/Button/Button";
import {Link} from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import { openModal } from "redux/actions/modal";
import FormSelect from "components/common/FormEllements/FormSelect";
import {ReactComponent as ArrowLeft} from "assets/vectors/arrow-left-grey.svg";
import {useDispatch, useSelector} from "react-redux";
import modalConstants from "redux/constants/modal";
import {updateUserProfile} from "redux/actions/userProfile";
import {useMediaQuery} from "react-responsive";


export default function AccountSettings() {
    const dispatch = useDispatch();
    const userInfo  = useSelector(state => state.userInfo);
    const {medical_categories}  = useSelector(state => state.staticData.staticData);

    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1200px)'})

    const AccountCancel = () =>{
        console.log('AccountCancel')
    }

    const AccountSave = () =>{
        console.log('AccountSave')
    }

    const DeleteAccount = (dataModal) =>{
        dispatch(openModal(modalConstants.DELETE_ACCOUNT, dataModal))
    }

    const getQualification = (data = {}) => {

        if (data['medical_categories'] && data['medical_categories'].length) {
            return data['medical_categories'][0].id
        }
        return {}
    }

    return (
        <section className={`page-account-settings`}>
            {
                !isTabletOrMobile &&
                <h1 className={`title`}> <Link className={`back-home`} to="/dashboard"><ArrowLeft className={`icon`} /></Link>Account settings</h1>
            }

            <Formik
                enableReinitialize
                initialValues={{
                    name: userInfo ? userInfo.name : '',
                    surname: userInfo ? userInfo.surname : '',
                    email: userInfo ? userInfo.email : '',
                    medical_categories: userInfo ? getQualification(userInfo) : '',
                    // specialisation: '',
                    // language: '1',
                }}
                validationSchema={Yup.object({
                    name: Yup.string().required('Required'),
                    surname: Yup.string().required('Required'),
                    email: Yup.string().email('Invalid email address').required('Required'),
                    // medical_categories :Yup.string().required('Required'),
                    // specialisation :Yup.string().required('Required')
                })}

                onSubmit={
                    (values, {setErrors, setSubmitting, resetForm, setStatus}) => {
                        setSubmitting(false);

                        let newValues = {...values, medical_categories: [values.medical_categories]}

                        // resetForm();
                        dispatch(updateUserProfile(newValues, setErrors));
                    }
                }
            >
                {({
                      isClearable,
                      handleReset,
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      touched,
                      isSubmitting,
                      dirty,
                      setFieldValue,
                      handleBlur,
                      status,
                      ...props
                  }) => {

                    // console.log(errors);

                    return (
                        <Form className={`account-settings-form`}>

                            <div className="settings">
                                <div className="basics">
                                    <h3 className="title-block">Basics:</h3>

                                    <div className={`basics-fields`}>

                                        <div className={`row${errors.name && touched.name ? ' input-error' : ''}`}>

                                            <TextInput
                                                label="First name"
                                                name="name"
                                                type="text"
                                                placeholder="First name"
                                            />

                                        </div>

                                        <div className={`row${errors.surname && touched.surname ? ' input-error' : ''}`}>

                                            <TextInput
                                                label="Second name"
                                                name="surname"
                                                type="text"
                                                placeholder="Second name"

                                            />
                                        </div>

                                        <div className={`row${errors.email && touched.email ? ' input-error' : ''}`}>

                                            <TextInput
                                                label="Your email"
                                                name="email"
                                                type="email"
                                                placeholder="Your email"
                                            />

                                        </div>
                                    </div>
                                </div>

                                <div className="professionals">
                                    <h3 className="title-block">Professionals:</h3>

                                    <div className={`basics-fields`}>

                                        {/*<FormControl disabled>*/}
                                        <div className={`row`}>

                                            <FormControl className={`form-selected-input`} error = {errors.medical_categories && touched.medical_categories}  >
                                                <InputLabel className="form-select-label">Qualification</InputLabel>
                                                <Field name="medical_categories" component={FormSelect}>

                                                    {
                                                        medical_categories &&
                                                        medical_categories.map((item, index) => {

                                                          return (
                                                                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                            )
                                                        })
                                                    }

                                                </Field>
                                                <FormHelperText>{(errors.medical_categories && touched.medical_categories) && errors.medical_categories}</FormHelperText>

                                            </FormControl>
                                        </div>

                                        {
                                            /*

                                           <div className={`row`}>

                                            <FormControl className={`form-selected-input`} error = {errors.specialisation && touched.specialisation}  >
                                                <InputLabel className="form-select-label">Specialisation</InputLabel>
                                                <Field name="specialisation" component={FormSelect}>
                                                    <MenuItem value={'1'}>M.Sc - Anatomy</MenuItem>
                                                    <MenuItem value={'2'}>M.Sc - Anatomy 2</MenuItem>
                                                    <MenuItem value={'3'}>M.Sc - Anatomy 3</MenuItem>
                                                    <MenuItem value={'4'}>M.Sc - Anatomy 4</MenuItem>
                                                    <MenuItem value={'5'}>M.Sc - Anatomy 5</MenuItem>
                                                </Field>
                                                <FormHelperText>{(errors.specialisation && touched.specialisation) && errors.specialisation}</FormHelperText>

                                            </FormControl>

                                        </div>


                                            */
                                        }


                                    </div>
                                </div>
                            </div>

                            {
                                /*

                                <div className="language-block">
                                <h3 className="title-block">Basics:</h3>
                                <div className={`row`}>

                                    <FormControl disabled className={`form-selected-input`} error = {errors.language && touched.language}  >
                                        <InputLabel className="form-select-label">Language</InputLabel>
                                        <Field name="language" component={FormSelect}>
                                            <MenuItem value={'1'}>English</MenuItem>
                                            <MenuItem value={'2'}>Ukr</MenuItem>
                                            <MenuItem value={'3'}>Rus</MenuItem>
                                        </Field>
                                        <FormHelperText>{(errors.language && touched.language) && errors.language}</FormHelperText>
                                    </FormControl>


                                </div>
                            </div>

                            <div className="my-learning-area">
                                <h3 className="title-block">My learning area:</h3>
                                <div className="list-area">
                                    <div className="item">Angiology</div>
                                    <div className="item">Occupational medicine</div>
                                    <div className="item">Gastroenterology</div>
                                    <div className="item">Nuclear medicine</div>
                                    <div className="item">Paediatrics</div>
                                    <div className="item">Hematology</div>
                                    <div className="item">Gastroenterology</div>
                                    <div className="item">Plastic, reconstructive and aesthetic surgery</div>
                                    <div className="item">Paediatrics</div>
                                    <div className="item">Hand surgery</div>
                                    <div className="item add-more">+ Add more</div>
                                </div>
                            </div>
                                 */
                            }

                            <div className="actions">
                                <div className="actions-buttons">
                                    <Button
                                        className="button button--border cancel"
                                        disabled={isSubmitting || !dirty}
                                        onClick={AccountCancel}>
                                        Cancel
                                    </Button>

                                    <Button
                                        // onClick={AccountSave}
                                        type="submit"
                                        className="button button--green save"
                                    >
                                        Save changes
                                    </Button>
                                </div>


                                <div className="delete-account"
                                     onClick={() => {
                                         DeleteAccount()
                                     }}
                                >
                                    Close account
                                </div>
                            </div>

                        </Form>

                    )
                }}
            </Formik>

        </section>
    )

}