import React, {useState, useMemo} from "react";
import Navigation from "components/pages/Resources/Tabs/Navigation";

export default function Tabs({tabs, setActiveTab, activeTabId}) {

    return (
        <div className="resources-tabs">
            <div className="tabs-wrap">

                <div className={`tabs`}>
                    <Navigation tabs={tabs} onNavClick={setActiveTab} activeTabId={activeTabId}/>
                </div>

            </div>
        </div>

    )
}






