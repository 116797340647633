import React, {useState} from "react";
import "./MyCertifications.scss";

import 'react-perfect-scrollbar/dist/css/styles.css';

export default function HeaderListCertificates() {

    const [statusAwaredToggle, setStatusAwaredToggle] = useState(false);

    const AwaredToggle = () => {
        setStatusAwaredToggle(!statusAwaredToggle);
    };

    return (
        <section className="header-list-certificates">

            <div className="course">Course</div>

            <div className="awared-on" onClick={AwaredToggle}>
                Awared on
                {/*<div className="icon-awared">*/}
                {/*    <div className={`icon${!statusAwaredToggle ? ` active` : ``}`}>*/}
                {/*        <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*            <path d="M8.47105 4.48633L5.00005 1.00033L1.52905 4.48633" strokeWidth="1.5"*/}
                {/*                  strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*        </svg>*/}
                {/*    </div>*/}
                {/*    <div className={`icon${statusAwaredToggle ? ` active` : ``}`}>*/}
                {/*        <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*            <path d="M1.52905 1.48633L5.00005 4.97233L8.47105 1.48633" strokeWidth="1.5"*/}
                {/*                  strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*        </svg>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="code">Code</div>

            <div className="duration">Duration</div>

        </section>
    )

}