import React, {useEffect} from 'react';
import './App.scss';
import {useDispatch, useSelector} from "react-redux";
import {useAuthStatus} from "./hooks/useAuthStatus";
import Authorized from "components/common/Authorized/Authorized";
import UnAuthorized from "components/common/UnAuthorized/UnAuthorized";
import {getStaticData} from "redux/actions/staticData";

export default function App() {
    const dispatch = useDispatch();
    const isUserAuthorized = useAuthStatus();
    const {staticData} = useSelector(state => state.staticData);

    useEffect(() => {
        if (Object.keys(staticData).length === 0) dispatch(getStaticData());
    }, []);

    return (
        <div className={`main-wrap`}>
            {
                isUserAuthorized ?
                    <Authorized/>
                    :
                    <UnAuthorized/>
            }
        </div>
    );
}