import React, {useEffect} from 'react';
import {Formik, Field, Form, ErrorMessage} from 'formik';
import {useSelector, useDispatch} from "react-redux";
import * as Yup from 'yup';
import '../forms.scss';
import Button from "components/common/Button/Button";
import {loginAdminRequest} from "redux/actions/login";
import {clearState} from "redux/actions/clearState";
import login from "redux/constants/login";

const AdminLoginForm = () => {
    const dispatch = useDispatch();
    const {loginError, loginRequest} = useSelector(state => state.login);

    useEffect(() => {
        let timerId = null;

        if (loginError) {
            timerId = setTimeout(() => dispatch(clearState(login.CLEAR_LOGIN_ERROR)), 5000);
        }

        return () => {
            if (loginError) {
                clearTimeout(timerId);
                dispatch(clearState(login.CLEAR_LOGIN_ERROR));
            }
        };

    }, [loginError, dispatch]);

    return (
        <Formik
            initialValues={{
                email: '',
                password: ''
            }}
            validationSchema={Yup.object({
                email: Yup.string()
                    .email('*Invalid email address')
                    .required('*Required'),
                password: Yup.string()
                    .required('*Required'),
            })}
            onSubmit={(values) => dispatch(loginAdminRequest(values))}
        >
            <Form className='register-form' autoComplete="on" noValidate={true}>
                <Field name="email"
                       type="email"
                       className='register-form__input'
                       placeholder='Your email'
                />
                <ErrorMessage component="span" name="email" className='register-form__error'/>
                <Field name="password"
                       type="password"
                       className='register-form__input'
                       placeholder='Your password'
                />
                <ErrorMessage component="span" name="password" className='register-form__error'/>
                <Button type="submit"
                        isLoading={loginRequest}
                        className='button button--green register-form__submit'>
                    Login
                </Button>
                {
                    loginError &&
                    <div className='register-form__error'
                         style={{bottom: 0, marginTop: '16px', fontSize: '16px'}}>
                        *Error: {loginError}
                    </div>
                }
            </Form>
        </Formik>
    );
};

export default AdminLoginForm;