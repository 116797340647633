import React from 'react';
import './ChangeAvatar.scss';

import Avatar from "components/Modal/ChangeAvatar/Avatar";

export default function ChangeAvatar({title, description, modalData}) {

    return (

        <div className="setup-new-avatar">

            <h2 className={`form-title`}>{title}</h2>

            <p className={`form-description`}>{description}</p>

            {
                <Avatar modalData={modalData} />
            }

        </div>
    );
};