import React from 'react';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import EmptyTab from "components/pages/Dashboard/Tabs/EmptyTab";
import LoadingSpinner from "components/common/LoadingSpinner/LoadingSpinner";

const TabsContent = ({courses = [], loading, render}) => {
    const TextEmpty = 'You don’t have any active courses yet. To start learning select course from page “All courses” and start learn it';

    return (
        loading ?
            <LoadingSpinner/>
            :
            courses.length > 0 ?
                <TransitionGroup className="list-items">
                    {
                        courses.map(course => {
                            const nodeRef = React.createRef();

                            return (
                                <CSSTransition key={course.id}
                                               timeout={{
                                                   enter: 500,
                                                   exit: 200,
                                               }}
                                               unmountOnExit
                                               nodeRef={nodeRef}
                                               classNames='animate-'
                                >
                                    {
                                        <div className="item" ref={nodeRef}>
                                            {render({course, key: course.id})}
                                        </div>
                                    }
                                </CSSTransition>
                            )
                        })
                    }
                </TransitionGroup>
                :
                <EmptyTab emptyText={TextEmpty}/>
    );
};

export default TabsContent;