import {put, fork, takeLatest, call, select} from "redux-saga/effects";
import info from 'redux/constants/userInfo';
import login from 'redux/constants/login';
import {getData} from "redux/helpers/requestHandlers";
import {USER_INFO_URL} from "config/api";
import {getUserInfoSuccess} from "redux/actions/userInfo";
import ApiError from "../../services/ErrorHandler/ApiError";
import {showPopup} from "redux/actions/popup";
import responseHandler from "redux/helpers/responseHandler";

function* watchGetUserInfo() {
    yield takeLatest([info.GET_USER_INFO, login.LOGIN_SUCCESS], getUserInfoRequest)
}

function* getUserInfoRequest() {
    const token = yield select(state => state.login.authToken);
    const {data} = yield call(getData(USER_INFO_URL, token));

    yield responseHandler(data, function* () {
        yield put(getUserInfoSuccess(data.data));

    }, function* () {
        const err = new ApiError(data, `Can't get user info.`);
        yield put(showPopup({title: err.name, text: err.message}));
    });
}

export default function* () {
    yield fork(watchGetUserInfo);
}
