import React, {useState} from "react";
import "./MyCertifications.scss";
import {ReactComponent as SearchIcon} from "assets/vectors/search.svg";
import 'react-perfect-scrollbar/dist/css/styles.css';

export default function SearchCertificate() {

    const [certificateSearchValue, setCertificateSearchValue] = useState('');

    const handleCertificateSearchChange = (event) => {
        setCertificateSearchValue(event.target.value);
        console.log(event.target.value)
    }

    return (
        <section className="headline">
            <div className="title-block"><h1 className={`title`}>My certifications</h1></div>
            {/*<div className="search-certificate">*/}
            {/*    <SearchIcon className={`icon`}/>*/}

            {/*    <input*/}
            {/*        name="search-certificate"*/}
            {/*        onChange={handleCertificateSearchChange}*/}
            {/*        type="text"*/}
            {/*        value={certificateSearchValue}*/}
            {/*        placeholder={`Search`}/>*/}

            {/*</div>*/}
        </section>
    )

}