import React from "react";
import {ReactComponent as SiteLogo} from "assets/vectors/logo.svg";
import {Link} from "react-router-dom";

export default function Logo({setOpenBlurContent}) {

    const closeNavMenu = () =>{
        setOpenBlurContent(false);
    }

    return (
        <div className="logo">
            <Link to={{pathname: `/dashboard`}} onClick={closeNavMenu}>
                <SiteLogo/>
            </Link>
        </div>
    )
}