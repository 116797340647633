import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./Courses.scss";
import Empty from "components/pages/Empty/Empty";
import emptyPageImage from "assets/empty/my-certifications.svg";
import SearchCourses from "components/pages/Courses/SearchCourses";
import {getAllCourses} from "redux/actions/courses";
import LoadingSpinner from "components/common/LoadingSpinner/LoadingSpinner";
import CoursesOverview from "components/pages/Courses/CoursesOverview";

export default function Courses() {
    const {courses, loading} = useSelector(state => state.courses);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllCourses());
    }, [dispatch]);

    return (
        <section className={`page-all-courses`}>
            {
                loading ?
                    <LoadingSpinner/>
                    :
                    <>
                        <SearchCourses/>
                        {
                            courses.length === 0 ?
                                <Empty image={emptyPageImage}
                                       desription={`You have no certificates yet. To get certificate you need finish a course`}
                                />
                                :
                                <CoursesOverview allCourses={courses}/>
                        }
                    </>
            }
        </section>
    )

}