import staticData from '../constants/staticData';

const initialState = {
    staticData: {},
    staticDataError: '',
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case staticData.GET_STATIC_DATA_SUCCESS:
            return {
                ...state,
                staticData: payload
            };
        case staticData.GET_STATIC_DATA_ERROR:
            return {
                ...state,
                staticData: {},
                staticDataError: payload
            };
        default:
            return state;
    }
}