import React from "react";
import {ReactComponent as CompleteIcon} from "assets/vectors/courses/completed.svg";
import {ReactComponent as HoldIcon} from "assets/vectors/courses/hold-2.svg";


export default function StatusOfCourse({getStatus}) {
    switch (getStatus) {
        case "completed":
            return (
                <div className="status-of-course completed">
                    <div className="title-status">Completed</div>
                    <div className="icon"><CompleteIcon/></div>
                </div>
            );
        case "hold" :
            return (
                <div className="status-of-course hold">
                    <div className="title-status">Hold</div>
                    <div className="icon"><HoldIcon/></div>
                </div>
            )
        case "in_progress":
            return (
                <div className="status-of-course in-progress">
                    <div className="title-status">In progress</div>
                </div>
            )
        default:
            return null;
    }
}
