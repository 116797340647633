import action from '../constants/singleCourse';

export const getSingleCourse = (courseId) => ({
    type: action.GET_SINGLE_COURSE,
    payload: courseId
});

export const getMySingleCourse = (courseId) => ({
    type: action.GET_MY_SINGLE_COURSE,
    payload: courseId
});

export const getSingleCourseError = (errorData) => ({
    type: action.GET_SINGLE_COURSE_ERROR,
    payload: errorData,
});

export const getSingleCourseSuccess = (responseData) => ({
    type: action.GET_SINGLE_COURSE_SUCCESS,
    payload: responseData
});