import {put, fork, takeLatest, call, select} from "redux-saga/effects";
import action from 'redux/constants/completeLesson';
import {postData} from "redux/helpers/requestHandlers";
import {COMPLETE_LESSON_URL} from "config/api";
import ApiError from "../../services/ErrorHandler/ApiError";
import {showPopup} from "redux/actions/popup";
import {completeLessonError, completeLessonSuccess} from "redux/actions/completeLesson";
import responseHandler from "redux/helpers/responseHandler";
import {getUserInfo} from "redux/actions/userInfo";
import {showModalCertificate} from "redux/actions/completeLesson";
import {getMySingleCourse} from "redux/actions/singleCourse";

function* watchCompleteLesson() {
    yield takeLatest(action.COMPLETE_LESSON, completeLesson);
}

function* completeLesson({payload}) {
    const {authToken} = yield select(state => state.login);
    const {data} = yield call(postData(COMPLETE_LESSON_URL(payload.topicDetailsID), authToken));

    yield responseHandler(data, function* () {
        yield put(showModalCertificate());
        yield put(completeLessonSuccess(payload.topicDetailsID));
        yield put(getUserInfo());
        yield put(getMySingleCourse(payload.courseId));
    }, function* () {
        const err = new ApiError(data, `Can't start the  course.`);

        yield put(completeLessonError(err.message));
        yield put(showPopup({title: 'Error', text: err.message}));
    });
}

export default function* () {
    yield fork(watchCompleteLesson);
}
