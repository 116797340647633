import React from 'react';
import Button from "components/common/Button/Button";
import modalConstants from "redux/constants/modal";
import {useDispatch} from "react-redux";
import './DeleteAccount.scss';
import {deleteUserProfile} from "redux/actions/userProfile";

const DeleteAccount = ({title, subtitle, modalData}) => {
    const dispatch = useDispatch();

    function DeleteAccountNow(userId){
        // console.log(userId)
        dispatch(deleteUserProfile())
        console.log('need to logout');
    }

    const CancelDeleteAccount = () => {
        dispatch({type: modalConstants.CLOSE_MODAL});
    };

    return (
        <div className="delete-account-modal">
            <h2 className="delete-account-modal__title">
             {title}
            </h2>
            {
                subtitle && (
                    <p className="delete-account-modal__subtitle">
                        {subtitle}
                    </p>
                )
            }
            <div className="delete-actions-wrap">

                <Button
                    type="button"
                    className="button button--border"
                    onClick={()=>DeleteAccountNow()}
                >
                    Close account
                </Button>

                <Button
                    type="button"
                    className="button button--green"
                    onClick={CancelDeleteAccount}
                >
                    Cancel
                </Button>

            </div>
        </div>
    );
};

export default DeleteAccount;