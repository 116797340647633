import React from "react";

export default function Content({text, contentType}){
    const getTitle = () => {
        switch (contentType) {
            case 'topic':
                return 'About this topic';
            case 'lesson':
                return 'About this lesson';
            default:
                return 'About this course';
        }
    };

    return(
        <div className="content">
            <h2>{getTitle()}</h2>
            <div className='text' dangerouslySetInnerHTML={{__html: text}}/>
        </div>
    )
}

