import React from "react";
import "./Header.scss";

import Logo from "components/common/Header/Logo/Logo";
import UserInfo from "components/common/Header/UserInfo/UserInfo";

export default function Header({setOpenBlurContent,openBlurContent,toggleMenu,isUserAuthorized}) {
    return (
        <header className={`header`}>
            <Logo setOpenBlurContent={setOpenBlurContent}/>
            {
                isUserAuthorized && <UserInfo openBlurContent={openBlurContent} toggleMenu={toggleMenu}/>
            }
        </header>
    )
}