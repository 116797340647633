import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import Login from "components/pages/Login/Login";
import Register from "components/pages/Login/Register/Register";

const UnAuthorized = () => {
    return (
        <Routes>
            <Route path='login' element={<Login/>}/>
            <Route path='login/form' element={<Register/>}/>
            <Route path="*" element={<Navigate replace to="login"/>}/>
        </Routes>
    );
};

export default UnAuthorized;