const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const LOGIN_SERVICES_URL = `${REACT_APP_API_BASE_URL}/login/provider-links`;
export const LOGIN_SRXL_URL = `${REACT_APP_API_BASE_URL}/login/srxl`;
export const LOGIN_ADMIN_URL = `${REACT_APP_API_BASE_URL}/login/mecaso`;
export const LOGOUT_URL = `${REACT_APP_API_BASE_URL}/logout`;
export const REGISTER_URL = `${REACT_APP_API_BASE_URL}/register`;
export const STATIC_DATA_URL = `${REACT_APP_API_BASE_URL}/defined-data`;
export const USER_INFO_URL = `${REACT_APP_API_BASE_URL}/me`;
export const GET_COURSES = `${REACT_APP_API_BASE_URL}/courses-overview`;
export const GET_CERTIFICATES = `${REACT_APP_API_BASE_URL}/certificates`;
export const GET_MY_COURSES = `${REACT_APP_API_BASE_URL}/courses`;
export const GET_SINGLE_COURSE_URL = (id) => `${REACT_APP_API_BASE_URL}/courses-overview/${id}`;
export const GET_MY_SINGLE_COURSE_URL = (id) => `${REACT_APP_API_BASE_URL}/courses/${id}`;
export const START_COURSE_URL = (id) => `${REACT_APP_API_BASE_URL}/courses-overview/${id}/start`;
export const COMPLETE_LESSON_URL = (id) => `${REACT_APP_API_BASE_URL}/lesson/${id}/complete`;
export const ADD_FAVORITE_COURSE_URL = (id) => `${REACT_APP_API_BASE_URL}/favoured-courses/${id}`;
export const QUIZ_ROUTE = (id) => `${REACT_APP_API_BASE_URL}/quiz/${id}`;
export const QUIZ_SAVE_ROUTE = (id) => `${REACT_APP_API_BASE_URL}/quiz/answers/${id}`;
export const FAVORITE_COURSES_URL = `${REACT_APP_API_BASE_URL}/favoured-courses`;
export const USER_PROFILE = `${REACT_APP_API_BASE_URL}/profile`;
export const CONTACT_FORM_URL = `${REACT_APP_API_BASE_URL}/contact-form`;

