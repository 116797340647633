const isLocked = (topics = [], lockedSteps = []) => (
    !!topics.find(topic =>
        topic.lessons.find(lesson =>
            lockedSteps.includes(lesson.id) && !lesson.completed
        ))
);

export const transformSingleCourse = (course, completedId) => {
    const transformedCourse = {...course};

    if (transformedCourse.topics) {
        transformedCourse.topics = transformedCourse.topics.map(topic => {
                topic.lessons.map(lesson => {
                    if (lesson.id === completedId) lesson.completed = true;
                    lesson.isLocked = isLocked(transformedCourse.topics, lesson.previous_steps);

                    return lesson;
                });

                return topic;
            }
        );
    }

    return transformedCourse;
};