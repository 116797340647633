import register from '../constants/register';

const initialState = {
    registerData: {},
    registerRequestProgress: false,
    registerError: '',
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case register.REGISTER_REQUIRED:
            return {
                ...state,
                registerData: payload
            };
        case register.REGISTER_REQUEST:
            return {
                ...state,
                registerRequestProgress: true,
                registerError: '',
            };
        case register.REGISTER_ERROR:
            return {
                ...state,
                registerRequestProgress: false,
                registerError: payload,
            };
        case register.CLEAR_REGISTER_ERROR:
            return {
                ...state,
                registerError: ''
            };
        case register.REGISTER_SUCCESS:
            return {
                ...initialState
            };

        default:
            return state;
    }
}