import React from "react";

export default function CurrentTab(props) {

    return (
        <div className="current-active-tab">
            <div className={`tabs__content`}>
                {props.tab.component}
            </div>
        </div>

    )
}