import React from 'react';
import SwissRx from "components/pages/Login/LoginServices/SwissRx";
import DocCheck from "components/pages/Login/LoginServices/DocCheck";

const LoginServices = ({services = {}}) => {
    const servicesToRender = [];

    for (let service in services) {
        switch (service) {
            case 'srxl':
                servicesToRender.push(<SwissRx href={services[service]} key={service}/>)
                break;
            case 'dc':
                servicesToRender.push(<DocCheck iframe={services[service]} key={service}/>);
                break;
        }
    }

    return servicesToRender;
};

export default LoginServices;