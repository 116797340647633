import React from "react";
import {ReactComponent as ProfileMenuArrow} from "assets/vectors/arrow-down-grey.svg";
import {NavLink} from "react-router-dom";
import {ReactComponent as AccountSettingsIcon} from "assets/vectors/menu-icons/accaunt-settings.svg";
import {ReactComponent as NotificationsSettingsIcon} from "assets/vectors/menu-icons/notifications-settings.svg";
import {ReactComponent as LanguageIcon} from "assets/vectors/menu-icons/language.svg";
import {ReactComponent as LogOutIcon} from "assets/vectors/menu-icons/loguot.svg";
import {useDispatch} from "react-redux";
import {logOutRequest} from "redux/actions/login";

export default function ProfileMenu() {
    const dispatch = useDispatch();

    return (
        <div className={`profile-menu`}>
            <div className={`profile-menu-list`}>

                <nav className={`profile-navigation`}>

                    <NavLink to={'/account-settings'}>
                        <AccountSettingsIcon className={`icon profile-icon`}/>
                        <span className={`menu-name`}>Account settings</span>
                    </NavLink>

                    {/*<NavLink to={'/notification-settings'}>*/}
                    {/*    <NotificationsSettingsIcon className={`icon profile-icon`}/>*/}
                    {/*    <span className={`menu-name`}>Notifications settings</span>*/}
                    {/*</NavLink>*/}

                    {/*<NavLink to={'/account-settings'}>*/}

                    {/*    <LanguageIcon className={`icon profile-icon`}/>*/}
                    {/*    <span className={`menu-name`}>Language <span className={`lang-name`}>English</span></span>*/}
                    {/*</NavLink>*/}

                    <button onClick={() => dispatch(logOutRequest())}>
                        <LogOutIcon className={`icon profile-icon`}/>
                        <span className={`menu-name`}>Log out</span>
                    </button>


                </nav>

            </div>
        </div>

    )
}