import login from '../constants/login';

const initialState = {
    loginServices: {},
    loginServicesRequest: false,
    loginServicesError: null,
    authToken: '',
    loginError: null,
    loginRequest: false
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case login.GET_LOGIN_SERVICES:
            return {
                ...state,
                loginServicesRequest: true
            };
        case login.GET_LOGIN_SERVICES_ERROR:
            return {
                ...state,
                loginServices: {},
                loginServicesRequest: false,
                loginServicesError: payload
            };
        case login.GET_LOGIN_SERVICES_SUCCESS:
            return {
                ...state,
                loginServicesRequest: false,
                loginServicesError: null,
                loginServices: payload
            };
        case login.LOGIN_REQUEST:
        case login.LOGIN_ADMIN_REQUEST:
            return {
                ...state,
                authToken: '',
                loginError: null,
                loginRequest: true
            };
        case login.LOGIN_ERROR:
            return {
                ...state,
                authToken: '',
                loginError: payload,
                loginRequest: false
            };
        case login.CLEAR_LOGIN_ERROR:
            return {
                ...state,
                authToken: '',
                loginError: null,
                loginRequest: false
            }
        case login.LOGIN_SUCCESS:
            return {
                ...state,
                authToken: payload,
                loginError: null,
                loginRequest: false
            };
        case login.LOGOUT:
            return {
                ...initialState,
            }
        default:
            return state;
    }
}