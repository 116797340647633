import React from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useMediaQuery} from "react-responsive";
import {CSSTransition} from "react-transition-group";
import Topic from "components/pages/Course/MyCourse/Topics/Topic/Topic";
import Lesson from "components/pages/Course/MyCourse/Topics/Lesson/Lesson";
import {ReactComponent as BackArrow} from "assets/vectors/back-arrow.svg";
import LessonsList from "components/pages/Course/MyCourse/Topics/LessonsList/LessonsList";

export default function Topics({topics = [], courseId, mobileLessonsOpen, toggleMobileLessons}) {
    {/* TODO temporary hides topics and leaves lessons only #task MEC-97*/
    }
    const nodeRef = React.useRef(null);
    const isTabletOrMobile = useMediaQuery({maxWidth: 980});
    const lessons = topics.map(topic => topic.lessons).flat();

    return (
        isTabletOrMobile ?
            <CSSTransition in={mobileLessonsOpen}
                           timeout={500}
                           unmountOnExit
                           nodeRef={nodeRef}
                           classNames='animate-'>
                <section className='sidebar topics' ref={nodeRef}>
                    <LessonsList toggleMobileLessons={toggleMobileLessons}
                                 lessons={lessons}
                                 courseId={courseId}
                    />
                </section>
            </CSSTransition>
            :
            <PerfectScrollbar className='sidebar topics'>
                {/* TODO temporary hides topics and leaves lessons only #task MEC-97*/}
                {/*{*/}
                {/*    topics.map(topic => <Topic lessons={topic.lessons}*/}
                {/*                               topicId={topic.id}*/}
                {/*                               name={topic.name}*/}
                {/*                               courseId={courseId}*/}
                {/*                               key={topic.id}/>)*/}
                {/*}*/}
                <LessonsList toggleMobileLessons={toggleMobileLessons}
                             lessons={lessons}
                             courseId={courseId}
                />
            </PerfectScrollbar>
    );
}
