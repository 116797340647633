import React from "react";
import "./MyCertifications.scss";
import { useDispatch } from "react-redux";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import modalConstants from "redux/constants/modal";
import { openModal } from "redux/actions/modal";
import Button from "components/common/Button/Button";
import uniqid from 'uniqid';
import {useMediaQuery} from "react-responsive";

export default function ListCertifications({allCertificates}) {

    const dispatch = useDispatch();

    const OpenModalRequest = (dataModal) => {
        dispatch(openModal(modalConstants.CERTIFICATIONS_MODAL, dataModal))
    }
    const isTabletOrMobile = useMediaQuery({maxWidth: 767});
    return (
        <section className="list-certificates">

            <PerfectScrollbar className="list-certificates-scroll">

                {
                    allCertificates.map((item) =>{

                        return(
                            <div className="item" key={uniqid()}>
                                <div className="course">
                                    <div className="image-certificate"><img className={`image`} src={item.course_image} alt="certificate"/></div>

                                    <div className="course-certificate">
                                        <h3 className="certificate-title">{item.course_name}</h3>
                                    </div>
                                </div>

                                <div className="awared-on">
                                    {
                                        isTabletOrMobile && <span className={'mobile-title'}>Awared on</span>
                                    }
                                    {item.generating_date}

                                </div>

                                <div className="code">
                                    {
                                        isTabletOrMobile && <span className={'mobile-title'}>Code</span>
                                    }
                                    {item.code}
                                </div>

                                <div className="duration">
                                    <div className="duration-time">
                                        {
                                            isTabletOrMobile && <span className={'mobile-title'}>Duration</span>
                                        }
                                        {item.course_duration}h
                                    </div>

                                    <Button
                                        type="button"
                                        className="button button--green"
                                        onClick={()=>{OpenModalRequest(item.preview)}}
                                    >
                                        View certificate
                                    </Button>

                                </div>
                            </div>
                        )
                    })
                }


            </PerfectScrollbar>

        </section>
    )

}