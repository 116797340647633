import React from "react";
import {useSelector} from "react-redux";
import "./Tabs.scss";
import {Routes, Route} from 'react-router-dom';
import {tabs} from "../../../../helpers/static";
import Navigation from "components/pages/Dashboard/Tabs/Navigation";
import NotFound from "components/pages/NotFound/NotFound";
import {getCoursesByStatus} from "../../../../helpers/courses";
import TabsContent from "components/pages/Dashboard/Tabs/TabsContent";
import CourseInProgress from "components/pages/Dashboard/Tabs/CourseInProgress";
import CourseCompleted from "components/pages/Dashboard/Tabs/CourseCompleted";
import CourseMyCourse from "components/pages/Dashboard/Tabs/CourseMyCourse";

export default function Tabs() {
    const {courses, loading} = useSelector(state => state.myCourses);
    const {courses: favoriteCourses, loading: isLoading} = useSelector(state => state.favoriteCourses);

    return (
        <div className="tabs-wrap">
            <div className="title-block">
                <h1 className={`title`}>My learning</h1>
            </div>
            <div className={`tabs`}>
                <Navigation tabs={tabs}/>
                <div className={`tabs__content`}>
                    <Routes>
                        <Route path='/' element={<TabsContent courses={getCoursesByStatus(courses, 'in_progress')}
                                                              loading={loading}
                                                              render={props => <CourseInProgress {...props}/>}/>}/>
                        {/*// TODO temporary hide #task MEC-97*/}
                        {/*<Route path='my-courses' element={<TabsContent courses={favoriteCourses}*/}
                        {/*                                               loading={isLoading}*/}
                        {/*                                               render={props => <CourseMyCourse {...props}/>}/>}/>*/}
                        <Route path='completed' element={<TabsContent courses={getCoursesByStatus(courses, 'completed')}
                                                                      loading={loading}
                                                                      render={props => <CourseCompleted {...props}/>}/>}/>
                        {/*TODO temporary hide #task MEC-71*/}
                        {/*<Route path='on-hold' element={<OnHoldCourse courses={getCoursesByStatus(courses, 'on_hold')}/>}/>*/}
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </div>
            </div>
        </div>
    )
}






