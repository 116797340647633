import {put, fork, takeLatest, call, select} from "redux-saga/effects";
import action from 'redux/constants/favoriteCourses';
import {getData} from "redux/helpers/requestHandlers";
import {FAVORITE_COURSES_URL} from "config/api";
import ApiError from "../../services/ErrorHandler/ApiError";
import {getFavoriteCoursesError, getFavoriteCoursesSuccess} from "redux/actions/favoriteCourse";
import responseHandler from "redux/helpers/responseHandler";

function* watchGetFavoriteCourses() {
    yield takeLatest(action.GET_FAVORITE_COURSES, getFavoriteCoursesRequest)
}

export function* getFavoriteCoursesRequest() {
    const token = yield select(state => state.login.authToken);
    const {data} = yield call(getData(FAVORITE_COURSES_URL, token));

    yield responseHandler(data, function* () {
        yield put(getFavoriteCoursesSuccess(data.data));
    }, function* () {
        const err = new ApiError(data, `Can't get favorite courses`);

        yield put(getFavoriteCoursesError(err.message));
    })
}

export default function* () {
    yield fork(watchGetFavoriteCourses);
}
