import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import "./variables.scss";
import {store} from "redux/store";
import {Provider} from "react-redux";
import Modal from "components/Modal/Modal";
import {getAuthTokenFromStorage} from "./services/login";
import {loginSuccess} from "redux/actions/login";
import Popup from "components/common/Popup/Popup";

const authToken = getAuthTokenFromStorage();

if (authToken) {
    store.dispatch(loginSuccess(authToken));
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App/>
                <Modal/>
                <Popup/>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
