import React from 'react';
import NotFound from "components/pages/NotFound/NotFound";
import LoadingSpinner from "components/common/LoadingSpinner/LoadingSpinner";

const EmptyLayout = ({isEmpty = false}) => {
    return (
        isEmpty ?
            <NotFound/>
            :
            <LoadingSpinner/>
    );
};

export default EmptyLayout;