import React from 'react';
import EmptyPage from './EmptyPage/EmptyPage';
import CoursePresentation from "components/pages/Course/CoursePresentation/CoursePresentation";
import NotFound from "components/pages/NotFound/NotFound";
import Quiz from "components/pages/Course/Quiz/Quiz";

const FullScreen = ({lesson = {}}) => {
    const renderComponent = () => {
        switch (lesson.type) {
            // TODO temporary hid presentation page
            // case 'presentation':
            //     return <CoursePresentation lesson={lesson}/>;
            case 'quiz':
                return <Quiz quizId={lesson.quiz_id} lessonId={lesson.id}/>;
            default:
                return <EmptyPage/>;
        }
    }

    return renderComponent();
};

export default FullScreen;