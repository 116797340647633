import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import './Register.scss';
import {ReactComponent as UserDecoImg} from 'assets/vectors/login/user-info-deco.svg';
import Header from "components/common/Header/Header";
import {useRequireRegister} from "../../../../hooks/useRequireRegister";
import AdminLoginForm from "components/forms/AdminLoginForm/AdminLoginForm";
import RegisterForm from "components/forms/RegisterForm/RegisterForm";

const Register = () => {
    const isRegisterRequired = useRequireRegister();
    const {state} = useLocation();
    const isAdminLogin = state === 'isAdmin';

    return (
        isRegisterRequired || isAdminLogin ?
            <div className='register'>
                <div className="register__creds">
                    <Header/>
                    <div className="register__form">
                        <h1 className="register__title">Login</h1>
                        {
                            isRegisterRequired && (
                                <p className="register__text">
                                    Authorisation was successful. Please login by filling out the form below.
                                </p>
                            )
                        }
                        {isAdminLogin ? <AdminLoginForm/> : <RegisterForm/>}
                    </div>
                </div>
                <div className="register__deco">
                    <UserDecoImg className='register__deco-img'/>
                </div>
            </div>
            :
            <Navigate replace to={'/login'}/>
    );
};

export default Register;