import action from '../constants/favoriteCourses';

export const getFavoriteCourses = () => ({
    type: action.GET_FAVORITE_COURSES,
});

export const getFavoriteCoursesError = (errorData) => ({
    type: action.GET_FAVORITE_COURSES_ERROR,
    payload: errorData,
});

export const getFavoriteCoursesSuccess = (responseData) => ({
    type: action.GET_FAVORITE_COURSES_SUCCESS,
    payload: responseData
});

export const addFavoriteCourse = (courseId) => ({
    type: action.ADD_FAVORITE_COURSE,
    payload: courseId
});

export const addFavoriteCourseError = (errorData) => ({
    type: action.ADD_FAVORITE_COURSE_ERROR,
    payload: errorData,
});

export const addFavoriteCourseSuccess = (responseData) => ({
    type: action.ADD_FAVORITE_COURSE_SUCCESS,
    payload: responseData
});