import React, {useState} from "react";

import {ReactComponent as UpcomingIcon} from "assets/vectors/dashboard/upcoming.svg";
import ListItems from "components/pages/Resources/AdditionalInfo/Upcoming/ListItems";
import {TestItemsUpcoming} from "components/pages/Resources/AdditionalInfo/Upcoming/testData";
import NoItems from "components/pages/Resources/AdditionalInfo/Upcoming/NoItems";
import './Upcoming.scss';

export default function Upcoming() {

    const [statusUpcomingCourses, setStatusUpcomingCourses] = useState(true);

    return (
        <div className="upcoming">
            <div className="section-head">

                <h3 className={`section-title`}><span className="icon"><UpcomingIcon/></span>Upcoming courses</h3>

            </div>

            {
                statusUpcomingCourses ?
                    <div className="upcoming-items">
                        {
                            TestItemsUpcoming.map((item) => (
                                    <ListItems key={item.id} item={item}/>
                                )
                            )
                        }
                    </div>
                    :
                    <NoItems/>
            }

        </div>
    )
}
