import React from 'react';


const DefaultModal = ({closeModal, modalData}) => {
    return (
        <div className="decline-modal">
            <h2 className="decline-modal__title">
                {modalData?.title}
            </h2>
            {
                modalData?.description && (
                    <p className="decline-modal__text">
                        {modalData.description}
                    </p>
                )
            }
            <div className="decline-modal__actions">

            </div>
        </div>
    );
};

export default DefaultModal;