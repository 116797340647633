import React, {useState} from "react";
import './Recomended.scss';
import {ReactComponent as RecommendedIcon} from "assets/vectors/resource/recomended.svg";
import ListItems from "components/pages/Resources/AdditionalInfo/Upcoming/ListItems";
import {TestItemsRecommended} from "components/pages/Resources/AdditionalInfo/Recommended/testData";
import NoItems from "components/pages/Resources/AdditionalInfo/Upcoming/NoItems";

export default function Recommended() {

    const [statusRecommendedCourses, setStatusRecommendedCourses] = useState(true);

    return (
        <div className="recommended">
            <div className="section-head">

                <h3 className={`section-title`}><span className="icon"><RecommendedIcon/></span>Recommended for you</h3>

            </div>

            {
                statusRecommendedCourses ?
                    <div className="recommended-items">
                        {
                            TestItemsRecommended.map((item) => (
                                    <ListItems key={item.id} item={item}/>
                                )
                            )
                        }
                    </div>
                    :
                    <NoItems/>
            }

        </div>
    )
}
