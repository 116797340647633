import {put, spawn, takeLatest, call, select} from "redux-saga/effects";
import action from 'redux/constants/favoriteCourses';
import {postData} from "redux/helpers/requestHandlers";
import {ADD_FAVORITE_COURSE_URL} from "config/api";
import ApiError from "../../services/ErrorHandler/ApiError";
import {showPopup} from "redux/actions/popup";
import {addFavoriteCourseError, addFavoriteCourseSuccess} from "redux/actions/favoriteCourse";
import responseHandler from "redux/helpers/responseHandler";

function* watchAddFavoriteCourse() {
    yield takeLatest(action.ADD_FAVORITE_COURSE, addFavoriteCourseRequest);
}

function* addFavoriteCourseRequest({payload}) {
    const {authToken} = yield select(state => state.login);
    const {data} = yield call(postData(ADD_FAVORITE_COURSE_URL(payload), authToken));

    yield responseHandler(data, function* () {
        yield put(addFavoriteCourseSuccess(data.data));
    }, function* () {
        const err = new ApiError(data, `Can't add course to favorite.`);

        yield put(addFavoriteCourseError(err.message));
        yield put(showPopup({title: 'Error', text: err.message}));
    });
}

export default function* () {
    yield spawn(watchAddFavoriteCourse);
}
