import React, {useState} from "react";
import {ReactComponent as NoUpcomingCoursesIcon} from "assets/vectors/courses/no-upcoming-courses.svg";
import Button from "components/common/Button/Button";
import './NoItems.scss';

export default function NoItems() {
    return (
        <div className="no-items">
            <div className="icon">
                <NoUpcomingCoursesIcon />
            </div>

            <p className={`description`}>3 Here You’ll see a list of ucomming courses, added to your list of courses</p>

            <div className="add-courses-wrap">
                <Button
                    type="button"
                    className="button button--border add-courses"
                    // onClick={() => {
                    //     OpenModalRequest({id: 55, title: 'Request 55'})
                    // }}
                >
                    Add courses to my list
                </Button>
            </div>

        </div>
    )
}