import React from 'react';
import './DocCheck.scss';

const DocCheck = ({modalData}) => {
    return (
        <div className="doc-check">
            <h2 className="doc-check__title">
                {modalData?.title}
            </h2>
            <div className='doc-check__iframe'
                 dangerouslySetInnerHTML={{__html: modalData.iframe}}/>
        </div>
    );
};

export default DocCheck;