import React, {useMemo, useState} from "react";
import "./Resources.scss";
import SearchResources from "components/pages/Resources/SearchResources";
import Tabs from "components/pages/Resources/Tabs/Tabs";
import CurrentTab from "components/pages/Resources/Tabs/CurrentTab";
import Articles from "components/pages/Resources/Tabs/Articles";
import Video from "components/pages/Resources/Tabs/Video";
import Documents from "components/pages/Resources/Tabs/Documents";
import InteractivePresentations from "components/pages/Resources/Tabs/InteractivePresentations";
import AdditionalInfo from "components/pages/Resources/AdditionalInfo/AdditionalInfo";

export default function Resources() {

    const tabs = [
        {
            id: 1,
            title: 'Articles',
            component: <Articles statusEmpty={true}/>,
        },
        {
            id: 2,
            title: "Video",
            component: <Video statusEmpty={true}/>,
        },
        {
            id: 3,
            title: "Documents",
            component: <Documents statusEmpty={true}/>,
        },
        {
            id: 4,
            title: "Interactive presentations",
            component: <InteractivePresentations statusEmpty={true}/>,
        }
    ];

    const [activeTabId, setActiveTab] = useState(tabs[0].id);

    const activeTab = useMemo(() => (
        tabs.find((tab) => (
            tab.id === activeTabId
        ))
    ), [activeTabId, tabs]);


    // console.log(currentTab)

    return (
        <section className={`page-resources`}>

            <SearchResources/>

            <Tabs tabs={tabs} setActiveTab={setActiveTab} activeTabId={activeTabId}/>

            <div className="resources-content">

                <CurrentTab tab={activeTab}/>

                <AdditionalInfo/>

            </div>
        </section>
    )

}