import React, {useEffect, useState} from "react";

import {ReactComponent as BellNotification} from "assets/vectors/bell.svg";


export default function Notification() {

    const [hasNotification, setNotification] = useState(true);

    // useEffect(() => {
    //     setNotification(!hasNotification)
    // }, [hasNotification]);

    {/*TODO temporary hidden until functionality is not ready*/
    }
    // return (
    //     <div className="notification-block">
    //         <BellNotification className={`bell`}/>
    //         {
    //             hasNotification && <div className="active-notification"></div>
    //         }
    //     </div>
    // )
    return null;
}