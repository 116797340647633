import React from "react";
import "./ContactUs.scss";
import  ContactImage from "assets/vectors/contact-us/contact.svg";
import ContactForm from "components/pages/ContactUs/ContctForm";
export default function ContactUs() {

    return (
        <section className={`page-contact-us`}>

            <div className="left-column">
                <ContactForm />
            </div>
            
            <div className="right-column">
                <img src={ContactImage} alt=""/>
            </div>

          </section>
    )

}