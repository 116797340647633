import account from '../constants/accountSettings';

const initialState = {
    accountSettingsError: null,
    avatarLoading: false,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case account.CHANGE_ACCOUNT_AVATAR:
            return {
                ...state,
                avatarLoading: true
            };
        case account.CHANGE_ACCOUNT_AVATAR_DONE:
            return {
                ...state,
                avatarLoading: false,
            };
        default:
            return state;
    }
}