import React from 'react';
import {ReactComponent as QuizActive} from "assets/vectors/topics/active/quiz.svg";
import {ReactComponent as QuizDefault} from "assets/vectors/topics/default/quiz.svg";
import {ReactComponent as QuizCompleted} from "assets/vectors/topics/completed/quiz.svg";
import {ReactComponent as CompletedIcon} from "assets/vectors/check.svg";

const QuizIcon = ({active, completed}) => {
    if (completed) {
        return <><QuizCompleted/><CompletedIcon className='complete-icon'/></>;
    }
    return active ? <QuizActive/> : <QuizDefault/>;
};

export default QuizIcon;