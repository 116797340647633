import {put, fork, takeLatest, call, select} from "redux-saga/effects";
import userProfile from 'redux/constants/userProfile';
import {deleteData, postData} from "redux/helpers/requestHandlers";
import {USER_PROFILE} from "config/api";
import {deleteUserProfileError, updateUserProfileSuccess} from "redux/actions/userProfile";
import {logOutRequest} from "redux/actions/login";
import ApiError from "../../services/ErrorHandler/ApiError";
import {showPopup} from "redux/actions/popup";
import {closeModal} from "redux/actions/modal";
import {getUserInfo} from "redux/actions/userInfo";
import responseHandler from "redux/helpers/responseHandler";

function* watchDeleteUserProfile() {
    yield takeLatest(userProfile.DELETE_USER_PROFILE, handleDeleteUserProfile)
}

function* watchUpdateUserProfile() {
    yield takeLatest(userProfile.UPDATE_USER_PROFILE, handleUpdateUserProfile)
}

function* handleDeleteUserProfile() {
    const token = yield select(state => state.login.authToken);
    const {data} = yield call(deleteData(USER_PROFILE, token));

    yield responseHandler(data, function* () {
        yield put(logOutRequest());
        yield put(closeModal());
    }, function* () {
        const err = new ApiError(data, `Can't delete user profile.`);

        yield put(deleteUserProfileError(err.getErrorMessages()));
        yield put(showPopup({title: err.name, text: err.message}));
    });
}

function* handleUpdateUserProfile(requestData) {
    const token = yield select(state => state.login.authToken);
    const {data} = yield call(postData(USER_PROFILE, token, requestData.payload));

    yield responseHandler(data, function* () {
        yield put(updateUserProfileSuccess());
        yield put(getUserInfo());
        yield put(showPopup({title: 'Account settings', text: 'Data saved successfully'}, true));
    }, function* () {
        const err = new ApiError(data);

        yield put(deleteUserProfileError(err.getErrorMessages()));
        yield put(showPopup({title: err.name, text: err.getErrorMessages()}));
    });
}

export default function* () {
    yield fork(watchDeleteUserProfile);
    yield fork(watchUpdateUserProfile);
}
