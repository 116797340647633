import React from "react";
import ImageWithPlaceholder from "components/common/ImageWithPlaceholder/ImageWithPlaceholder";

export default function Preview({coverImg, coverText}){
    return(
        <div className="preview">
            <ImageWithPlaceholder src={coverImg} />
            {
                coverText && <div className="title">{coverText}</div>
            }
        </div>
    )
}

