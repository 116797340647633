import React, {useState} from "react";

import {ReactComponent as UpcomingIcon} from "assets/vectors/dashboard/upcoming.svg";
import UpcomingItems from "components/pages/Dashboard/Sidebar/UpcomingCourses/UpcomingItems";
import {TestItems} from "components/pages/Dashboard/Sidebar/UpcomingCourses/testData";
import NoItems from "components/pages/Dashboard/Sidebar/UpcomingCourses/NoItems";

export default function UpcomingCourses() {

    const [statusUpcomingCourses, setStatusUpcomingCourses] = useState(true);
    {/*TODO temporary hidden until functionality is not ready*/}
    // return (
    //     <div className="upcoming-courses">
    //         <div className="section-head">
    //             <h3 className={`section-title`}><span className="icon"><UpcomingIcon/></span>Upcoming courses</h3>
    //         </div>
    //         {
    //             statusUpcomingCourses ?
    //                 <div className="upcoming-items">
    //                     {
    //                         TestItems.map((item) => (
    //                                 <UpcomingItems key={item.id} item={item}/>
    //                             )
    //                         )
    //                     }
    //                 </div>
    //                 :
    //                 <NoItems/>
    //         }
    //     </div>
    // )

    return null;
}
