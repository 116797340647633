import {put, fork, takeLatest, call} from "redux-saga/effects";
import action from 'redux/constants/register';
import {postData} from "redux/helpers/requestHandlers";
import {REGISTER_URL} from "config/api";
import {loginSuccess} from "redux/actions/login";
import {saveAuthTokenToStorage} from "../../services/login";
import {registerError, registerSuccess} from "redux/actions/register";
import ApiError from "../../services/ErrorHandler/ApiError";
import responseHandler from "redux/helpers/responseHandler";


function* watchRegister() {
    yield takeLatest(action.REGISTER_REQUEST, registerRequest)
}

function* registerRequest({payload}) {
    const {data} = yield call(postData(REGISTER_URL, null, payload));

    yield responseHandler(data, function* () {
        const responseData = data.data;

        yield saveAuthTokenToStorage(responseData.token);
        yield put(loginSuccess(responseData.token));
        yield put(registerSuccess());
    }, function* () {
        const err = new ApiError(data);

        yield put(registerError(err.getErrorMessages()));
    });
}

export default function* () {
    yield fork(watchRegister);
}