import React from 'react';
import {ReactComponent as SwissRxIcon} from "assets/vectors/login/swiss-rx.svg";
import {ReactComponent as ArrowIcon} from "assets/vectors/login/arrow.svg";

const SwissRx = ({href}) => {
    return (
        <a className="item" href={href}>
            <SwissRxIcon/>
            <ArrowIcon/>
        </a>
    );
};

export default SwissRx;